import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router";

import { ConfirmationModal } from "components";
import { SuccessToast } from "components/atoms/Toast";
import BreadCrumbs from "components/molecules/Breadcrumbs";

import Stepper from "../components/Stepper";
import RabForm from "./Rab";
import TorForm from "./Tor";

const validationSchema = yup.object({});

const stepperItems = [
  {
    label: "TOR",
    id: "tor",
    component: <TorForm />,
  },
  {
    label: "RAB",
    id: "rab",
    component: <RabForm />,
  },
];

const DisbursementForm = () => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
    mode: "all",
  });

  const navigate = useNavigate();
  const { id } = useParams();
  const [confirmModal, setConfirmModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [payload, setPayload] = useState({});
  const [success, setSuccess] = useState(false);

  const submitForm = () => {
    setConfirmModal(true);
  };

  const items = [
    {
      label: "Daftar Permohonan",
      path: "/disbursement/application",
    },
    { label: "Tambah Permohonan" },
  ];

  const onCreate = async (data) => {
    try {
      setSuccess(true);

      setConfirmModal(!confirmModal);

      navigate("/proposal/konsep-notes");
    } catch (err) {
      console.error(err);
    }
  };

  const onNext = (type, values) => {
    if (!id) {
      setCurrentStep((curr) => curr + 1);
      setPayload((curr) => ({
        ...curr,
        [type]: {
          ...curr[type],
          ...values,
        },
      }));
      if (currentStep > 1) {
        return renderModal();
      }
    } else {
      setCurrentStep((curr) => curr + 1);
    }
  };

  const onBack = (type, values) => {
    setPayload((curr) => ({
      ...curr,
      [type]: {
        ...curr[type],
        ...values,
      },
    }));
    if (currentStep >= 1) setCurrentStep((curr) => curr - 1);
    else navigate(-1);
  };

  const renderModal = () => {
    return (
      <ConfirmationModal
        open={confirmModal}
        onCancel={setConfirmModal}
        onSubmit={() => onCreate(methods.getValues())}
        cancelLabel={"Tidak, Ubah Data"}
        confirmLabel={"Ya, Kirimkan"}
      >
        <div className="text-lg font-semibold">
          Anda yakin akan mengirimkan semua data ini?
        </div>
        <div className="text-sm">
          Dengan ini kami menyatakan bahwa Seluruh data yang kami berikan pada
          formulir ini adalah benar adanya. Jika dikemudian hari BPDLH menemukan
          unsur kebohongan dan atau pemalsuan data, kami siap dan bersedia untuk
          diajukan ke ranah hukum sesuai dengan undang-undang yang berlaku.
        </div>
      </ConfirmationModal>
    );
  };

  return (
    <>
      {/* Modal */}
      {renderModal()}

      {success && (
        <SuccessToast
          onClose={() => setSuccess(!success)}
          message={"Data Berhasil Dikirim!"}
          message_description="Data yang anda masukkan sudah berhasil dikirim"
        />
      )}

      <div className="flex flex-col bg-white p-10 gap-10 self-stretch rounded-xl">
        {/* Header Section */}
        <div className="flex flex-col gap-4">
          <BreadCrumbs routes={items} />
          <Stepper currentStep={currentStep} items={stepperItems} />
        </div>
        {React.cloneElement(stepperItems[currentStep].component, {
          type: stepperItems[currentStep].id,
          onNext,
          onBack,
          payload,
        })}
      </div>
    </>
  );
};

export default DisbursementForm;
