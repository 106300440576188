import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { BottomFormAction, Button, InputForm, Spinner } from "components";
import AtomDatePicker from "components/atoms/Datepicker";
import AtomSelect from "components/atoms/Select";
import CardForm from "components/molecules/CardForm";
import FileDetail from "components/molecules/FileDetail";
import InputFormDropzone from "components/molecules/InputFormDropzone";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import React, { useEffect, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import { RiAddLine } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";
import { getAwpDetail, getAwpList } from "services/danaProgram/awpCmsService";
import {
  getDirectSubmissionSectorList,
  postDirectSubmissionStep1,
} from "services/danaProgram/directSubmissionService";
import { fileUpload } from "services/danaProgram/fileService";
import { getThematicProgrammes } from "services/danaProgram/masterDataService";
import { array, number, object, string } from "yup";

const GeneralInformationInsentif = ({ onNext, onBack, type }) => {
  let [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const [thematic, setThematic] = useState([]);

  const [awp, setAwp] = useState([]);
  const [pageAwp, setPageAwp] = useState(1);
  const [lastPageAwp, setLastPageAwp] = useState(1);

  const [sector, setSector] = useState([]);

  const [output, setOutput] = useState([]);

  const [initProposalDocs, setInitProposalDocs] = useState([]);
  const [initAdditionalDocs, setInitAdditionalDocs] = useState([]);

  const methods = useFormContext();

  const { control, watch } = methods;
  useEffect(() => {
    methods.setValue("isStep2", false);
  }, []);

  useEffect(() => {
    setInitProposalDocs(methods.getValues("initProposal"));
    setInitAdditionalDocs(methods.getValues("initDoc"));
  }, [watch(["initProposal", "initDoc"])]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "location_address",
  });

  const {
    fields: torField,
    append: torAppend,
    remove: torRemove,
  } = useFieldArray({
    control,
    name: "tor",
  });

  const { data, isLoading } = useQuery({
    queryKey: ["get-thematic-list"],
    queryFn: async () => {
      const res = await getThematicProgrammes();

      return res.data.data;
    },
  });

  useEffect(() => {
    if (data) {
      const mapped = data?.map((item) => ({
        label: item?.nama,
        value: item?.id,
      }));
      setThematic(mapped);
    }
  }, [data]);

  const { data: awpList } = useQuery({
    queryKey: ["get-awp-list", pageAwp],
    queryFn: async () => {
      const res = await getAwpList({ page: pageAwp, status: "dikirim" });

      return res.data.data;
    },
  });

  const reachBottomAwp = () => {
    if (pageAwp < lastPageAwp) {
      setPageAwp(pageAwp + 1);
    }
  };

  useEffect(() => {
    if (awpList) {
      let mapped = awpList?.data?.map((item) => ({
        label: item?.proposal_number,
        value: item?._id,
      }));
      setAwp((prevState) => [...prevState, ...mapped]);
      setPageAwp(awpList?.page);
      setLastPageAwp(awpList?.lastPage);
    }
  }, [awpList]);

  const { data: sectorList } = useQuery({
    queryKey: ["get-sector-list"],
    queryFn: async () => {
      const res = await getDirectSubmissionSectorList();

      return res.data.data;
    },
  });

  useEffect(() => {
    if (sectorList) {
      let mapped = sectorList?.map((item) => ({
        label: item?.name,
        value: item?._id,
      }));
      setSector(mapped);
    }
  }, [sectorList]);

  const { data: awpDetail } = useQuery({
    enabled: !!methods.getValues("project")?.value,
    queryKey: ["get-awp-list", watch("project")],
    queryFn: async () => {
      const res = await getAwpDetail(methods.getValues("project")?.value);

      return res.data.data;
    },
  });

  useEffect(() => {
    if (awpDetail) {
      // SET MASTER ACTIVITY (USED ON STEP 2)
      let activity = awpDetail?.output
        .flatMap((item) => {
          return item.activity;
        })
        .map((item) => {
          let detailMapped = item?.detail.map((detail) => {
            return {
              label: detail?.name,
              value: detail?._id,
              budget: detail?.budget,
            };
          });
          return {
            label: item?.name,
            value: item?._id,
            detail: detailMapped,
          };
        });
      methods.setValue("master_activity", activity);

      // SET PROPOSAL ID (USED ON STEP 2)
      methods.setValue(
        "proposal_id",
        awpDetail?.investment?.step_1?.proposal_id
      );

      // REMAP OBJECT OUTPUT
      const outputMapping = {};
      awpDetail?.investment?.step_3?.map((item) => {
        const outputKey = item.output;
        if (!!outputKey) {
          if (outputKey in outputMapping) {
            outputMapping[outputKey].indicator.push(item.indicator);
          } else {
            outputMapping[outputKey] = {
              label: outputKey,
              value: outputKey,
              indicator: [item.indicator],
            };
          }
        }
      });

      let mapped = [];
      for (const key in outputMapping) {
        mapped.push(outputMapping[key]);
      }
      setOutput(mapped);
    }
  }, [awpDetail]);

  const onSubmit = () => {
    let payload = {
      proposal: methods.getValues("proposal"),
      doc: methods.getValues("doc"),
    };

    postFile.mutate(payload);
  };

  const postFile = useMutation({
    mutationKey: ["post-file-direct-submission"],
    mutationFn: async (payload) => {
      const data = payload.proposal?.map(async (item) => {
        const formData = new FormData();
        formData.append("file", item);
        const res = await fileUpload(formData);
        return {
          fileId: res.data.data.id,
          fileName: res.data.data.name,
          fileSize: res.data.data.size,
          mimeType: res.data.data.mimeType,
          path: res.data.data.key,
        };
      });
      const data2 = payload.doc?.map(async (item) => {
        const formData = new FormData();
        formData.append("file", item);
        const res = await fileUpload(formData);
        return {
          fileId: res.data.data.id,
          fileName: res.data.data.name,
          fileSize: res.data.data.size,
          mimeType: res.data.data.mimeType,
          path: res.data.data.key,
        };
      });

      return Promise.all([...(data || []), ...(data2 || [])]);
    },
    onSuccess: (data) => {
      submitMutate.mutate({
        id: id ? id : "",
        project: methods.getValues("project"),
        program_name: methods.getValues("program_name"),
        desc: methods.getValues("desc"),
        sector: methods.getValues("sector"),
        periode: methods.getValues("periode"),
        program_goal: methods.getValues("program_goal"),
        scope: methods.getValues("scope"),
        involvement: methods.getValues("involvement"),
        location_address: methods.getValues("location_address"),
        tor: methods.getValues("tor"),
        proposal: data?.[0]
          ? [data[0], ...(initProposalDocs || [])]
          : [...(initProposalDocs || [])],
        doc: data?.[1]
          ? [data[1], ...(initAdditionalDocs || [])]
          : [...(initAdditionalDocs || [])],
      });
    },
  });

  const submitMutate = useMutation({
    mutationKey: ["step-1-insentif"],
    mutationFn: async (payload) => {
      const res = await postDirectSubmissionStep1(payload);
      return res.data;
    },
    onSuccess: (res) => {
      let payload = {
        id: res?.id,
      };
      onNext(type, payload);
    },
  });
  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          <FormProvider {...methods}>
            <CardForm label="Identitas program/ kegiatan">
              <AtomSelect
                label={"Proyek"}
                controllerName={"project"}
                className={"mt-2"}
                options={awp}
                onMenuScrollToBottom={reachBottomAwp}
                required
                asText={searchParams.get("mode") === "detail"}
              />
              <InputForm
                label="Judul program/ Kegiatan"
                controllerName={`program_name`}
                className={`py-2 px-4 border w-full rounded-md flex-1 mt-2`}
                required
                asText={searchParams.get("mode") === "detail"}
              />
              <InputForm
                label="Deskripsi program/ kegiatan"
                controllerName={`desc`}
                className={`py-2 px-4 border w-full rounded-md flex-1 mt-2`}
                textArea
                required
                asText={searchParams.get("mode") === "detail"}
              />
              <div className="grid grid-cols-2 gap-5">
                <AtomSelect
                  label={"Sektor"}
                  controllerName={"sector"}
                  className={"mt-2"}
                  options={sector}
                  required
                  asText={searchParams.get("mode") === "detail"}
                />
                <AtomDatePicker
                  label="Periode Pelaksanaan Program/ Kegiatan"
                  controllerName={"periode"}
                  isRangeDatePicker
                  className={"border-gray-200"}
                  required
                  showErrorLabel
                  asText={searchParams.get("mode") === "detail"}
                />
              </div>
              <InputForm
                required
                label="Tujuan program/ kegiatan"
                controllerName={`program_goal`}
                className={`py-2 px-4 border w-full rounded-md flex-1 mt-2`}
                textArea
                asText={searchParams.get("mode") === "detail"}
              />
              <InputForm
                required
                label="Ruang Lingkup"
                controllerName={`scope`}
                className={`py-2 px-4 border w-full rounded-md flex-1 mt-2`}
                textArea
                asText={searchParams.get("mode") === "detail"}
              />
              <h2 className="font-semibold text-xl">Keterlibatan</h2>
              <div className="grid grid-cols-2 gap-5">
                <InputForm
                  label="Jumlah Gender Laki - Laki"
                  controllerName={`involvement.total_man`}
                  className={`py-2 px-4 border w-full rounded-md flex-1 mt-2`}
                  asText={searchParams.get("mode") === "detail"}
                  forceNumber
                />
                <InputForm
                  label="Jumlah Gender Perempuan"
                  controllerName={`involvement.total_woman`}
                  className={`py-2 px-4 border w-full rounded-md flex-1 mt-2`}
                  asText={searchParams.get("mode") === "detail"}
                  forceNumber
                />
              </div>
            </CardForm>
            <CardForm label="Theory of Change">
              <div className="flex flex-col gap-3">
                {torField?.map((item, index) => (
                  <div key={item?.id} className="flex gap-3 items-end">
                    <AtomSelect
                      label={"Output"}
                      controllerName={`tor.${index}.output`}
                      className={"mt-2"}
                      options={output}
                      required
                      asText={searchParams.get("mode") === "detail"}
                    />
                    {index !== torField.length && torField.length > 1 && (
                      <Button
                        label={<BiTrash />}
                        className="w-fit bg-red-700 text-white"
                        onClick={() => torRemove(index)}
                      />
                    )}
                    {torField.length < output.length &&
                      index === torField.length - 1 && (
                        <Button
                          label={<RiAddLine />}
                          className="w-fit bg-primary-700 text-white"
                          onClick={() =>
                            torAppend({
                              output: {
                                label: "",
                                value: "",
                              },
                            })
                          }
                        />
                      )}
                  </div>
                ))}
              </div>
            </CardForm>
            <CardForm label="Lokasi pelaksanaan">
              {fields.map((item, index) => (
                <LocationExecutions
                  key={item.id}
                  item={item}
                  index={index}
                  append={() => append({ province: null, city: null })}
                  remove={() => remove(index)}
                  asText={searchParams.get("mode") === "detail"}
                />
              ))}
            </CardForm>
            <CardForm label="Dokumen">
              <div className="flex flex-col gap-3">
                <label className="label-text font-semibold">
                  Upload Proposal Program/ Kegiatan
                  <span className="text-red-500">*</span>
                </label>
                {(!searchParams.get("mode") === "detail" ||
                  !searchParams.get("mode")) && (
                  <InputFormDropzone
                    name="proposal"
                    accept={{
                      "image/png": [],
                      "image/jpeg": [],
                      "application/pdf": [],
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                        [],
                    }}
                    maxFiles={1}
                    maxSize={1}
                    multiple={false}
                    informationText="PNG, JPG, PDF, Docx"
                  />
                )}
                {initProposalDocs?.map((item, index) => (
                  <FileDetail
                    key={index}
                    file={item}
                    onRemove={() => {
                      const filtered = initProposalDocs.filter(
                        (file) => file.fileId !== item.fileId
                      );
                      setInitProposalDocs(filtered);
                    }}
                    hideDelete={searchParams.get("mode") === "detail"}
                  />
                ))}
              </div>
              <div className="flex flex-col gap-3">
                <label className="label-text font-semibold">
                  Upload Dokumen Pendukung Lainnya
                  <span className="text-red-500">*</span>
                </label>
                {(!searchParams.get("mode") === "detail" ||
                  !searchParams.get("mode")) && (
                  <InputFormDropzone
                    name="doc"
                    accept={{
                      "image/png": [],
                      "image/jpeg": [],
                      "application/pdf": [],
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                        [],
                    }}
                    maxFiles={1}
                    maxSize={1}
                    multiple={false}
                    informationText="PNG, JPG, PDF, Docx"
                  />
                )}
                {initAdditionalDocs?.map((item, index) => (
                  <FileDetail
                    key={index}
                    file={item}
                    onRemove={() => {
                      const filtered = initAdditionalDocs.filter(
                        (file) => file.fileId !== item.fileId
                      );
                      setInitAdditionalDocs(filtered);
                    }}
                    hideDelete={searchParams.get("mode") === "detail"}
                  />
                ))}
              </div>
            </CardForm>
          </FormProvider>
          <BottomFormAction
            lastStep={false}
            backButtonAction={() => onBack()}
            submitActionButton={() => {
              if (searchParams.get("mode") === "detail") {
                onNext(type);
              } else {
                methods.handleSubmit(onSubmit)();
              }
            }}
            hideDraft
          />
        </div>
      )}
    </>
  );
};

const LocationExecutions = ({
  item,
  index,
  append,
  remove,
  asText = false,
}) => {
  const { control, getValues } = useFormContext();

  const provinceId = useWatch({
    name: `location_address.${index}.province`,
    control,
  });
  return (
    <div className="flex items-end gap-3">
      <div className="grid grid-cols-2 gap-5 flex-1">
        <SelectFDBProvince
          countryId="a874db33-a64e-4d1e-86d6-41ab95fad8b1" // indonesia
          controllerName={`location_address.${index}.province`}
          className="basic-single flex-1"
          label="Provinsi"
          required
          placeholder="Provinsi"
          asText={asText}
        />
        <SelectFDBCity
          controllerName={`location_address.${index}.city`}
          className="basic-single flex-1"
          label="Kabupaten/Kota"
          required
          placeholder="Kabupaten/Kota"
          provinceId={provinceId?.value}
          asText={asText}
        />
      </div>
      {!asText && (
        <>
          {index === 0 ? (
            <Button
              label={<RiAddLine />}
              className="w-fit bg-primary-700 text-white"
              onClick={() =>
                append({
                  province: null,
                  city: null,
                })
              }
            />
          ) : (
            <Button
              label={<BiTrash />}
              className="w-fit bg-red-700 text-white"
              onClick={remove}
            />
          )}
        </>
      )}
    </div>
  );
};

export default GeneralInformationInsentif;
