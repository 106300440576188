/* eslint-disable react-hooks/rules-of-hooks */
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import {
  RiClipboardLine,
  RiContactsLine,
  RiDraftLine,
  RiFileAddLine,
  RiFileListLine,
  RiFileSearchLine,
  RiFileTransferLine,
  RiFileUserLine,
  RiSearchLine,
} from 'react-icons/ri';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router';
import style from './index.css';
import { useQuery } from '@tanstack/react-query';
import { getProfileAccount } from 'services/fdb/profile';

const Sidebar = ({ children, props }) => {
  const typeProps = props;
  const location = useLocation();
  const navigate = useNavigate();
  const navLemtara = [
    {
      icon: <RiContactsLine />,
      label: 'Info Akun',
      path: '/info-akun',
    },
    {
      icon: <RiClipboardLine />,
      label: 'Penilaian LEMTARA',
      path: '/penilaian-lemtara',
    },
    {
      icon: <RiClipboardLine />,
      label: 'Proposal',
      children: [
        {
          label: 'Konsep Note',
          path: '/proposal/konsep-notes',
        },
        {
          label: 'Full Proposal',
          path: '/proposal/call-for-proposal',
        },
      ],
    },
    {
      icon: <RiClipboardLine />,
      label: 'Pencairan Dana',
      children: [
        {
          label: 'Permohonan',
          path: '/disbursement/application',
        },
      ],
    },
    // {
    //   icon: <RiClipboardLine />,
    //   label: "Pengajuan Langsung / Insentif",
    //   path: "/request-insentif",
    // },
    {
      icon: <RiClipboardLine />,
      label: 'Laporan',
      children: [
        {
          label: 'Laporan Kegiatan',
          path: '/report/activity',
        },
        {
          label: 'Laporan Kemajuan',
          path: '/report/progress',
        },
        {
          label: 'Laporan Komitmen',
          path: '/report/commitment',
        },
      ],
    },
  ];

  const navLembagaPenyalur = [
    {
      icon: <RiContactsLine />,
      label: 'Info Akun',
      path: '/info-akun',
    },
    {
      icon: <RiClipboardLine />,
      label: 'Formulir Pendaftaran',
      path: `/formulir-pendaftaran/${
        typeProps?.group !== undefined ? typeProps?.group : 'lembaga-penyalur'
      }/${typeProps?.type !== undefined ? typeProps?.type : 'bank-ventura'}`,
      matchFn: (location) => {
        return /^\/formulir-pendaftaran/.test(location?.pathname);
      },
    },
    // {
    //   icon: <RiFileUserLine />,
    //   label: "Self Assesment",
    //   path: "/penilaian-lemtara",
    // },
    {
      icon: <RiFileUserLine />,
      label: 'Permohonan Pembiayaan',
      path: `/permohonan-pembiayaan/${
        typeProps?.group !== undefined ? typeProps?.group : 'lembaga-penyalur'
      }/${typeProps?.type !== undefined ? typeProps?.type : 'bank-ventura'}`,
      matchFn: (location) => {
        return /^\/permohonan-pembiayaan/.test(location?.pathname);
      },
    },
    {
      icon: <RiFileUserLine />,
      label: 'Penawaran',
      path: `/penawaran/${
        typeProps?.group !== undefined ? typeProps?.group : 'lembaga-penyalur'
      }/${typeProps?.type !== undefined ? typeProps?.type : 'bank-ventura'}`,
      matchFn: (location) => {
        return /^\/penawaran/.test(location?.pathname);
      },
    },
    {
      icon: <RiFileUserLine />,
      label: 'Perjanjian',
      path: `/perjanjian/${
        typeProps?.group !== undefined ? typeProps?.group : 'lembaga-penyalur'
      }/${typeProps?.type !== undefined ? typeProps?.type : 'bank-ventura'}`,
      matchFn: (location) => {
        return /^\/perjanjian/.test(location?.pathname);
      },
    },
    {
      icon: <RiFileUserLine />,
      label: 'Permohonan Pencairan',
      path: `/permohonan-pencairan/${
        typeProps?.group !== undefined ? typeProps?.group : 'lembaga-penyalur'
      }/${typeProps?.type !== undefined ? typeProps?.type : 'bank-ventura'}`,
      matchFn: (location) => {
        return /^\/permohonan-pencairan/.test(location?.pathname);
      },
    },
  ];

  const navLembagaPenyalurNonPerorangan = [
    {
      icon: <RiContactsLine />,
      label: 'Info Akun',
      path: '/info-akun',
    },
    {
      icon: <RiClipboardLine />,
      label: 'Formulir Pendaftaran',
      path: `/non-perorangan/${typeProps !== undefined ? typeProps : 'bumdes'}`,
    },
    {
      icon: <RiFileUserLine />,
      label: 'Self Assesment',
      path: '/penilaian-lemtara',
    },
    {
      icon: <RiFileUserLine />,
      label: 'Permohonan Pembiayaan',
      path: `/non-perorangan/financing-request?type=${
        typeProps !== undefined ? typeProps : 'bumdes'
      }`,
      matchFn: (location) => {
        return /^\/non-perorangan\/financing-request/.test(location?.pathname);
      },
    },
  ];

  const navAkunKelompok = useMemo(() => {
    const type =
      location.pathname?.indexOf('/perhutanan-sosial') > -1
        ? '/perhutanan-sosial'
        : '/non-perhutanan-sosial';

    return [
      {
        icon: <RiContactsLine size={24} />,
        label: 'Info Akun',
        path: `/kelompok${type}/info-akun`,
        matchFn: (location) => {
          return /^(\/kelompok\/(non-)?perhutanan-sosial\/info-akun)/.test(
            location?.pathname
          );
        },
      },
      {
        icon: <RiClipboardLine size={24} />,
        label: 'Formulir Pendaftaran',
        path: `/kelompok${type}/formulir-pendaftaran`,
        matchFn: (location) => {
          return /^(\/kelompok\/(non-)?perhutanan-sosial\/formulir-pendaftaran)/.test(
            location?.pathname
          );
        },
      },
      {
        icon: <RiFileUserLine size={24} />,
        label: 'Pengajuan Daftar Permohonan',
        path: `/kelompok${type}/pengajuan-daftar-permohonan`,
        matchFn: (location) => {
          return /^(\/kelompok\/(non-)?perhutanan-sosial\/pengajuan-daftar-permohonan)/.test(
            location?.pathname
          );
        },
      },
      {
        icon: <RiFileListLine size={24} />,
        label: 'Permohonan Pencairan',
        path: `/kelompok${type}/permohonan-pencairan`,
        matchFn: (location) => {
          return /^(\/kelompok\/(non-)?perhutanan-sosial\/permohonan-pencairan)/.test(
            location?.pathname
          );
        },
      },
    ].filter((e) => !!e);
  }, [location.pathname]);

  const navAkunPerorangan = useMemo(() => {
    const type =
      location.pathname?.indexOf('/perhutanan-sosial') > -1
        ? '/perhutanan-sosial'
        : '/non-perhutanan-sosial';

    return [
      {
        icon: <RiContactsLine />,
        label: 'Info Akun',
        path: `/perorangan${type}/info-akun`,
        matchFn: (location) => {
          return /^(\/perorangan\/(non-)?perhutanan-sosial\/info-akun)/.test(
            location?.pathname
          );
        },
      },
      location.pathname.indexOf('/perorangan/perhutanan-sosial') === 0
        ? undefined
        : {
            icon: <RiClipboardLine />,
            label: 'Formulir Pendaftaran',
            path: `/perorangan${type}/formulir-pendaftaran`,
            matchFn: (location) => {
              return /^(\/perorangan\/(non-)?perhutanan-sosial\/formulir-pendaftaran)/.test(
                location?.pathname
              );
            },
          },
      {
        icon: <RiFileTransferLine />,
        label: 'Permohonan Pembiayaan',
        path: `/perorangan${type}/permohonan-pembiayaan`,
        matchFn: (location) => {
          return /^(\/perorangan\/(non-)?perhutanan-sosial\/permohonan-pembiayaan)/.test(
            location?.pathname
          );
        },
      },
      {
        icon: <RiFileUserLine />,
        label: 'Self Assessment',
        path: '/perorangan/self-assessment',
        matchFn: (location) => {
          return (
            location?.pathname?.indexOf('/perorangan/self-assessment') === 0
          );
        },
      },
      {
        icon: <RiDraftLine />,
        label: 'Penawaran',
        path: `/perorangan${type}/penawaran`,
        matchFn: (location) => {
          return /^(\/perorangan\/(non-)?perhutanan-sosial\/penawaran)/.test(
            location?.pathname
          );
        },
      },
      {
        icon: <RiDraftLine />,
        label: 'Perjanjian',
        path: `/perorangan${type}/perjanjian`,
        matchFn: (location) => {
          return /^(\/perorangan\/(non-)?perhutanan-sosial\/perjanjian)/.test(
            location?.pathname
          );
        },
      },
      {
        icon: <RiFileSearchLine />,
        label: 'Permohonan Pencairan',
        path: `/perorangan${type}/permohonan-pencairan`,
        matchFn: (location) => {
          return /^(\/perorangan\/(non-)?perhutanan-sosial\/permohonan-pencairan)/.test(
            location?.pathname
          );
        },
      },
      // {
      //   icon: <RiSearchLine />,
      //   label: "Pencairan",
      //   path: `/perorangan${type}/pencairan`,
      //   matchFn: (location) => {
      //     return /^(\/perorangan\/(non-)?perhutanan-sosial\/penawaran)/.test(
      //       location?.pathname
      //     );
      //   },
      // },
    ].filter((e) => !!e);
  }, [location.pathname]);

  const navBeneficiaries = [
    {
      icon: <RiClipboardLine />,
      label: 'Pengajuan Langsung / Insentif',
      path: '/beneficiaries/insentif',
    },
  ];

  const [navItems, setNavItems] = useState();
  const currentPath = window.location.pathname;

  // ? CHECK USER IF PS OR NPS
  // Refactored due to error. Never put hooks inside a condition!
  useQuery(['get-active-submission'], getProfileAccount, {
    enabled:
      !currentPath.includes('penilaian-lemtara') &&
      !currentPath.includes('proposal') &&
      !currentPath.includes('disbursement') &&
      // !currentPath.includes("request-insentif") &&
      !currentPath.includes('report'),
    onSuccess: (data) => {
      console.log(
        location.pathname.startsWith('/kelompok') &&
          data.data.data.group_profile.group_type === 'Non Perhutanan Sosial'
      );

      if (
        location.pathname.startsWith('/kelompok') &&
        data.data.data.group_profile.group_type === 'Non Perhutanan Sosial'
      ) {
        const route = location.pathname.replace(
          '/perhutanan-sosial',
          '/non-perhutanan-sosial'
        );

        navigate(route, { replace: true });
      } else if (
        location.pathname.startsWith('/kelompok') &&
        data.data.data.group_profile.group_type === 'Perhutanan Sosial'
      ) {
        const route = location.pathname.replace(
          '/non-perhutanan-sosial',
          '/perhutanan-sosial'
        );
        navigate(route, {
          replace: true,
        });
      } else if (
        location.pathname.startsWith('/perorangan') &&
        data.data.data.group_profile.group_type === 'Non Perhutanan Sosial'
      ) {
        const route = location.pathname.replace(
          '/perhutanan-sosial',
          '/non-perhutanan-sosial'
        );
        navigate(route, {
          replace: true,
        });
      } else {
        const route = location.pathname.replace(
          '/non-perhutanan-sosial',
          '/perhutanan-sosial'
        );
        navigate(route, {
          replace: true,
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    let items = navLembagaPenyalur;

    if (
      location?.pathname.includes('/penilaian-lemtara') ||
      location?.pathname.includes('/proposal') ||
      location?.pathname.includes('/disbursement') ||
      // location?.pathname.includes("/request-insentif") ||
      location?.pathname.includes('/report')
    ) {
      items = navLemtara;
    } else if (location?.pathname?.includes('/beneficiaries/insentif')) {
      items = navBeneficiaries;
    } else if (location?.pathname?.indexOf('/kelompok') === 0) {
      items = navAkunKelompok;
    } else if (location?.pathname?.indexOf('/perorangan') === 0) {
      items = navAkunPerorangan;
    } else if (location?.pathname?.indexOf('/non-perorangan') === 0) {
      items = navLembagaPenyalurNonPerorangan;
    }

    setNavItems(items);
  }, [location]);

  return (
    <aside className="hidden sm:block w-72 overflow-y-auto border-gray-500 z-10 shrink-0 bg-[#ffffff] p-6 rounded-xl shadow-sm h-min">
      <ul className="space-y-2 m-4">
        {_.map(navItems, ({ label, icon, path, matchFn, children }, key) =>
          children?.length > 0 ? (
            <li key={key}>
              <label className="cursor-pointer group">
                <input className="peer absolute scale-0" type="checkbox" />
                <div className="block max-h-9 overflow-hidden transition-all duration-300 peer-checked:max-h-52">
                  <div
                    className={`flex items-center p-2 text-sm font-semibold rounded-lg text-[#667085]`}
                  >
                    <div className="flex flex-1">
                      <div className={[style.iconHolder, 'shrink-0'].join(' ')}>
                        {icon}
                      </div>
                      <span className="ml-3">{label}</span>
                    </div>
                    <MdKeyboardArrowRight
                      size={20}
                      className="text-[#667085] group-has-[peer]:rotate-180"
                    />
                  </div>
                  <div className="flex flex-col space-y-1">
                    {children?.map((child, idx) => (
                      <div
                        key={idx}
                        onClick={() => navigate(child?.path)}
                        className={`ml-6 flex items-center p-2 font-semibold text-sm rounded-lg hover:bg-[#01A24A] hover:text-[#ffffff] ${
                          (
                            matchFn
                              ? matchFn(location)
                              : location?.pathname?.includes(child?.path)
                          )
                            ? `bg-[#01A24A] text-[#ffffff]`
                            : `text-[#667085]`
                        }`}
                      >
                        {child.label}
                      </div>
                    ))}
                  </div>
                </div>
              </label>
            </li>
          ) : (
            <li key={key} className="cursor-pointer">
              <div
                onClick={() => navigate(path)}
                className={`flex items-center p-2 text-base font-semibold text-sm rounded-lg hover:bg-[#01A24A] hover:text-[#ffffff] ${
                  (
                    matchFn
                      ? matchFn(location)
                      : location?.pathname?.includes(path)
                  )
                    ? 'bg-[#01A24A] text-[#ffffff]'
                    : 'text-[#667085]'
                }`}
              >
                <div className={[style.iconHolder, 'shrink-0'].join(' ')}>
                  {icon}
                </div>
                {/* <img src={icon} className={`${location?.pathname?.includes(path) ? "bg-[#01A24A] text-[#ffffff]" : "text-[#667085]"}`}/> */}
                <span className="ml-3 text-sm font-semibold">{label}</span>
              </div>
            </li>
          )
        )}
      </ul>
    </aside>
  );
};

export default Sidebar;
