import React, { useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import CardForm from "components/molecules/CardForm";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormRadio,
} from "components";
import AtomSelect from "components/atoms/Select";
import { RiAddLine } from "react-icons/ri";
import { FiAlertCircle } from "react-icons/fi";
import FileInfoField from "components/atoms/FileInfoField";
import { map } from "lodash";
import { BiTrash } from "react-icons/bi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import InputCurrencyForm from "components/V2/shared/input-currency-form";
import AsyncDropzoneFieldForm from "components/molecules/async-dropzone-field-form";
import { useMutation } from "@tanstack/react-query";
import { patchAgencyRequestApplication } from "services/fdb/lembagaPenyalur";
import { applicationValidationScheme } from "./ValidationSchema/application.scheme";
import { getFile } from "services/danaProgram/fileService";
import { humanizeFileSize } from "helpers";

const FieldLabel = {
  APP_VALUE: "Nilai Permohonan",
  COOPERATION_PATTERN: "Pola Kerjasama",
  FINANCING_TERM_PLAN: "Rencana jangka waktu pembiayaan yang akan disalurkan",
  COLLATERAL_TYPE: "Jenis Jaminan",
  GUARANTEE_FORM: "Bentuk Jaminan",
  GUARANTEE_NAME: "Nama Jaminan",
  COLLATERAL_VALUE: "Nilai Jaminan",
  GUARANTEE_FILE: "Upload Jaminan",
  FDB_POTENCY: "Upload Daftar Potensi Penerima FDB",
};

const collateral_type_options = [
  { value: "Aset Tetap", label: "Aset Tetap" },
  {
    value: "Aset Tidak Tetap / Bergerak",
    label: "Aset Tidak Tetap / Bergerak",
  },
];

const guarantee_form_options = [
  [
    { value: "Tanah & Bangunan", label: "Tanah & Bangunan" },
    { value: "Tanah", label: "Tanah" },
    { value: "Ruko", label: "Ruko" },
    { value: "Lainnya", label: "Lainnya" },
  ],
  [
    { value: "Tagihan Lancar", label: "Tagihan Lancar" },
    { value: "Cash Collateral", label: "Cash Collateral" },
    { value: "Lainnya", label: "Lainnya" },
  ],
];

const ApplicationValue = ({ dataApplication }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const request_id = urlParams.get("request");
  const url = location.pathname.split("/");
  const is_view = url[4] === "view" ? true : false;

  const [urlFile, setUrlFile] = useState("");
  const [showGracePeriod, setShowGracePeriod] = useState(false);

  const methods = useForm({
    resolver: yupResolver(applicationValidationScheme),
    mode: "onChange",
    defaultValues: {},
  });

  const {
    fields: collateralFields,
    append: appendCollateral,
    remove: removeCollateral,
  } = useFieldArray({
    control: methods.control,
    name: `collaterals`,
  });

  const mutateAgencyRequsetApplication = useMutation(
    ["PATCH AGENCY REQUEST APPLICATION"],
    ({ data, params }) => {
      return patchAgencyRequestApplication(data, params);
    }
  );

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (methods.watch("is_grace_period")) setShowGracePeriod(true);
    else setShowGracePeriod(false);
  }, [methods.watch("is_grace_period"), showGracePeriod]);

  useEffect(() => {
    if (dataApplication) {
      methods.reset({
        application_value: dataApplication?.application_value,
        term_plan_value: dataApplication?.term_plan_value,
        term_plan_type: dataApplication?.term_plan_type,
        is_grace_period: dataApplication?.is_grace_period,
        grace_period_value: dataApplication?.grace_period_value,
        grace_period_type: dataApplication?.grace_period_type,
        format_plan_file: dataApplication?.format_plan_file,
        format_potention_file: dataApplication?.format_potention_file,
        format_potention_url: dataApplication?.format_potention_url,
        collaterals: dataApplication?.agency_application_collateral.length
          ? dataApplication?.agency_application_collateral.map((data) => {
            return {
              id: data?.id,
              type: data?.type,
              form: data?.form,
              value: data?.value,
              files: data?.files,
            };
          })
          : [
            {
              id: null,
              type: null,
              form: null,
              values: 0,
              files: [],
            },
          ],
      });
    } else {
      methods.reset({
        collaterals: [
          {
            id: null,
            type: null,
            form: null,
            values: 0,
            files: [],
          },
        ],
      });
    }
  }, [dataApplication, methods]);

  const onFormSubmit = useCallback(() => {
    if (is_view) {
      setSearchParams(`step=2&request=${request_id}`);
      return;
    }

    methods.handleSubmit((data) =>
      mutateAgencyRequsetApplication.mutate(
        {
          data,
          params: {
            request_id: request_id ?? null,
            is_draft: false,
          },
        },
        {
          onSuccess: (response) => {
            setSearchParams(
              `step=2&request=${response?.data?.data?.request_id}`
            );
          },
        }
      )
    )();
  }, [
    is_view,
    methods,
    mutateAgencyRequsetApplication,
    request_id,
    setSearchParams,
  ]);

  const onFormDraft = useCallback(() => {
    const data = methods.getValues();
    mutateAgencyRequsetApplication.mutate(
      {
        data,
        params: {
          request_id: request_id ?? null,
          is_draft: true,
        },
      },
      {
        onSuccess: () => navigate(`/permohonan-pembiayaan/${url[2]}/${url[3]}`),
      }
    );
  }, [methods, mutateAgencyRequsetApplication, navigate, url]);

  const renderGuaranteeOptions = (value) => {
    if (value === "Aset Tetap") {
      return guarantee_form_options[0];
    }

    if (value === "Aset Tidak Tetap / Bergerak") {
      return guarantee_form_options[1];
    }

    return [];
  };

  const onChangeFile = (data) => {
    methods.setValue("format_potention_url", data?.url);
  };
  const openDocsPlanFile = async () => {
    window.open(usedPlanFile.url, "_blank");
  };

  const openDocsPotentionFile = async () => {
    window.open(potentionFile.url, "_blank");
  };

  const handleBack = () => {
    navigate(-1);
  };

  const [potentionFile, setPotentionFile] = useState();
  const [usedPlanFile, setUsedPlanFile] = useState();

  const handleGetTemplateFile = useCallback(async () => {
    const resUsedPlanFile = await getFile(
      "35e44ffc-5b15-4879-9c5b-8a0a0fd775a2"
    );
    const resPotentionFile = await getFile(
      "b6d37fe8-d024-4564-a9a5-f04645cdb651"
      // "985e5c47-ef77-45ba-9507-057a1708a2b7"
    );

    setUsedPlanFile(resUsedPlanFile.data.data);
    setPotentionFile(resPotentionFile.data.data);
  }, []);

  useEffect(() => {
    handleGetTemplateFile();
  }, [handleGetTemplateFile]);

  // TODO:

  return (
    <FormProvider {...methods}>
      <div className="space-y-10">
        <CardForm label="Nilai Permohonan">
          <div className="space-y-6">
            <InputCurrencyForm
              name="application_value"
              label={FieldLabel.APP_VALUE}
              required
              asText={is_view}
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-5">
              <InputForm
                label={FieldLabel.FINANCING_TERM_PLAN}
                className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                controllerName="term_plan_value"
                required
                asText={is_view}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <AtomSelect
                controllerName="term_plan_type"
                className="w-full rounded-md md:mt-7"
                options={[
                  {
                    label: "Bulan",
                    value: "Bulan",
                  },
                  {
                    label: "Tahun",
                    value: "Tahun",
                  },
                ]}
                placeholder="Bulan"
                required
                returnObject={false}
                asText={is_view}
              />
            </div>
            <InputFormRadio
              values={[
                {
                  label: "Ya",
                  value: true,
                },
                {
                  label: "Tidak",
                  value: false,
                },
              ]}
              label={"Apakah Rencana Jangka Waktu Dengan Grace Period?"}
              controllerName={"is_grace_period"}
              required
              disabled={is_view}
            />

            {showGracePeriod && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-5">
                <InputForm
                  label="Lama Grace Period"
                  className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                  controllerName="grace_period_value"
                  required
                  asText={is_view}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <AtomSelect
                  controllerName="grace_period_type"
                  className="w-full rounded-md md:mt-7"
                  options={[
                    {
                      label: "Bulan",
                      value: "Bulan",
                    },
                    {
                      label: "Tahun",
                      value: "Tahun",
                    },
                  ]}
                  placeholder="Bulan"
                  required
                  returnObject={false}
                  asText={is_view}
                />
              </div>
            )}
          </div>
        </CardForm>
        <CardForm label={"Nilai Jaminan"}>
          <div className="space-y-6">
            {map(collateralFields, (field, index) => (
              <div key={field.id} className="space-y-6">
                {index > 0 && <div className="border-t" />}
                {index > 0 && (
                  <div className="flex items-center justify-end mx-4">
                    <Button
                      type="button"
                      onClick={() => removeCollateral(index)}
                      className="p-3 rounded-lg bg-error-600 flex-none"
                      label={<BiTrash className="text-white" />}
                    />
                  </div>
                )}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-5">
                  <AtomSelect
                    controllerName={`collaterals.${index}.type`}
                    className={`mt-2 w-full rounded-md `}
                    options={collateral_type_options}
                    label={FieldLabel.COLLATERAL_TYPE}
                    placeholder={" "}
                    required={true}
                    returnObject={false}
                    asText={is_view}
                  />
                  <AtomSelect
                    controllerName={`collaterals.${index}.form`}
                    className={`mt-2 w-full rounded-md `}
                    options={renderGuaranteeOptions(
                      methods.watch(`collaterals.${index}.type`)
                    )}
                    label={FieldLabel.GUARANTEE_FORM}
                    placeholder=""
                    isDisabled={
                      !methods.watch(`collaterals.${index}.type`)?.value
                    }
                    required
                    returnObject={false}
                    asText={is_view}
                  />
                </div>
                {methods?.watch(`collaterals.${index}.form`)
                  ?.value === "Lainnya" && (
                    <InputForm
                      controllerName={`collaterals.${index}.form`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md ${methods.errors.nilai_permohonan
                        ? "border-[#F04438]"
                        : "border-green"
                        }`}
                      label={FieldLabel.GUARANTEE_NAME}
                      required={true}
                      returnObject={false}
                      asText={is_view}
                    />
                  )}
                <InputCurrencyForm
                  name={`collaterals.${index}.value`}
                  label={FieldLabel.COLLATERAL_VALUE}
                  required
                  asText={is_view}
                />

                <div className="w-full pt-2">
                  <div className="bg-gray-25 border border-gray-300 rounded-xl p-4">
                    <div className="flex flex-row gap-2">
                      <div className="w-[17px] h-[15px] mt-1">
                        <FiAlertCircle className="text-gray-800" />
                      </div>
                      <div className="flex-1">
                        <span className="font-semibold text-[14px] text-gray-800">
                          Bisa upload lebih dari satu
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-10">
                  <AsyncDropzoneFieldForm
                    maxFiles={10}
                    informationText="PNG, JPG, PDF, DOCX"
                    name={`collaterals.${index}.files`}
                    maxSize={10}
                    multiple
                    isView={is_view}
                  />
                </div>
              </div>
            ))}
            <div className="border-t" />
            <div className="flex flex-row-reverse my-5 mx-4">
              {!is_view && (
                <Button
                  className="p-4 border rounded-lg bg-white"
                  onClick={() =>
                    appendCollateral({
                      id: null,
                      type: "",
                      form: "",
                      value: undefined,
                      files: [],
                    })
                  }
                  label={
                    <div className="flex items-center gap-2 font-[14px]">
                      <RiAddLine />
                      Tambah Jaminan
                    </div>
                  }
                />
              )}
            </div>
          </div>
        </CardForm>
        <CardForm label="Rencana Penggunaan & Daftar Potensi FDB">
          <div className="space-y-6">
            <div className="space-y-1 mb-5">
              <label htmlFor="" className="font-semibold">
                Format Rencana Penggunaan FDB
              </label>
              <FileInfoField
                title="Format Rencana Penggunaan FDB"
                desc={humanizeFileSize(usedPlanFile?.size)}
                showDownload
                showIcon
                onDownloadClick={() => openDocsPlanFile()}
              />
            </div>

            <div className="space-y-1">
              <AsyncDropzoneFieldForm
                label="Upload Rencana Penggunaan FDB"
                required
                maxFiles={1}
                informationText="PNG, JPG, PDF, DOCX"
                name="format_plan_file"
                maxSize={10}
                isView={is_view}
              />
            </div>

            <div className="space-y-1 mb-5">
              <label htmlFor="" className="font-semibold">
                Format Daftar Potensi Penerima FDB
              </label>
              <FileInfoField
                title="Format Daftar Potensi Penerima FDB"
                desc={humanizeFileSize(potentionFile?.size)}
                showDownload
                showIcon
                onDownloadClick={() => openDocsPotentionFile()}
              />
            </div>

            <div className="space-y-1">
              <AsyncDropzoneFieldForm
                label="Upload Daftar Potensi Penerima FDB"
                required
                maxFiles={1}
                informationText="XLSX"
                name="format_potention_file"
                maxSize={10}
                onChange={(data) => onChangeFile(data)}
                isView={is_view}
              />
            </div>
          </div>
        </CardForm>
      </div>
      <BottomFormAction
        backButtonProps={{
          type: "button",
        }}
        draftButtonProps={{
          type: "button",
        }}
        submitButtonProps={{
          type: "submit",
        }}
        submitActionButton={onFormSubmit}
        backButtonAction={handleBack}
        disableDrafButton={is_view ? true : false}
        drafButtonAction={onFormDraft}
      />
    </FormProvider>
  );
};

export default ApplicationValue;
