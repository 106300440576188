import { createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "helpers";
import { USER } from "services";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: null
};

export const accountGroupSlice = createSlice({
  name: "accountGroup",
  initialState,
  reducers: {
    create: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    createSuccess: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.errorMessage = null;
    },
    createFailed: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = action?.payload?.message;
    },
    clearState: (state) => {
      state = initialState
    }
  },
});

// this is for dispatch
export const { create, createSuccess, createFailed, clearState } =
  accountGroupSlice.actions;

export const registerAccountGroup = (params) => {
  return async (dispatch, getState) => {
    dispatch(create());
    try {
      const response = await USER.registerAccountGroup(params);
      const data = response?.data;
      dispatch(createSuccess(data));
    } catch (error) {
      dispatch(createFailed({ message: getErrorMessage(error) }));
    }
  };
};

// this is for configureStore
export default accountGroupSlice.reducer;
