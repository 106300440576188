import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, InputForm } from "components";
import AtomDatePicker from "components/atoms/Datepicker";
import AtomSelect from "components/atoms/Select";
import CardForm from "components/molecules/CardForm";
import { enqueueSnackbar } from "notistack";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";
import { getActivityCategory } from "services/fdb/activityCategory";
import { getMasterBussinessCategory } from "services/fdb/bussinessCategory";
import { getCompanionStatus } from "services/fdb/companionStatus";
import {
  deleteActivityFormulirPendaftaran,
  deleteFinanceBusinessFormulirPendaftaran,
  deletePartnerFormulirPendaftaran,
} from "services/fdb/perhutananSosial/registerForm";

const InformationGroup = () => {
  return (
    <CardForm label="Informasi Kelompok">
      <div className="flex flex-col gap-5">
        <div className="grid grid-cols-4 gap-6">
          <InputForm
            controllerName="leader_name"
            className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
            label="Nama Ketua Tani Hutan"
            placeholder=""
          // asText={isView}
          />
          <InputForm
            controllerName="leader_phone"
            className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
            label="Nomor Telepon Ketua Tani Hutan"
            placeholder=""
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            maxLength={15}
          // asText={isView}
          />
          <InputForm
            controllerName="leader_identity"
            className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
            label="NIK KTP Ketua Tani Hutan"
            placeholder=""
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            maxLength="16"
          // asText={isView}
          />
          <AtomSelect
            controllerName="leader_gender"
            className="mt-2"
            label="Jenis Kelamin Ketua"
            placeholder="Laki Laki/Perempuan"
            // asText={isView}
            menuPosition="fixed"
            returnObject={false}
            options={[
              {
                value: "m",
                label: "Laki-laki",
              },
              {
                value: "f",
                label: "Perempuan",
              },
            ]}
          />
        </div>

        <div className="grid grid-cols-3 gap-6">
          <InputForm
            controllerName="secretary_name"
            className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
            label="Nama Sekretaris Tani Hutan"
            placeholder=""
          // asText={isView}
          />
          <InputForm
            controllerName="secretary_phone"
            className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
            label="Nomor Telepon Sekretaris Tani Hutan"
            placeholder=""
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            maxLength={15}
          // asText={isView}
          />
          <AtomSelect
            controllerName="secretary_gender"
            label="Jenis Kelamin Sekretaris"
            className="mt-2"
            placeholder="Laki Laki/Perempuan"
            // asText={isView}
            menuPosition="fixed"
            returnObject={false}
            options={[
              {
                value: "m",
                label: "Laki-laki",
              },
              {
                value: "f",
                label: "Perempuan",
              },
            ]}
          />
        </div>

        <div className="grid grid-cols-3 gap-6">
          <InputForm
            controllerName="treasurer_name"
            className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
            label="Nama Bendahara Tani Hutan"
            placeholder=""
          // asText={isView}
          />
          <InputForm
            controllerName="treasurer_phone"
            className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
            label="Nomor Telepon Bendahara Tani Hutan"
            placeholder=""
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            maxLength={15}
          // asText={isView}
          />
          <AtomSelect
            controllerName="treasurer_gender"
            label="Jenis Kelamin Bendahara"
            className="mt-2"
            placeholder="Laki Laki/Perempuan"
            // asText={isView}
            menuPosition="fixed"
            returnObject={false}
            options={[
              {
                value: "m",
                label: "Laki-laki",
              },
              {
                value: "f",
                label: "Perempuan",
              },
            ]}
          />
        </div>

        <div className="grid grid-cols-4 gap-6">
          <InputForm
            controllerName="companion_name"
            className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
            label="Nama Pendamping Tani Hutan"
            placeholder=""
          // asText={isView}
          />
          {/* <AtomSelect
            controllerName="companion_affiliate_id"
            label="Afiliasi Pendamping Tani Hutan"
            className="mt-2"
            placeholder=""
            // asText={isView}
            menuPosition="fixed"
            returnObject={false}
            options={[]}
          /> */}
          <CompanionStatusSelect />
          <InputForm
            controllerName="companion_phone"
            className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
            label="Nomor Telepon Pendamping Tani Hutan"
            placeholder=""
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            maxLength={15}
          // asText={isView}
          />
          <AtomSelect
            controllerName="companion_gender"
            label="Jenis Kelamin Pendamping"
            className="mt-2"
            placeholder="Laki Laki/Perempuan"
            // asText={isView}
            menuPosition="fixed"
            returnObject={false}
            options={[
              {
                value: "m",
                label: "Laki-laki",
              },
              {
                value: "f",
                label: "Perempuan",
              },
            ]}
          />
        </div>

        <div className="grid grid-cols-2 gap-6">
          {/* <AtomDatePicker
            controllerName="end_date_confirmation"
            className="py-2 px-4 mt-2 border w-full rounded-md"
            label="Batas waktu pengukuhan kelompok"
            customInputClassname="border-gray-200"
            placeholder=""
            datepickerOptions={{
              showMonthDropdown: true,
              showYearDropdown: true,
              scrollableYearDropdown: true,
              dropdownMode: "select",
            }}
            showErrorLabel
            // format={"yyyy-MM-dd"}
            // asText={isView}
          /> */}
          {/* <AtomDatePicker
            controllerName="end_date_permission"
            className="py-2 px-4 mt-2 border w-full rounded-md"
            label="Batas Waktu Kepengurusan Anggota"
            customInputClassname="border-gray-200"
            placeholder=""
            datepickerOptions={{
              showMonthDropdown: true,
              showYearDropdown: true,
              scrollableYearDropdown: true,
              dropdownMode: "select",
            }}
            showErrorLabel
            // format={"yyyy-MM-dd"}
            // asText={isView}
          /> */}
          <InputForm
            label="Batas waktu pengukuhan kelompok"
            controllerName="end_date_confirmation"
            className="w-full"
            wrapperClass="gap-1"
          />
          <InputForm
            label="Batas Waktu Kepengurusan Anggota"
            controllerName="end_date_permission"
            className="w-full"
            wrapperClass="gap-1"
          />
        </div>

        <div className="grid grid-cols-2 gap-6">
          {/* <AtomDatePicker
            controllerName="end_date_management"
            className="py-2 px-4 mt-2 border w-full rounded-md"
            label="Batas Akhir Kepengurusan"
            customInputClassname="border-gray-200"
            placeholder=""
            datepickerOptions={{
              showMonthDropdown: true,
              showYearDropdown: true,
              scrollableYearDropdown: true,
              dropdownMode: "select",
            }}
            showErrorLabel
            // format={"yyyy-MM-dd"}
            // asText={isView}
          /> */}

          {/* <AtomDatePicker
            controllerName="end_date_legality"
            className="py-2 px-4 mt-2 border w-full rounded-md"
            label="Batas waktu legalitas akses kelompok/anggota terhadap lahan"
            customInputClassname="border-gray-200"
            placeholder=""
            datepickerOptions={{
              showMonthDropdown: true,
              showYearDropdown: true,
              scrollableYearDropdown: true,
              dropdownMode: "select",
            }}
            showErrorLabel
            // format={"yyyy-MM-dd"}
            // asText={isView}
          /> */}
          <InputForm
            label="Batas Akhir Kepengurusan"
            controllerName="end_date_management"
            className="w-full"
            wrapperClass="gap-1"
          />
          <InputForm
            label="Batas waktu legalitas akses kelompok/anggota terhadap lahan"
            controllerName="end_date_legality"
            className="w-full"
            wrapperClass="gap-1"
          />
        </div>

        <hr />
        <GroupActivities />
        <hr />

        {/* <InputForm
          controllerName="total_member_not_apply"
          className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
          label="Jumlah Anggota Kelompok (Termasuk yang tidak mengajukan pinjaman)"
          placeholder=""
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          // asText={isView}
        /> */}
        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-2">
            <InputForm
              controllerName="total_member_not_apply"
              className="py-2 px-4 mt-2 border w-full rounded-md"
              label="Jumlah Anggota Kelompok (Termasuk yang tidak mengajukan pinjaman)"
              placeholder=""
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            // asText={isView}
            />
          </div>
          <InputForm
            controllerName="total_member_not_apply_persil"
            className="py-2 px-4 mt-2 border w-full rounded-md"
            label="Jumlah Persil"
            placeholder=""
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          // asText={isView}
          />

          <InputForm
            controllerName="total_member_not_apply_area"
            className="py-2 px-4 mt-2 border w-full rounded-md"
            label="Total Luas Lahan"
            placeholder=""
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            subfix={
              <div className="font-medium text-slate-400">
                M<sup>2</sup>
              </div>
            }
          // asText={isView}
          />
        </div>

        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-2">
            <InputForm
              controllerName="total_member_apply"
              className="py-2 px-4 mt-2 border w-full rounded-md"
              label="Jumlah anggota yang mengajukan permohonan"
              placeholder=""
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            // asText={isView}
            />
          </div>
          <InputForm
            controllerName="total_member_apply_persil"
            className="py-2 px-4 mt-2 border w-full rounded-md"
            label="Jumlah Persil"
            placeholder=""
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          // asText={isView}
          />
          <InputForm
            controllerName="total_member_apply_area"
            className="py-2 px-4 mt-2 border w-full rounded-md"
            label="Total Luas Lahan"
            placeholder=""
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            subfix={
              <div className="font-medium text-slate-400">
                M<sup>2</sup>
              </div>
            }
          // asText={isView}
          />
        </div>

        <hr />

        <Partners />
        <hr />
        <FinanceBusinesses />
      </div>
    </CardForm>
  );
};

const CompanionStatusSelect = () => {
  const { data } = useQuery(
    ["master-companion-affiliates-fdb"],
    getCompanionStatus,
    {
      select: (data) => data?.data?.data?.data,
    }
  );

  return (
    <AtomSelect
      controllerName="companion_status_id"
      label="Status Pendamping"
      className="mt-2"
      placeholder=""
      // asText={isView}
      menuPosition="fixed"
      returnObject={false}
      options={data?.map((e) => ({ value: e.id, label: e.name }))}
    />
  );
};

const GroupActivities = () => {
  const { data } = useQuery(["master-category-fdb"], getActivityCategory, {
    select: (data) => data?.data?.data?.data,
  });

  const mutateDeleteActivity = useMutation(
    ["DELETE_ACTIVITY"],
    deleteActivityFormulirPendaftaran,
    {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: "Kegiatan berhasil dihapus",
        });
      },
      onError: (err) => {
        enqueueSnackbar({
          variant: "error",
          message: "Kegiatan gagal dihapus",
        });
      },
    }
  );

  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "activities",
  });

  const handleDelete = (idx, data) => {
    if (data.id) {
      mutateDeleteActivity.mutate(data.id);
      remove(idx);
      return;
    }

    remove(idx);
  };

  return (
    <div className="flex flex-col gap-5">
      <h4 className="text-lg font-semibold text-primary-700">
        Kegiatan - Kegiatan Kelompok yang Telah Dilaksanakan
      </h4>
      {fields.map((field, idx) => (
        <div className="flex flex-row gap-6" key={field.id}>
          <AtomSelect
            controllerName={`activities.${idx}.activity_category_id`}
            label="Kategori"
            className="mt-2"
            placeholder="Pilih Kategori"
            // asText={isView}
            menuPosition="fixed"
            returnObject={false}
            options={data?.map((e) => ({ value: e.id, label: e.name }))}
          />
          <InputForm
            controllerName={`activities.${idx}.description`}
            className="px-4 mt-2 border w-full rounded-md h-[100px]"
            label="Deskripsi"
            placeholder="Deskripsi"
            textArea
          // asText={isView}
          />
          {idx > 0 ? (
            <Button
              theme="error"
              className="text-white self-end h-10 w-11"
              onClick={() =>
                handleDelete(idx, methods.getValues(`activities.${idx}`))
              }
              label={
                <div>
                  <RiDeleteBin6Line size={20} />
                </div>
              }
            />
          ) : null}
        </div>
      ))}
      <hr />
      <div className="flex justify-end">
        <Button
          className="w-max bg-white border-gray-200"
          onClick={() => {
            append({
              id: null,
              activity_category_id: "",
              description: "",
            });
          }}
          outline
          label={
            <>
              <RiAddLine /> <p>Tambah Kegiatan kelompok</p>
            </>
          }
        />
      </div>
    </div>
  );
};

const Partners = () => {
  const methods = useFormContext();

  const mutateDeletePartner = useMutation(
    ["DELETE_PARTNERS"],
    deletePartnerFormulirPendaftaran,
    {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: "Mitra Usaha berhasil dihapus",
        });
      },
      onError: (err) => {
        enqueueSnackbar({
          variant: "error",
          message: "Mitra Usaha gagal dihapus",
        });
      },
    }
  );

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: "partners",
  });

  const handleDelete = (idx, data) => {
    if (data.id) {
      mutateDeletePartner.mutate(data.id);
      remove(idx);
      return;
    }

    remove(idx);
  };

  return (
    <div className="flex flex-col gap-5">
      {fields.map((field, idx) => (
        <div className="flex gap-6" key={field.id}>
          <InputForm
            controllerName={`partners.${idx}.name`}
            className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
            label="Mitra Usaha Kelompok (Jika Ada)"
            placeholder=""
          // asText={isView}
          />
          {idx > 0 ? (
            <Button
              theme="error"
              className="text-white self-end h-10 w-11"
              onClick={() =>
                handleDelete(idx, methods.getValues(`partners.${idx}`))
              }
              label={
                <div>
                  <RiDeleteBin6Line size={20} />
                </div>
              }
            />
          ) : null}
        </div>
      ))}
      <hr />
      <div className="flex justify-end">
        <Button
          className="w-max bg-white border-gray-200"
          onClick={() => {
            append({
              id: null,
              name: "",
            });
          }}
          outline
          label={
            <>
              <RiAddLine /> <p>Tambah mitra usaha kelompok</p>
            </>
          }
        />
      </div>
    </div>
  );
};

const FinanceBusinesses = () => {
  const { data } = useQuery(
    ["master-bussiness-category-fdb"],
    getMasterBussinessCategory,
    {
      select: (data) => data?.data?.data?.data,
    }
  );

  const mutateDeleteFinanceBusiness = useMutation(
    ["DELETE_FINANCE_BUSINESS"],
    deleteFinanceBusinessFormulirPendaftaran,
    {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: "Gambaran Umum Jenis Usaha berhasil dihapus",
        });
      },
      onError: (err) => {
        enqueueSnackbar({
          variant: "error",
          message: "Gambaran Umum Jenis Usaha gagal dihapus",
        });
      },
    }
  );

  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "finance_businesses",
  });

  const handleDelete = (idx, data) => {
    if (data.id) {
      mutateDeleteFinanceBusiness.mutate(data.id);
      remove(idx);
      return;
    }
    remove(idx);
  };

  return (
    <div className="flex flex-col gap-5">
      <h4 className="text-lg font-semibold text-primary-700">
        Gambaran Umum Jenis - Jenis Usaha Anggota Kelompok yang Dibiayai
      </h4>
      {fields.map((field, idx) => (
        <div className="flex flex-row gap-6" key={field.id}>
          <AtomSelect
            controllerName={`finance_businesses.${idx}.finance_business_category_id`}
            label="Kategori"
            className="mt-2"
            placeholder="Pilih Kategori"
            // asText={isView}
            menuPosition="fixed"
            returnObject={false}
            options={data?.map((e) => ({ value: e.id, label: e.name }))}
          />
          <InputForm
            controllerName={`finance_businesses.${idx}.description`}
            className="px-4 mt-2 border w-full rounded-md h-[100px]"
            label="Deskripsi"
            placeholder="Deskripsi"
            textArea
          // asText={isView}
          />
          {idx > 0 ? (
            <Button
              theme="error"
              className="text-white self-end h-10 w-11"
              onClick={() =>
                handleDelete(
                  idx,
                  methods.getValues(`finance_businesses.${idx}`)
                )
              }
              label={
                <div>
                  <RiDeleteBin6Line size={20} />
                </div>
              }
            />
          ) : null}
        </div>
      ))}
      <hr />
      <div className="flex justify-end">
        <Button
          className="w-max bg-white border-gray-200"
          onClick={() => {
            append({
              id: null,
              activity_category_id: "",
              description: "",
            });
          }}
          outline
          label={
            <>
              <RiAddLine /> <p>Tambah Gambaran Umum</p>
            </>
          }
        />
      </div>
    </div>
  );
};

export default InformationGroup;
