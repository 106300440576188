import { Button, ConfirmationModal, Spinner } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { debounce } from "lodash";
import { RiAddLine } from "react-icons/ri";
import { useDisbursement } from "./hooks";
import TablePagination from "components/atoms/Pagination";
import Select from "./components/Select";
import SearchInput from "./components/SearchInput";
import CustomDatepicker from "./components/Datepicker";
import { useNavigate } from "react-router";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import { postSubmitReport } from "services/danaProgram/callForProposalService";
import { enqueueSnackbar } from "notistack";
import { AiOutlineEye } from "react-icons/ai";
import { post, postApprove } from "services/danaProgram/disbursement";

const rowPerPageOptions = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
];

const STATUS_ARRAY = [
  "Menunggu Persetujuan",
  "Disetujui",
  "Perlu Revisi",
  "Ditolak",
];

const StatusBadge = ({ status }) => {
  let defaultDotClass = "bg-gray-500";
  let defaultWrapperClass = "bg-gray-50 text-gray-700";

  switch (status) {
    case "Disetujui":
      defaultDotClass = "bg-bluelight-500";
      defaultWrapperClass = "bg-bluelight-50 text-bluelight-700";
      break;
    case "Menunggu Persetujuan":
      defaultDotClass = "bg-purple-500";
      defaultWrapperClass = "bg-purple-50 text-purple-700";
      break;
    case "Perlu Revisi":
      defaultDotClass = "bg-pink-500";
      defaultWrapperClass = "bg-pink-50 text-pink-700";
      break;
    case "Ditolak":
      defaultDotClass = "bg-red-500";
      defaultWrapperClass = "bg-red-50 text-red-700";
      break;

    default:
      defaultDotClass = "bg-gray-500";
      defaultWrapperClass = "bg-gray-50 text-gray-700";
      break;
  }
  return (
    <div
      className={`rounded-2xl flex items-center border-transparent p-2 text-xs w-fit ${defaultWrapperClass}`}
    >
      <div
        className={`rounded-[50%] w-2 h-2 inline-block mr-1 ${defaultDotClass}`}
      />
      <div className="flex-1 text-center whitespace-nowrap">{status}</div>
    </div>
  );
};

const DisbursementList = () => {
  const navigate = useNavigate();
  const wrapperRef = useRef();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);
  const [dataSource, setDataSource] = useState({});
  const [statusFilter, setStatusFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowPerPage, setRowPerPage] = useState({ value: 10, label: "10" });
  const [modal, setModal] = useState({ title: '', description: '', type: '', open: false, data: null, cancelLabel: '', confirmLabel: '' });
  const params = {
    page: page,
    limit: limit,
    name: keyword,
    status: statusFilter?.value,
    start_date: startDate && moment(startDate).format("YYYY-MM-DD"),
    end_date: endDate && moment(endDate).format("YYYY-MM-DD"),
  };

  const {
    refetch,
    disbursmenetData,
    isLoadingDisbursementData,
    paginationDisbursementData,
  } = useDisbursement({ params });

  useEffect(() => {
    refetch();
  }, [page, limit, keyword, statusFilter, startDate, endDate]);

  useEffect(() => {
    refetch();
  }, []);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const changePage = (val) => {
    setPage(val);
    wrapperRef.current.scrollIntoView({ behaviour: "smooth" });
  };

  const changeRowsPerPage = (page) => {
    setRowPerPage(page);
    setPage(1);
    setLimit(page.value);
    wrapperRef.current.scrollIntoView({ behaviour: "smooth" });
  };

  const onSubmitReport = useMutation({
    mutationKey: ["submit-report-call-for-proposal"],
    mutationFn: async () => {
      const res = await postSubmitReport({ id: modal?.data });
      return res;
    },
    onSuccess: () => {
      setModal(curr => ({
        ...curr,
        type: '',
        title: '',
        open: false,
        data: null,
        cancelLabel: '',
        confirmLabel: ''
      }));
      enqueueSnackbar("Laporan berhasil disubmit", { variant: "success" });
      refetch();
    }
  });

  const batalkanPermohonan = useMutation({
    mutationKey: ["post-batalkan-permohonan-pembayaran"],
    mutationFn: async (payload) => {
      const res = await postApprove(payload);
      return res;
    },
    onSuccess: (res) => {
      enqueueSnackbar("Data Berhasil Disimpan", { variant: "success" });
      navigate(`/disbursement/application`);
    },
  });

  return (
    <Fragment>
      <ConfirmationModal
        useGraphic
        open={modal.open && modal.type === "confirm"}
        onCancel={() => {
          setModal(curr => ({
            ...curr,
            type: '',
            title: '',
            description: '',
            open: false,
            data: null
          }));
        }}
        cancelLabel={modal.cancelLabel}
        confirmLabel={modal.confirmLabel}
        onSubmit={() => onSubmitReport.mutate()}
      >
        <div className="space-y-1">
          <div className="text-lg text-gray-900 font-semibold">
            {modal.title}
          </div>
          <div className="text-sm text-gray-600">
            {modal.description}
          </div>
        </div>
      </ConfirmationModal>
      <div className="space-y-6">
        <div className="bg-white rounded-xl p-10">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: "Permohonan",
                  path: `/disbursement/application`,
                },
              ]}
            />
            <div className="mt-6 flex flex-row">
              <div className="flex-1">
                <div className="flex flex-col">
                  <span
                    className="font-semibold text-[24px] text-gray-900"
                    ref={wrapperRef}
                  >
                    Daftar Permohonan Pencairan
                  </span>
                </div>
              </div>
              <div className="flex-1 text-right">
                <Button
                  type="button"
                  className="rounded-lg bg-secondary-orange-600 border border-secondary-orange-600 text-white px-4 py-2.5"
                  label={
                    <div className="flex items-center gap-2">
                      <RiAddLine />
                      <span className="font-semibold text-sm">
                        Permohonan
                      </span>
                    </div>
                  }
                  onClick={() => navigate("/disbursement/application/form")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-4 p-3 rounded-xl bg-white border border-gray-200">
          {/* <div className="flex gap-5">
            <div className="w-1/3">
              <SearchInput onChange={onSearch} />
            </div>
            <div className="w-1/3">
              <CustomDatepicker
                placeholder="Tanggal Pengajuan"
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);
                }}
                startDate={startDate}
                endDate={endDate}
                onClear={() => {
                  setStartDate(null);
                  setEndDate(null);
                }}
                selectsRange
              />
            </div>
            <div className="w-1/3">
              <Select
                placeholder="Status"
                value={statusFilter}
                optionsData={STATUS_ARRAY.map((x) => ({
                  value: x,
                  label: x,
                }))}
                onChange={setStatusFilter}
                isClearable={true}
                menuPortalTarget={document.body}
              />
            </div>
          </div> */}
          {isLoadingDisbursementData ? (
            <div className="min-h-[500px] flex items-center justify-center">
              <Spinner theme="primary" />
            </div>
          ) : disbursmenetData.length > 0 ? (
            <div className="space-y-4">
              {disbursmenetData.map((x, key) => (
                <div
                  key={key}
                  className="p-5 rounded-xl border border-gray-200 space-y-3"
                >
                  <div className="flex w-full">
                    <div className="flex flex-col w-full">
                      <span className="font-bold">No Pengajuan : {x?._id}</span>
                      <span>Tanggal pengajuan : {x?.created_at ? moment(x?.created_at).format("DD-MM-YYYY") : ''}</span>
                      <span>Nama Project: {x?.project_name}</span>
                      <span>Total Kegiatan : {x?.requirement_item.length}</span>
                    </div>
                    <div className="flex flex-col w-full">
                      <span><StatusBadge status={x?.status} /></span>
                      {/* response data didn't return Tanggal Persetujuan */}
                      {/* <span>Tanggal Persetujuan : -</span> */}
                      <span>Pengajuan Pencairan: {x?.created_at ? moment(x?.created_at).format("DD-MM-YYYY") : ''}</span>
                    </div>
                    <div className="flex flex-col w-[50%] gap-4">
                      <span>
                        <Button
                          type="button"
                          size="sm"
                          className="py-2 px-3.5 bg-red-50 rounded-lg w-full h-full"
                          onClick={() => {
                            batalkanPermohonan.mutate({
                              "id": x?._id,
                              "status": "Dibatalkan"
                            });
                          }}
                          label={
                            <div className="flex items-center gap-2 text-red-700 text-xs font-semibold">
                              <span>Batalkan</span>
                            </div>
                          }
                        />
                      </span>
                      <span>
                        <Button
                          type="button"
                          size="sm"
                          className="py-2 px-3.5 border border-gray-300 bg-white rounded-lg w-full h-full"
                          onClick={() => navigate(`/disbursement/application/${x?._id}`)}
                          label={
                            <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                              <AiOutlineEye size={20} />
                              <span>Lihat Detail</span>
                            </div>
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <hr />
              <div className="flex items-center justify-end px-4 pb-4 pt-3">
                <div className="flex space-x-3 items-center">
                  <p className="mb-0 text-gray-600 text-sm">Show Data</p>
                  <Select
                    value={rowPerPage}
                    optionsData={rowPerPageOptions}
                    onChange={changeRowsPerPage}
                    menuPortalTarget={document.body}
                  />
                  <p className="mb-0 text-gray-600 text-sm">{`${(page - 1) * rowPerPage.value || 1
                    } - ${page <= 1 &&
                      paginationDisbursementData?.total <= rowPerPage.value
                      ? paginationDisbursementData?.total
                      : page * rowPerPage.value
                    } data from ${paginationDisbursementData?.total} data.`}</p>
                </div>
                <div className="flex flex-1 justify-end">
                  <TablePagination
                    page={page}
                    totalPage={paginationDisbursementData?.lastPage}
                    onChange={changePage}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default DisbursementList;
