import { yupResolver } from "@hookform/resolvers/yup";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormRadio,
  InputFormUpload,
} from "components";
import AccordionButton from "components/atoms/AccordionButton";
import AtomDatePicker from "components/atoms/Datepicker";
import AtomSelect from "components/atoms/Select";
import CardForm from "components/molecules/CardForm";
import AsyncDropzoneFieldForm from "components/molecules/async-dropzone-field-form";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";
import {
  financeBankValidator,
  financeBumdesValidator,
  financeKoperasiValidator,
  financeNonKoperasiValidator,
  financePTCVFIRMAValidator,
} from "./ValidationSchema/finance.scheme";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getAgencyRequestById,
  patchAgencyRequestFinance,
} from "services/fdb/lembagaPenyalur";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";

const FinanceForm = ({ prefix, onSubmit, onBack, typeLembaga }) => {
  const optionsFinancialClassification = () => {
    let options = [
      {
        label: "Usaha Kecil (rencana penyaluran FDB < Rp. 5 Miliar)",
        value: "small_bussines",
      },
      {
        label: "Usaha Besar (rencana penyaluran FDB > Rp. 20 Miliar)",
        value: "middle_bussines",
      },
      {
        label: "Usaha Menengah (rencana penyaluran FDB  Rp. 5 - 20 Miliar)",
        value: "high_bussines",
      },
    ];

    if (typeLembaga === "bank-ventura") {
      options = [
        {
          label: "Bank Buku 1 (< 1 Triliun)",
          value: "small_bussines", // required
        },
        {
          label: "Bank Buku 2 (1-5 Triliun)",
          value: "middle_bussines",
        },
        {
          label: "Bank Buku 3 (5-10 Triliun)",
          value: "high_bussines",
        },
        {
          label: "Bank Buku 4 (>10 Triliun)",
          value: "very_high_bussines",
        },
      ];
    }

    return options;
  };

  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const request_id = urlParams.get("request");
  const url = location.pathname.split("/");
  const is_view = url[4] === "view" ? true : false;

  const validationSchema = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "bank-ventura":
        return financeBankValidator;

      case "bumdes":
        return financeBumdesValidator;

      case "koperasi":
        return financeKoperasiValidator;

      case "non-koperasi":
        return financeNonKoperasiValidator;

      case "badan-hukum":
        return financeNonKoperasiValidator;

      default:
        return financePTCVFIRMAValidator;
    }
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema(typeLembaga)),
  });

  const mutateAgencyRequsetFinance = useMutation(
    ["PATCH AGENCY REQUEST FINANCE"],
    ({ data, params }) => {
      return patchAgencyRequestFinance(data, params);
    }
  );

  const { data: dataFinance } = useQuery({
    queryKey: ["request-profile-by-id"],
    queryFn: () => (request_id ? getAgencyRequestById(request_id) : null),
    select: (data) => data?.data?.data?.finance,
  });

  const resetForm = useCallback(() => {
    const currentYear = moment().year();
    const length =
      typeLembaga !== "bumdes" || typeLembaga !== "koperasi" ? 3 : 2;
    const firstYear = currentYear - length;

    if (!isEmpty(dataFinance)) {
      let reportsTemp = [];

      for (let i = 0; i < length; i++) {
        reportsTemp.push({
          name: dataFinance?.agency_finance_report[i]?.name ?? null,
          opinion: dataFinance?.agency_finance_report[i]?.opinion ?? null,
          file: dataFinance?.agency_finance_report[i]?.file ?? null,
          year:
            dataFinance?.agency_finance_report[i]?.year ??
            (firstYear + i).toString(),
        });
      }

      methods.reset({
        quarters:
          dataFinance?.agency_finance_quarter?.length > 0
            ? dataFinance?.agency_finance_quarter.map((dataQuarter) => {
              return {
                report: dataQuarter?.report,
                report_file: dataQuarter?.report_file,
                year: dataQuarter?.year,
              };
            })
            : [
              {
                report: null,
                report_file: "",
                year: `${currentYear}`,
              },
            ],
        reports:
          dataFinance?.agency_finance_report?.length > 0
            ? reportsTemp.sort((a, b) => a.year - b.year)
            : Array(length)
              .fill()
              .map((_, index) => ({
                name: null,
                file: null,
                opinion: null,
                year: `${firstYear + index}`,
              })),
        clasification: dataFinance?.clasification ?? null,
        is_have_experience: dataFinance?.is_have_experience ?? null,
        is_investation: dataFinance?.is_investation ?? null,
        tax_evidance_payment_number:
          dataFinance?.tax_evidance_payment_number ?? null,
        tax_evidance_payment_date: dataFinance?.tax_evidance_payment_date
          ? new Date(dataFinance?.tax_evidance_payment_date)
          : null,
        tax_evidance_payment_file:
          dataFinance?.tax_evidance_payment_file ?? null,
        tax_mandatory: dataFinance?.tax_mandatory ?? null,
        tax_skb_spt_file: dataFinance?.tax_skb_spt_file ?? null,
        tax_skt_date: dataFinance?.tax_skt_date
          ? new Date(dataFinance?.tax_skt_date)
          : null,
        tax_skt_file: dataFinance?.tax_skt_file ?? null,
        tax_skt_number: dataFinance?.tax_skt_number ?? null,
        oprational_file: dataFinance?.oprational_file ?? null,
        tax_registration_date: dataFinance?.tax_registration_date
          ? new Date(dataFinance?.tax_registration_date)
          : null,
      });
      return;
    }

    methods.reset({
      quarters: [
        {
          report: null,
          report_file: "",
          year: `${currentYear}`,
        },
      ],
      reports: Array(length)
        .fill()
        .map((_, index) => {
          return {
            name: null,
            file: null,
            opinion: null,
            year: `${firstYear + index}`,
          };
        }),
    });
  }, [methods, dataFinance, typeLembaga]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const [searchParams, setSearchParams] = useSearchParams();

  const distribution = useMemo(() => {
    let result;

    switch (typeLembaga) {
      case "bank-ventura":
        result = "BANK";
        break;
      case "koperasi":
        result = "KOPERASI";
        break;
      case "non-koperasi":
        result = "NON_KOPERASI";
        break;
      case "badan-hukum":
        result = "LAINNYA";
        break;
      case "bumdes":
        result = "BUMDES";
        break;
      case "cv":
        result = "CV";
        break;
      case "pt":
        result = "PT";
        break;
      case "firma":
        result = "FIRMA";
        break;
      default:
        result = "BANK";
        break;
    }

    return result;
  }, [typeLembaga]);

  const onFormSubmit = useCallback(() => {
    if (is_view) {
      setSearchParams(`step=3&request=${request_id}`);
      return;
    }

    methods.handleSubmit((data) => {
      const dataMap = {
        ...data,
        tax_evidance_payment_date: data?.tax_evidance_payment_date
          ? new Date(data?.tax_evidance_payment_date)
          : null,
        tax_registration_date: data?.tax_registration_date
          ? new Date(data?.tax_registration_date)
          : null,
        tax_skt_date: data?.tax_skt_date ? new Date(data?.tax_skt_date) : null,
      };
      mutateAgencyRequsetFinance.mutate(
        {
          data: dataMap,
          params: {
            request_id: request_id ?? null,
            is_draft: false,
            distribution,
          },
        },
        {
          onSuccess: () => setSearchParams(`step=3&request=${request_id}`),
        }
      );
    })();
  }, [
    distribution,
    is_view,
    methods,
    mutateAgencyRequsetFinance,
    request_id,
    setSearchParams,
  ]);

  const onFormDraft = useCallback(() => {
    const data = methods.getValues();
    mutateAgencyRequsetFinance.mutate(
      {
        data,
        params: {
          request_id: request_id ?? null,
          is_draft: true,
          distribution,
        },
      },
      {
        onSuccess: () => navigate(`/permohonan-pembiayaan/${url[2]}/${url[3]}`),
      }
    );
  }, [
    distribution,
    methods,
    mutateAgencyRequsetFinance,
    navigate,
    request_id,
    url,
  ]);

  const createInputGroup = (label, controllerName, required = false) => (
    <div className="space-y-1">
      <InputForm
        label={label}
        required={required}
        controllerName={controllerName}
        className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
        asText={is_view}
      />
    </div>
  );

  const createDatePickerGroup = (label, controllerName, required = false) => (
    <div className="space-y-1">
      <AtomDatePicker
        label={label}
        controllerName={controllerName}
        customInputClassname="py-2 px-4 border w-full rounded-md flex-1"
        required={required}
        asText={is_view}
        datepickerOptions={{
          maxDate: new Date(),
          showMonthDropdown: true,
          showYearDropdown: true,
          scrollableYearDropdown: true,
          dropdownMode: "select",
        }}
      />
    </div>
  );

  const handleBack = () => {
    navigate(-1);
  };

  const createFormRow = (children) => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">{children}</div>
  );

  const experienceOptions = [
    { label: "Ya Pernah", value: true },
    { label: "Belum Pernah", value: false },
  ];

  const renderExperienceRadioInput = (label, controllerName, condition) => {
    if (condition) {
      return (
        <InputFormRadio
          values={experienceOptions}
          label={label}
          controllerName={controllerName}
          disabled={is_view}
        />
      );
    }
    return null;
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-10">
        <CardForm label="Laporan Keuangan">
          <div className="space-y-6">
            <Fragment>
              <AnimatePresence>
                <ReportAccordionSection is_view={is_view} />
                <FinnancialReportSection is_view={is_view} />
              </AnimatePresence>
            </Fragment>
          </div>
        </CardForm>
        <CardForm label="Klasifikasi Finansial">
          <div className="space-y-6">
            <InputFormRadio
              values={optionsFinancialClassification()}
              label={"Pilih Klasifikasi"}
              controllerName={"clasification"}
              gridClassName="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2"
              usingGrid={true}
              disabled={is_view}
            />
          </div>
        </CardForm>

        <CardForm label="SOP Pengelolaan Keuangan">
          <div className="space-y-6">
            <AsyncDropzoneFieldForm
              required
              label={
                "Upload Dokumen SOP Mengenai Mekanisme penyaluran dan pengelolaan keuangan, audit internal dan external, pengadaan barang dan jasa, pengelolaan aset Dijadikan Satu"
              }
              maxFiles={1}
              name={"oprational_file"}
              maxSize={10}
              informationText="PNG, JPG, PDF, DOCX"
              isView={is_view}
            />

            {renderExperienceRadioInput(
              "Apakah Lembaga memilki pengalaman pembiayaan dalam bidang Usaha kehutanan dan Investasi lingkungan?",
              "is_investation",
              typeLembaga !== "bank-ventura"
            )}

            {renderExperienceRadioInput(
              "Memiliki pengalaman dalam menyusun laporan keuangan secara periodik sesuai mekanisme yang berlaku",
              "is_have_experience",
              typeLembaga !== "bank-ventura" && typeLembaga !== "koperasi"
            )}
          </div>
        </CardForm>

        <CardForm label="Pajak">
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
              <div className="space-y-1">
                <InputForm
                  label={"Bendahara"}
                  required
                  controllerName={"tax_mandatory"}
                  className={"py-2 px-4 mt-2 border w-full rounded-md flex-1"}
                  asText={is_view}
                />
              </div>
              <div className="space-y-1">
                <AtomDatePicker
                  label="Tanggal Registrasi"
                  controllerName={`tax_registration_date`}
                  customInputClassname={`py-2 px-4 border w-full rounded-md flex-1`}
                  required
                  asText={is_view}
                  datepickerOptions={{
                    maxDate: new Date(),
                    showMonthDropdown: true,
                    showYearDropdown: true,
                    scrollableYearDropdown: true,
                    dropdownMode: "select",
                  }}
                />
              </div>
            </div>

            {typeLembaga === "koperasi" ? (
              <>
                {createFormRow(
                  <>
                    {createInputGroup(
                      "Surat Pengukuhan Pengusaha Kena Pajak (PKP)/Non PKP",
                      "tax_skt_number",
                      true
                    )}
                    {createDatePickerGroup(
                      "Tanggal Pengukuhan Pengusaha Kena Pajak (PKP)/Non PKP",
                      "tax_skt_date",
                      true
                    )}
                  </>
                )}
                <AsyncDropzoneFieldForm
                  label="Bukti Surat Pengukuhan Pengusaha Kena Pajak (PKP)/Non PKP"
                  maxFiles={1}
                  informationText="PNG, JPG, PDF, DOCX"
                  name="tax_skt_file"
                  maxSize={10}
                  isView={is_view}
                />
              </>
            ) : (
              <>
                {createFormRow(
                  <>
                    {createInputGroup(
                      "Nomor Surat Keterangan Terdaftar (SKT)",
                      "tax_skt_number",
                      true
                    )}
                    {createDatePickerGroup(
                      "Tanggal Surat Keterangan Terdaftar (SKT)",
                      "tax_skt_date",
                      true
                    )}
                  </>
                )}
                <AsyncDropzoneFieldForm
                  label="Surat Keterangan Terdaftar (SKT)"
                  maxFiles={1}
                  informationText="PNG, JPG, PDF, DOCX"
                  name="tax_skt_file"
                  maxSize={10}
                  isView={is_view}
                  required
                />
              </>
            )}

            {typeLembaga !== "bumdes" && (
              <>
                {createFormRow(
                  <>
                    {createInputGroup(
                      "Nomor Bukti Pelunasan Pajak Tahun Terakhir",
                      "tax_evidance_payment_number",
                      true
                    )}
                    {createDatePickerGroup(
                      "Tanggal Bukti Pelunasan Pajak Tahun Terakhir",
                      "tax_evidance_payment_date",
                      true
                    )}
                  </>
                )}
                <AsyncDropzoneFieldForm
                  label="Bukti Pelunasan Pajak Tahun Terakhir"
                  maxFiles={1}
                  informationText="PNG, JPG, PDF, DOCX"
                  name="tax_evidance_payment_file"
                  maxSize={10}
                  isView={is_view}
                  required
                />
              </>
            )}
            <AsyncDropzoneFieldForm
              label="Upload Dokumen Surat Keterangan Bebas Pajak (SKB) & SPT Dijadikan satu"
              maxFiles={1}
              informationText="PNG, JPG, PDF, DOCX"
              name="tax_skb_spt_file"
              maxSize={10}
              isView={is_view}
            />
          </div>
        </CardForm>

        <BottomFormAction
          backButtonProps={{
            type: "button",
          }}
          draftButtonProps={{
            type: "button",
          }}
          submitButtonProps={{
            type: "submit",
          }}
          submitActionButton={onFormSubmit}
          backButtonAction={handleBack}
          drafButtonAction={onFormDraft}
          disableDrafButton={is_view ? true : false}
        />
      </div>
    </FormProvider>
  );
};

const ReportAccordionSection = ({ is_view }) => {
  const methods = useFormContext();
  const { control, setValue } = methods;

  const { fields: fieldsReports } = useFieldArray({
    control,
    name: "reports",
  });

  const [items, setItems] = useState([]);

  // useEffect(() => {
  //   fieldsReports.map((field, index) => {
  //     setItems((prevState) => [
  //       ...prevState,
  //       {
  //         ...field,
  //         index: index,
  //         open: false,
  //       },
  //     ]);
  //   });
  // }, [fieldsReports]);

  // const optionOpiniAuditor = [
  //   {
  //     label: "Wajar Tanpa Pengecualian",
  //     value: "reasonable_without_exceptions",
  //   },
  //   {
  //     label: "Wajar Tanpa Pengecualian dengan Paragraf",
  //     value: "unqualified_with_paragraph",
  //   },
  //   {
  //     label: "Wajar Dengan Pengecualian",
  //     value: "reasonable_with_exceptions",
  //   },
  //   {
  //     label: "Tidak Wajar",
  //     value: "unreasonable",
  //   },
  //   {
  //     label: "Tidak Memberikan Pendapat",
  //     value: "giving_an_opinion",
  //   },
  // ];

  const handleOpen = (index) => {
    // setItems((prevItems) =>
    //   prevItems.map((item) =>
    //     item.index === index ? { ...item, open: !item.open } : item
    //   )
    // );
  };

  return (
    <>
      {fieldsReports.map((item, index) => (
        <AccordionFinanceReport item={item} idx={index} />
        // <Fragment key={item.id}>
        //   <AccordionButton
        //     label={`Laporan Keuangan Lembaga - Tahun ${item.year}`}
        //     isOpen={item.open}
        //     onClick={() => handleOpen(item.index)}
        //   />
        //   {item.open && (
        //     <motion.div
        //       key={"accordion-currend-content"}
        //       className="space-y-4"
        //       initial={{ opacity: 0 }}
        //       animate={{
        //         opacity: 1,
        //         transition: {
        //           duration: 0.5,
        //         },
        //       }}
        //       exit={{ opacity: 0 }}
        //     >
        //       <div>
        //         <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
        //           <div className="space-y-1">
        //             <InputForm
        //               label={"Nama KAP Mengaudit"}
        //               required
        //               controllerName={`reports.${index}.name`}
        //               className={
        //                 "py-2 px-4 mt-2 border w-full rounded-md flex-1"
        //               }
        //               asText={is_view}
        //             />
        //           </div>
        //           <div className="space-y-1">
        //             <AtomSelect
        //               label={"Opini Auditor"}
        //               required
        //               className={"mt-2 w-full flex-1"}
        //               controllerName={`reports.${index}.opinion`}
        //               options={optionOpiniAuditor}
        //               returnObject={false}
        //               asText={is_view}
        //             />
        //           </div>
        //         </div>

        //         <AsyncDropzoneFieldForm
        //           name={`reports.${index}.file`}
        //           label={"Upload Laporan keuangan"}
        //           maxFiles={1}
        //           maxSize={10}
        //           informationText="PNG, JPG, PDF, DOCX"
        //           shouldRender={item.file !== null}
        //           isView={is_view}
        //         />
        //       </div>
        //     </motion.div>
        //   )}
        // </Fragment>
      ))}
    </>
  );
};

const optionOpiniAuditor = [
  {
    label: "Wajar Tanpa Pengecualian",
    value: "reasonable_without_exceptions",
  },
  {
    label: "Wajar Tanpa Pengecualian dengan Paragraf",
    value: "unqualified_with_paragraph",
  },
  {
    label: "Wajar Dengan Pengecualian",
    value: "reasonable_with_exceptions",
  },
  {
    label: "Tidak Wajar",
    value: "unreasonable",
  },
  {
    label: "Tidak Memberikan Pendapat",
    value: "giving_an_opinion",
  },
];

const AccordionFinanceReport = ({ item, idx }) => {
  const [isOpen, setIsOpen] = useState(false);

  const methods = useFormContext();

  const location = useLocation();
  const renderOptionsView = useCallback((value) => {
    switch (value) {
      case "reasonable_without_exceptions":
        return "Wajar Tanpa Pengecualian";
      case "unqualified_with_paragraph":
        return "Wajar Tanpa Pengecualian dengan Paragraf";
      case "reasonable_with_exceptions":
        return "Wajar Dengan Pengecualian";
      case "unreasonable":
        return "Tidak Wajar";
      default:
        return "Tidak Memberikan Pendapat";
    }
  }, []);

  const isView = location.pathname.includes("view");

  return (
    <Fragment key={item.id}>
      <AccordionButton
        label={`Laporan Keuangan Lembaga - Tahun ${item.year}`}
        isOpen={isOpen}
        onClick={() => setIsOpen((prev) => !prev)}
      />
      {isOpen && (
        <motion.div
          key={"accordion-currend-content"}
          className="space-y-4"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.5,
            },
          }}
          exit={{ opacity: 0 }}
        >
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
              <div className="space-y-1">
                <InputForm
                  label={"Nama KAP Mengaudit"}
                  required
                  controllerName={`reports.${idx}.name`}
                  className={"py-2 px-4 mt-2 border w-full rounded-md flex-1"}
                  asText={isView}
                />
              </div>
              <div className="space-y-1">
                {isView ? (
                  <div className="flex flex-col gap-2">
                    <p className="font-semibold text-sm">Opini Auditor</p>
                    {renderOptionsView(
                      methods.getValues(`reports.${idx}
                    .opinion`)
                    )}
                  </div>
                ) : (
                  <AtomSelect
                    label={"Opini Auditor"}
                    required
                    className={"mt-2 w-full flex-1"}
                    controllerName={`reports.${idx}.opinion`}
                    options={optionOpiniAuditor}
                    returnObject={false}
                    asText={isView}
                  />
                )}
              </div>
            </div>

            <AsyncDropzoneFieldForm
              name={`reports.${idx}.file`}
              label={"Upload Laporan keuangan"}
              maxFiles={1}
              maxSize={10}
              informationText="PNG, JPG, PDF, DOCX"
              shouldRender={item.file !== null}
              isView={isView}
              required
            />
          </div>
        </motion.div>
      )}
    </Fragment>
  );
};

const FinnancialReportSection = ({ is_view }) => {
  const [open, setopen] = useState(true);
  const { control, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "quarters",
  });

  const handleAppend = () => {
    if (fields.length < 4) {
      append({
        name: null,
        file: null,
        opinion: null,
        year: fields[0].year,
      });
    }
  };

  return (
    <Fragment>
      <AccordionButton
        label={`Laporan Keuangan Lembaga - Tahun ${fields[0]?.year}`}
        isOpen={open}
        onClick={() => setopen(!open)}
      />
      {open && (
        <motion.div
          key={"accordion-currend-content"}
          className="space-y-6"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.5,
            },
          }}
          exit={{ opacity: 0 }}
        >
          {fields.map((item, index) => (
            <div key={item.id} className="space-y-4">
              <AtomSelect
                label={"Laporan Keuangan Kuartal"}
                placeholder={"1/2/3/4"}
                options={[
                  {
                    label: "1",
                    value: "1",
                  },
                  {
                    label: "2",
                    value: "2",
                  },
                  {
                    label: "3",
                    value: "3",
                  },
                  {
                    label: "4",
                    value: "4",
                  },
                ]}
                controllerName={`quarters.${index}.report`}
                returnObject={false}
                asText={is_view}
              />
              <AsyncDropzoneFieldForm
                name={`quarters.${index}.report_file`}
                maxFiles={1}
                maxSize={10}
                informationText="PNG, JPG, PDF, DOCX"
                shouldRender={item.file !== null}
                isView={is_view}
              />
              {index > 0 && !is_view && (
                <div className="flex justify-end pt-3">
                  <Button
                    label={
                      <>
                        <RiDeleteBin6Line size={20} />
                        Hapus
                      </>
                    }
                    theme="error"
                    size="sm"
                    type="button"
                    className="text-white h-10"
                    onClick={() => remove(item.id)}
                  />
                </div>
              )}
            </div>
          ))}
          <div className="border-t" />
          <div className="flex flex-row-reverse my-5 mx-4">
            {!is_view && (
              <Button
                className="p-4 border rounded-lg bg-white"
                onClick={handleAppend}
                label={
                  <div className="flex items-center gap-2 font-[14px]">
                    <RiAddLine />
                    Tambah Laporan
                  </div>
                }
              />
            )}
          </div>
        </motion.div>
      )}
    </Fragment>
  );
};

export default FinanceForm;
