import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useRef,
} from 'react';
import { useNavigate } from 'react-router';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import AlertBlankData from 'components/molecules/Alert';
import { ConfirmationModal, HeaderLabel, StepProgress } from 'components';
import StrukturOrganisasi from './StrukturOrganisasi';
import TatakelolaKelembagaan from './TatakelolaLembaga';
import RencanaLembaga from './RencanaLembaga';
import Kemitraan from './Kemitraan';
import Subgrant from './Subgrant';
import Kompetensi from './Kompetensi';
import { createPortal } from 'react-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import {
  updateOrganizationDraftData,
  resetOrganizationDraftData,
  updateCompletedStatus,
} from 'app/actions';
import {
  getOrganizationProfile,
  organizationProfileSubmit,
} from 'services/danaProgram/organizationProfile';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'helpers';
import { isEqual } from 'lodash';
import { ToastContext } from 'components/atoms/Toast';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

const ProfileOrganisasi = () => {
  const dispatch = useDispatch();
  const methods = useForm();
  const navigate = useNavigate();
  const wrapperRef = useRef();
  const { showToastMessage, showToastDescription } = useContext(ToastContext);
  const [activeStep, setActiveStep] = useState(1);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmBackModal, setConfirmBackModal] = useState(false);
  const [confirmDraftModal, setConfirmDraftModal] = useState(false);
  const [organizationProfileDraftData, setOrganizationProfileDraftData] =
    useState();
  const draftDataOnRedux = useSelector(
    (state) => state?.organizationProfileDraftData?.data || {}
  );

  const userId = localStorage.getItem(`userId`);

  const mutation = useMutation({
    mutationFn: (params) => {
      return organizationProfileSubmit(params);
    },
  });

  const getMutation = useMutation({
    mutationFn: async () => {
      const promise = await getOrganizationProfile();
      const arrayData = promise.data.data;
      setOrganizationProfileDraftData(arrayData);
    },
  });

  function extractLabelsFromArray(data) {
    if (data) {
      const labels = data?.map((item) => item.label);
      return labels;
    }
  }

  const consumeAPI = (data, status) => {
    let params;
    if (status === 'DRAFTED') {
      switch (activeStep) {
        case 1:
          params = {
            user_id: userId,
            status: status,
            current_step: activeStep,
            structure_organization: {
              commissioners: data?.commissioners?.map((item, index) => ({
                name: item?.name,
                nik: item?.nik,
                gender: item?.gender,
                email: item?.email,
              })),
              directors: data?.directors?.map((item, index) => ({
                name: item?.name,
                nik: item?.nik,
                gender: item?.gender,
                email: item?.email,
                position: item?.position,
              })),
              supporting_documents: [
                {
                  flag: 'structure-organization',
                  path: data?.structure_organization_path?.id,
                  desc: data?.structure_organization_desc,
                },
                {
                  flag: 'job-desc',
                  path: data?.job_desc_path?.id,
                  desc: data?.job_desc_desc,
                },
                {
                  flag: 'sop',
                  path: data?.sop_path?.id,
                  desc: data?.sop_desc,
                },
              ],
            },
          };
          break;
        case 2:
          params = {
            user_id: userId,
            status: status,
            current_step: activeStep,
            institutional_governance: {
              institutional_transparency: data?.institutional_transparency,
              institutional_transparency_document:
                data?.institutional_transparency_document?.id,
              institutional_transparency_desc:
                data?.institutional_transparency_desc,
              anti_corruption: data?.anti_corruption,
              anti_corruption_document: data?.anti_corruption_document?.id,
              anti_corruption_desc: data?.anti_corruption_desc,
            },
          };
          break;
        case 3:
          params = {
            user_id: userId,
            status: status,
            current_step: activeStep,
            institutional_plan: {
              plan_strategy_organization: data?.plan_strategy_organization,
              plan_strategy_organization_time:
                data?.plan_strategy_organization_time,
              strategy_master: data?.strategy_master,
              hirarki_position: data?.hirarki_position,
              plan_desc: data?.plan_desc,
              organizational_structure_document:
                data?.organizational_structure_document?.id,
              organizational_structure_desc:
                data?.organizational_structure_desc,
              organ_function_document: data?.organ_function_document?.id,
              organ_function_desc: data?.organ_function_desc,
            },
          };
          break;
        case 4:
          params = {
            user_id: userId,
            status: status,
            current_step: activeStep,
            partnership: {
              level_anti_korupsi: data?.level_anti_korupsi,
              partners_total: data?.partners_total,
              partners: [
                {
                  name: data?.partners1_name,
                  cooperation: extractLabelsFromArray(
                    data?.partners1_cooperation
                  ),
                  start_date: data?.partners1_start_date,
                  end_date: data?.partners1_end_date,
                  nation: data?.partners1_nation?.value,
                  province_id: data?.partners1_province_id?.value,
                  city_id: data?.partners1_city_id?.value,
                  district_id: data?.partners1_district_id?.value,
                  village_id: data?.partners1_village_id?.value,
                  zip_code: data?.partners1_zip_code?.value,
                  address: data?.partners1_address,
                  document: data?.partners1_url?.id,
                },
                {
                  name: data?.partners2_name,
                  cooperation: extractLabelsFromArray(
                    data?.partners2_cooperation
                  ),
                  start_date: data?.partners2_start_date,
                  end_date: data?.partners2_end_date,
                  nation: data?.partners2_nation?.value,
                  province_id: data?.partners2_province_id?.value,
                  city_id: data?.partners2_city_id?.value,
                  district_id: data?.partners2_district_id?.value,
                  village_id: data?.partners2_village_id?.value,
                  zip_code: data?.partners2_zip_code?.value,
                  address: data?.partners2_address,
                  document: data?.partners2_url?.id,
                },
              ],
              supporting_documents: [
                {
                  flag: 'mitra',
                  path: data?.supporting_documents[0]?.url?.id,
                  desc: data?.supporting_documents[0]?.desc,
                },
              ],
            },
          };
          break;
        case 5:
          params = {
            user_id: userId,
            status: status,
            current_step: activeStep,
            sub_grant: {
              partners_total: data?.partners_total,
              partners: data?.partners?.map((item) => ({
                partner_name: item?.partner_name,
                partner_field: item?.partner_field,
                partner_document: item?.partner_document?.id,
              })),
            },
          };
          break;
        case 6:
          params = {
            user_id: userId,
            status: status,
            current_step: activeStep,
            competences: data?.competences,
          };
          break;
        default:
          break;
      }
    } else {
      params = {
        user_id: userId,
        status: status,
        current_step: activeStep,
        structure_organization: {
          commissioners:
            draftDataOnRedux?.structure_organization?.commissioners?.map(
              (item, index) => ({
                name: item?.name,
                nik: item?.nik,
                gender: item?.gender,
                email: item?.email,
              })
            ),
          directors: draftDataOnRedux?.structure_organization?.directors?.map(
            (item, index) => ({
              name: item?.name,
              nik: item?.nik,
              gender: item?.gender,
              email: item?.email,
              position: item?.position,
            })
          ),
          supporting_documents: [
            {
              flag: 'structure-organization',
              path: draftDataOnRedux?.structure_organization
                ?.structure_organization_path?.id,
              desc: draftDataOnRedux?.structure_organization
                ?.structure_organization_desc,
            },
            {
              flag: 'job-desc',
              path: draftDataOnRedux?.structure_organization?.job_desc_path?.id,
              desc: draftDataOnRedux?.structure_organization?.job_desc_desc,
            },
            {
              flag: 'sop',
              path: draftDataOnRedux?.structure_organization?.sop_path?.id,
              desc: data?.structure_organization?.sop_desc,
            },
          ],
        },
        institutional_governance: {
          institutional_transparency:
            draftDataOnRedux?.institutional_governance
              ?.institutional_transparency,
          institutional_transparency_document:
            draftDataOnRedux?.institutional_governance
              ?.institutional_transparency_document?.id,
          institutional_transparency_desc:
            draftDataOnRedux?.institutional_governance
              ?.institutional_transparency_desc,
          anti_corruption:
            draftDataOnRedux?.institutional_governance?.anti_corruption,
          anti_corruption_document:
            draftDataOnRedux?.institutional_governance?.anti_corruption_document
              ?.id,
          anti_corruption_desc:
            draftDataOnRedux?.institutional_governance?.anti_corruption_desc,
        },
        institutional_plan: {
          plan_strategy_organization:
            draftDataOnRedux?.institutional_plans?.plan_strategy_organization,
          plan_strategy_organization_time:
            draftDataOnRedux?.institutional_plans
              ?.plan_strategy_organization_time,
          strategy_master:
            draftDataOnRedux?.institutional_plans?.strategy_master,
          hirarki_position:
            draftDataOnRedux?.institutional_plans?.hirarki_position,
          plan_desc: draftDataOnRedux?.institutional_plans?.plan_desc,
          organizational_structure_document:
            draftDataOnRedux?.institutional_plans
              ?.organizational_structure_document?.id,
          organizational_structure_desc:
            draftDataOnRedux?.institutional_plans
              ?.organizational_structure_desc,
          organ_function_document:
            draftDataOnRedux?.institutional_plans?.organ_function_document?.id,
          organ_function_desc:
            draftDataOnRedux?.institutional_plans?.organ_function_desc,
        },
        partnership: {
          level_anti_korupsi: draftDataOnRedux?.partnership?.level_anti_korupsi,
          partners_total: draftDataOnRedux?.partnership?.partners_total,
          partners: [
            {
              name: draftDataOnRedux?.partnership?.partners1_name,
              cooperation: extractLabelsFromArray(
                draftDataOnRedux?.partnership?.partners1_cooperation
              ),
              start_date: draftDataOnRedux?.partnership?.partners1_start_date,
              end_date: draftDataOnRedux?.partnership?.partners1_end_date,
              nation: draftDataOnRedux?.partnership?.partners1_nation?.value,
              province_id:
                draftDataOnRedux?.partnership?.partners1_province_id?.value,
              city_id: draftDataOnRedux?.partnership?.partners1_city_id?.value,
              district_id:
                draftDataOnRedux?.partnership?.partners1_district_id?.value,
              village_id:
                draftDataOnRedux?.partnership?.partners1_village_id?.value,
              zip_code:
                draftDataOnRedux?.partnership?.partners1_zip_code?.value,
              address: draftDataOnRedux?.partnership?.partners1_address,
              document: draftDataOnRedux?.partnership?.partners1_url?.id,
            },
            {
              name: draftDataOnRedux?.partnership?.partners2_name,
              cooperation: extractLabelsFromArray(
                draftDataOnRedux?.partnership?.partners2_cooperation
              ),
              start_date: draftDataOnRedux?.partnership?.partners2_start_date,
              end_date: draftDataOnRedux?.partnership?.partners2_end_date,
              nation: draftDataOnRedux?.partnership?.partners2_nation?.value,
              province_id:
                draftDataOnRedux?.partnership?.partners2_province_id?.value,
              city_id: draftDataOnRedux?.partnership?.partners2_city_id?.value,
              district_id:
                draftDataOnRedux?.partnership?.partners2_district_id?.value,
              village_id:
                draftDataOnRedux?.partnership?.partners2_village_id?.value,
              zip_code:
                draftDataOnRedux?.partnership?.partners2_zip_code?.value,
              address: draftDataOnRedux?.partnership?.partners2_address,
              document: draftDataOnRedux?.partnership?.partners2_url?.id,
            },
          ],
          supporting_documents: [
            {
              flag: 'mitra',
              path: draftDataOnRedux?.partnership?.supporting_documents[0]?.url
                ?.id,
              desc: draftDataOnRedux?.partnership?.supporting_documents[0]
                ?.desc,
            },
          ],
        },
        sub_grant: {
          partners_total: draftDataOnRedux?.subgrant?.partners_total,
          partners: draftDataOnRedux?.subgrant?.partners?.map((item) => ({
            partner_name: item?.partner_name,
            partner_field: item?.partner_field,
            partner_document: item?.partner_document?.id,
          })),
        },
        competences: draftDataOnRedux?.competences?.competences,
      };
    }
    mutation.mutate(params);
  };

  const dispatchData = (data) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => value !== null && value !== undefined && value !== ''
      )
    );

    if (activeStep === 1) {
      if (
        Object.keys(filteredData).length > 0 &&
        !isEqual(
          organizationProfileDraftData?.structure_organization,
          filteredData
        )
      ) {
        dispatch(
          updateOrganizationDraftData({
            structure_organization: { ...filteredData },
          })
        );
      }
    } else if (activeStep === 2) {
      if (
        Object.keys(filteredData).length > 0 &&
        !isEqual(
          organizationProfileDraftData?.institutional_governance,
          filteredData
        )
      ) {
        dispatch(
          updateOrganizationDraftData({
            institutional_governance: { ...filteredData },
          })
        );
      }
    } else if (activeStep === 3) {
      if (
        Object.keys(filteredData).length > 0 &&
        !isEqual(
          organizationProfileDraftData?.institutional_plans,
          filteredData
        )
      ) {
        dispatch(
          updateOrganizationDraftData({
            institutional_plans: { ...filteredData },
          })
        );
      }
    } else if (activeStep === 4) {
      if (
        Object.keys(filteredData).length > 0 &&
        !isEqual(organizationProfileDraftData?.partnership, filteredData)
      ) {
        dispatch(
          updateOrganizationDraftData({
            partnership: { ...filteredData },
          })
        );
      }
    } else if (activeStep === 5) {
      if (
        Object.keys(filteredData).length > 0 &&
        !isEqual(organizationProfileDraftData?.subgrant, filteredData)
      ) {
        dispatch(
          updateOrganizationDraftData({
            subgrant: { ...filteredData },
          })
        );
      }
    } else if (activeStep === 6) {
      if (
        Object.keys(filteredData).length > 0 &&
        !isEqual(organizationProfileDraftData?.competences, filteredData)
      ) {
        dispatch(
          updateOrganizationDraftData({
            competences: { ...filteredData },
          })
        );
      }
    }
  };

  const onDraftSubmit = (data) => {
    consumeAPI(data, 'DRAFTED');
    dispatchData(data);
    setConfirmDraftModal(true);
  };

  const onBack = (data) => {
    consumeAPI(data, 'DRAFTED');
    dispatchData(data);
    setConfirmBackModal(true);
  };

  const onSubmit = (data) => {
    consumeAPI(data, 'DRAFTED');
    dispatchData(data);
    wrapperRef.current.scrollIntoView({ behaviour: 'smooth' });
    if (activeStep < stepProgress.length) {
      setActiveStep(activeStep + 1);
    } else {
      setConfirmModal(true);
    }
  };

  const onSubmitConfirmModal = () => {
    consumeAPI(draftDataOnRedux, 'SUBMITTED');
    dispatch(
      updateCompletedStatus({ profil_organisasi: true }, () => {
        navigate('/penilaian-lemtara');
        showToastMessage(
          'Data Berhasil Disimpan!',
          'Data yang anda masukan telah berhasil disimpan.'
        );
      })
    );
  };

  const onSubmitConfirmBackModal = () => {
    if (activeStep === 1) {
      navigate(-1);
      showToastMessage(
        'Data Berhasil Disimpan!',
        'Data yang anda masukan telah berhasil disimpan.'
      );
    } else {
      setActiveStep(activeStep - 1);
    }
    setConfirmBackModal(false);
  };

  const onSubmitConfirmDraftModal = () => {
    navigate('/penilaian-lemtara');
    showToastMessage(
      'Data Berhasil Disimpan!',
      'Data yang anda masukan telah berhasil disimpan.'
    );
  };

  const stepProgress = useMemo(() => [
    {
      label: 'Struktur Organisasi',
      step: 1,
    },
    {
      label: 'Tata Kelola Kelembagaan',
      step: 2,
    },
    {
      label: 'Rencana Kelembagaan',
      step: 3,
    },
    {
      label: 'Kemitraan',
      step: 4,
    },
    {
      label: 'Subgrant',
      step: 5,
    },
    {
      label: 'Kompetensi',
      step: 6,
    },
  ]);

  useEffect(() => {
    getMutation.mutate();
  }, [activeStep]);

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onCancel={() => setConfirmBackModal(false)}
          onSubmit={onSubmitConfirmModal}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {createPortal(
        <ConfirmationModal
          open={confirmDraftModal}
          onClose={setConfirmDraftModal}
          onCancel={() => setConfirmBackModal(false)}
          onSubmit={() => onSubmitConfirmDraftModal()}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin menyimpan ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda menyimpan sebagai draft anda masih bisa melanjutkan.
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {createPortal(
        <ConfirmationModal
          open={confirmBackModal}
          onCancel={() => setConfirmBackModal(false)}
          onClose={setConfirmBackModal}
          onSubmit={() => onSubmitConfirmBackModal()}
          className="space-y-2 mb-4"
        >
          <div>
            <div className="text-lg font-[600]">
              Apakah anda yakin untuk kembali?
            </div>
            <div className="text-sm font-[400]">
              Setelah anda kembali semua data akan disimpan pada draft.
            </div>
          </div>
        </ConfirmationModal>,
        document.body
      )}
      <div
        className="w-full space-y-6 bg-white  rounded-3xl border p-10 mb-20"
        ref={wrapperRef}
      >
        <BreadCrumbs
          routes={[
            {
              label: 'Penilaian LEMTARA',
              path: '/penilaian-lemtara',
            },
            {
              label: 'Profil Organisasi',
              path: '/penilaian-lemtara/profile-organisasi',
            },
          ]}
        />

        <div className="flex">
          <HeaderLabel
            text="Profil Organisasi"
            description="Silahkan daftar menggunakan data yang Anda miliki."
          />
        </div>

        <div className="border-b" />

        <StepProgress stepProgressData={stepProgress} activeStep={activeStep} />

        <div className="w-full pt-4">
          <AlertBlankData
            title={`Lengkapi Data`}
            body={`Silahkan isi semua data di bawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya.`}
          />
        </div>

        {activeStep === 1 && (
          <StrukturOrganisasi
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={organizationProfileDraftData?.structure_organization}
          />
        )}
        {activeStep === 2 && (
          <TatakelolaKelembagaan
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={organizationProfileDraftData?.institutional_governance}
            dataOnRedux={draftDataOnRedux?.institutional_governance}
          />
        )}
        {activeStep === 3 && (
          <RencanaLembaga
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={organizationProfileDraftData?.institutional_plan}
            dataOnRedux={draftDataOnRedux?.institutional_plans}
          />
        )}
        {activeStep === 4 && (
          <Kemitraan
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={organizationProfileDraftData?.partnership}
            dataOnRedux={draftDataOnRedux?.partnership}
          />
        )}
        {activeStep === 5 && (
          <Subgrant
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={organizationProfileDraftData?.sub_grant}
            dataOnRedux={draftDataOnRedux?.subgrant}
          />
        )}
        {activeStep === 6 && (
          <Kompetensi
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={organizationProfileDraftData?.competence}
            dataOnRedux={draftDataOnRedux?.competences}
          />
        )}
      </div>
    </>
  );
};

export default ProfileOrganisasi;
