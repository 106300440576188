import { setAuthHeader, removeAuthHeader } from "../services/axiosHeaders";

export const SAVE_DRAFT_DATA = "SAVE_DRAFT_DATA";
export const RESET_DRAFT_DATA = "RESET_DRAFT_DATA";
export const UPDATE_DRAFT_DATA = "UPDATE_DRAFT_DATA";
export const SAVE_ORGANIZATION_DRAFT_DATA = "SAVE_ORGANIZATION_DRAFT_DATA";
export const RESET_ORGANIZATION_DRAFT_DATA = "RESET_ORGANIZATION_DRAFT_DATA";
export const UPDATE_ORGANIZATION_DRAFT_DATA = "UPDATE_ORGANIZATION_DRAFT_DATA";
export const SAVE_RESOURCES_DRAFT_DATA = "SAVE_RESOURCES_DRAFT_DATA";
export const RESET_RESOURCES_DRAFT_DATA = "RESET_RESOURCES_DRAFT_DATA";
export const UPDATE_RESOURCES_DRAFT_DATA = "UPDATE_RESOURCES_DRAFT_DATA";
export const RESET_COMPLETED_STATUS = "RESET_COMPLETED_STATUS";
export const UPDATE_COMPLETED_STATUS = "UPDATE_COMPLETED_STATUS";
export const SAVE_FINANCIAL_DRAFT_DATA = "SAVE_FINANCIAL_DRAFT_DATA";
export const RESET_FINANCIAL_DRAFT_DATA = "RESET_FINANCIAL_DRAFT_DATA";
export const UPDATE_FINANCIAL_DRAFT_DATA = "UPDATE_FINANCIAL_DRAFT_DATA";
export const SAVE_REGION_DRAFT_DATA = "SAVE_REGION_DRAFT_DATA";
export const RESET_REGION_DRAFT_DATA = "RESET_REGION_DRAFT_DATA";
export const UPDATE_REGION_DRAFT_DATA = "UPDATE_REGION_DRAFT_DATA";
export const SAVE_USER_DRAFT_DATA = "SAVE_USER_DRAFT_DATA";
export const RESET_USER_DRAFT_DATA = "RESET_USER_DRAFT_DATA";
export const UPDATE_USER_DRAFT_DATA = "UPDATE_USER_DRAFT_DATA";
export const SAVE_MASTER_DATA = "SAVE_MASTER_DATA";
export const RESET_MASTER_DATA = "RESET_MASTER_DATA";
export const UPDATE_MASTER_DATA = "UPDATE_MASTER_DATA";

export const STORE_USER = "STORE_USER";
export const USER_SIGNED_OUT = "USER_SIGNED_OUT";
export const USER_EXPIRED = "USER_EXPIRED";
export const STORE_USER_ERROR = "STORE_USER_ERROR";
export const LOADING_USER = "LOADING_USER";
export const ADD_OIDC_USER = "ADD_OIDC_USER";
export const REMOVE_OIDC_USER = "REMOVE_OIDC_USER";

export const UPDATE_FORM_CFP = "UPDATE_FORM_CFP";
export const STORE_CATEGORY_CFP = "STORE_CATEGORY_CFP";
export const STORE_ID_CFP = "STORE_ID_CFP";
export const STORE_ID_PROPOSAL_PPD = "STORE_ID_PROPOSAL_PPD";
export const STORE_ID_SAFEGUARD = "STORE_ID_SAFEGUARD";
export const RESET_STATE_CFP = "RESET_STATE_CFP";

export const storeUser = (user) => {
  setAuthHeader(user.access_token);
  return {
    type: STORE_USER,
    payload: user,
  };
};

export const loadingUser = () => {
  return {
    type: LOADING_USER,
  };
};

export const storeUserError = () => {
  removeAuthHeader();
  return {
    type: STORE_USER_ERROR,
  };
};

export const userExpired = () => {
  removeAuthHeader();
  return {
    type: USER_EXPIRED,
  };
};

export const userSignedOut = () => {
  removeAuthHeader();
  return {
    type: USER_SIGNED_OUT,
  };
};

export const addOidcUser = (user) => {
  return {
    type: ADD_OIDC_USER,
    payload: user,
  };
};

export const removeOidcUser = () => {
  return {
    type: REMOVE_OIDC_USER,
  };
};

export const saveDraftData = (data) => ({
  type: SAVE_DRAFT_DATA,
  payload: data,
});

export const updateDraftData = (updatedData) => ({
  type: UPDATE_DRAFT_DATA,
  payload: updatedData,
});

export const resetDraftData = () => ({
  type: RESET_DRAFT_DATA,
});

export const saveOrganizationDraftData = (data) => ({
  type: SAVE_ORGANIZATION_DRAFT_DATA,
  payload: data,
});

export const updateOrganizationDraftData = (data) => ({
  type: UPDATE_ORGANIZATION_DRAFT_DATA,
  payload: data,
});

export const resetOrganizationDraftData = () => ({
  type: RESET_ORGANIZATION_DRAFT_DATA,
});

export const saveResourcesDraftData = (data) => ({
  type: SAVE_RESOURCES_DRAFT_DATA,
  payload: data,
});

export const updateResourcesDraftData = (updatedData) => ({
  type: UPDATE_RESOURCES_DRAFT_DATA,
  payload: updatedData,
});

export const resetResourcesDraftData = () => ({
  type: RESET_RESOURCES_DRAFT_DATA,
});

export const saveFinancialDraftData = (data) => ({
  type: SAVE_FINANCIAL_DRAFT_DATA,
  payload: data,
});

export const updateFinancialDraftData = (updatedData) => ({
  type: UPDATE_FINANCIAL_DRAFT_DATA,
  payload: updatedData,
});

export const resetFinancialDraftData = () => ({
  type: RESET_FINANCIAL_DRAFT_DATA,
});

export const saveRegionDraftData = (data) => ({
  type: SAVE_REGION_DRAFT_DATA,
  payload: data,
});

export const updateRegionDraftData = (updatedData) => ({
  type: UPDATE_REGION_DRAFT_DATA,
  payload: updatedData,
});

export const resetRegionDraftData = () => ({
  type: RESET_REGION_DRAFT_DATA,
});

export const saveUserDraftData = (data) => ({
  type: SAVE_USER_DRAFT_DATA,
  payload: data,
});

export const updateUserDraftData = (updatedData) => ({
  type: UPDATE_USER_DRAFT_DATA,
  payload: updatedData,
});

export const resetUserDraftData = () => ({
  type: RESET_USER_DRAFT_DATA,
});

export const updateCompletedStatus = (status, callback) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_COMPLETED_STATUS, payload: status });
    if (callback && typeof callback === "function") {
      callback();
    }
  };
};

export const resetCompletedStatus = () => ({
  type: RESET_ORGANIZATION_DRAFT_DATA,
});

export const updateMasterData = (updatedData) => ({
  type: UPDATE_MASTER_DATA,
  payload: updatedData,
});

export const updateFormCfP = (form) => {
  return {
    type: UPDATE_FORM_CFP,
    payload: form,
  };
};

export const saveCategoryCfP = (category) => {
  return {
    type: STORE_CATEGORY_CFP,
    payload: category,
  };
};

export const saveIdCfP = (id) => {
  return {
    type: STORE_ID_CFP,
    payload: id,
  };
};

export const saveIdProposalPpd = (id) => {
  return {
    type: STORE_ID_PROPOSAL_PPD,
    payload: id,
  };
};

export const saveIdSafeGuard = (id) => {
  return {
    type: STORE_ID_SAFEGUARD,
    payload: id,
  };
};

export const resetStateCfP = () => {
  return {
    type: RESET_STATE_CFP,
  };
};
