import React, { Fragment, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";

import {
  BottomFormAction,
  Button,
  ConfirmationModal,
  HeaderLabel,
  InputForm,
  InputFormRadio,
  Label,
} from "components";
import AlertBlankData from "components/molecules/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import AtomSelect from "components/atoms/Select";
import { RiAddLine, RiDeleteBinLine, RiQuestionLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import WarningAlert from "../components/WarningAlert";
import { AnimatePresence, motion } from "framer-motion";
import AccordionButton from "components/atoms/AccordionButton";
import { FaTrashAlt } from "react-icons/fa";
import InputFormDropzone from "components/molecules/InputFormDropzone";
import { updateFormCfP } from "app/actions";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fileUpload } from "services/danaProgram/fileService";
import {
  getDetailBeneficiaries,
  getDetailProposal,
  getGeneralInformation,
  postBeneficiaries,
} from "services/danaProgram/callForProposalService";
import { enqueueSnackbar } from "notistack";
import FileDetail from "components/molecules/FileDetail";

const QuestionAlert = ({ text = "" }) => {
  return (
    text && (
      <div className="flex gap-2 border rounded-xl p-4 items-center bg-white">
        <RiQuestionLine size={20} />
        <Label className="font-semibold" text={text} />
      </div>
    )
  );
};
const validationSchema = yup.object({
  indigenous_communities: yup
    .boolean()
    .required("Pilihan ini wajib diisi")
    .typeError("Pilihan ini wajib diisi"),
  indigenous_communities_description: yup
    .string()
    .required("Masyarakat Adat yang Dilibatkan wajib diisi"),
  indigenous_communities_document: yup.mixed().nullable(),
  traditional_law_communities: yup
    .boolean()
    .required("Pilihan ini wajib diisi")
    .typeError("Pilihan ini wajib diisi"),
  traditional_law_communities_description: yup
    .string()
    .required("Masyarakat Hukum Adat yang Dilibatkan wajib diisi"),
  traditional_law_communities_document: yup.mixed().nullable(),
  vulnerable_communities: yup
    .boolean()
    .required("Pilihan ini wajib diisi")
    .typeError("Pilihan ini wajib diisi"),
  vulnerable_communities_document: yup.mixed().nullable(),
  total_woman: yup.string(),
  total_man: yup.string(),
  total_beneficiaries: yup.string().required("Jumlah Total wajib diisi"),
  beneficiaries: yup.array().of(
    yup.object().shape({
      community_name: yup.string().required("Nama Kelompok wajib diisi"),
      community_leader: yup.string().required("Ketua Kelompok wajib diisi"),
      beneficiaries_type: yup.array().of(
        yup
          .object()
          .shape({
            value: yup.string(),
            label: yup.string(),
          })
          .required()
      ),
      permission_year: yup.string().nullable(),
      permission_type: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .nullable(),
      forestry_permit_status: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .nullable(),
      permitted_land_area: yup.string(),
      total_woman: yup.string(),
      total_man: yup.string(),
      total_beneficiaries: yup.string().required("Jumlah Total wajib diisi"),
      activities: yup.array().of(
        yup.object().shape({
          activity: yup.string(),
        })
      ),
    })
  ),
});

const defaultValues = {
  indigenous_communities: "",
  indigenous_communities_description: "",
  indigenous_communities_document: [],
  traditional_law_communities: "",
  traditional_law_communities_description: "",
  traditional_law_communities_document: [],
  vulnerable_communities: "",
  vulnerable_communities_document: [],
  total_woman: "0",
  total_man: "0",
  total_beneficiaries: "0",
  beneficiaries: [
    {
      community_name: "",
      community_leader: "",
      beneficiaries_type: null,
      permission_year: null,
      permission_type: null,
      forestry_permit_status: null,
      permitted_land_area: "",
      total_woman: "0",
      total_man: "0",
      total_beneficiaries: "",
      activities: [
        {
          activity: "",
        },
      ],
    },
  ],
};

const NestedActivities = ({ nestedIndex, control }) => {
  const {
    fields: activityFields,
    append: appendActivity,
    remove: removeActivity,
  } = useFieldArray({
    control,
    name: `beneficiaries[${nestedIndex}].activities`,
  });

  return (
    <div>
      <div className="flex gap-1 font-semibold text-[14px] mb-2">
        <span className={`label-text`}>
          Kegiatan Ekonomi/ Lingkungan yang Sudah Dilakukan Sejak Terbitnya Izin
        </span>
      </div>
      <div className="space-y-5">
        {activityFields.map((field, key) => (
          <div className="flex gap-6 items-start" key={field.id}>
            <InputForm
              controllerName={`beneficiaries[${nestedIndex}].activities.${key}.activity`}
              className={`border w-full rounded-md flex-1`}
            />
            {activityFields.length > 1 && (
              <Button
                type="button"
                className="rounded-lg bg-error-600 text-white shadow py-2 px-3.5 items-center font-semibold h-10"
                label={<RiDeleteBinLine size={20} className="text-white" />}
                onClick={() => {
                  removeActivity(key);
                }}
              />
            )}
          </div>
        ))}
      </div>
      <div className="flex items-center justify-end mt-5">
        <Button
          className="rounded-lg border border-gray-300 shadow py-2.5 px-4"
          label={
            <div className="flex gap-2 items-center text-gray-700 text-sm">
              <RiAddLine size={20} />
              Kegiatan
            </div>
          }
          onClick={() => {
            appendActivity({
              activity: "",
            });
          }}
        />
      </div>
    </div>
  );
};

const Beneficiaries = () => {
  const navigate = useNavigate();
  const [initIndigenousDocs, setInitIndigenousDocs] = useState([]);
  const [initTraditionalDocs, setInitTraditionalDocs] = useState([]);
  const [initVulnerableDocs, setInitVulnerableDocs] = useState([]);
  const [accordion, setAccordion] = useState([
    {
      open: true,
      index: 0,
      name: "Beneficiaries 1",
    },
  ]);
  const [showConfirmModal, setConfirmModal] = useState({
    open: false,
    type: "draft",
    cancelLabel: "Tidak",
    confirmLabel: "Iya, simpan draft",
    title: "",
    description: "",
  });
  const [payload, setPayload] = useState(null);
  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { control, setValue, getValues } = methods;
  const { id } = useSelector((state) => state?.callForProposalData?.data);
  // const { id } = useParams();

  const callForProposalData = useSelector(
    (state) => state?.callForProposalData?.data
  ).category;

  const beneficiariesId = callForProposalData?.filter(
    (item) => item.id === "beneficiaries"
  )?.[0]?.data?._id;

  const generalInformationId = useSelector(
    (state) => state?.callForProposalData?.data
  ).id;

  const proposalPpdId = useSelector(
    (state) => state?.callForProposalData?.data
  )?.idProposalPpd;

  const {
    fields: beneficiaryFields,
    append: appendBeneficiary,
    remove: removeBeneficiary,
  } = useFieldArray({
    control,
    name: "beneficiaries",
  });

  const indigenousRadio = useWatch({
    control,
    name: "indigenous_communities",
  });

  const traditionalLaw = useWatch({
    control,
    name: "traditional_law_communities",
  });

  useEffect(() => {
    if (!traditionalLaw)
      setValue("traditional_law_communities_description", "-");
    if (!indigenousRadio) setValue("indigenous_communities_description", "-");
  }, [traditionalLaw, indigenousRadio]);

  const saveDraft = (values) => {
    setPayload({ ...values, status: "DRAFT" });
    setConfirmModal((curr) => ({
      ...curr,
      open: true,
      type: "draft",
      cancelLabel: "Tidak",
      confirmLabel: "Iya, simpan draft",
      title: "Anda yakin akan menyimpan ini sebagai draft?",
      description:
        "Setelah anda menyimpan sebagai draft anda masih bisa melanjutkannya",
    }));
  };

  const submitForm = (values) => {
    setPayload({ ...values, status: "SUBMIT" });
    setConfirmModal((curr) => ({
      ...curr,
      open: true,
      type: "submit",
      cancelLabel: "Tidak, ubah data",
      confirmLabel: "Ya, kirimkan",
      title: "Anda yakin akan mengirimkan semua data ini?",
      description:
        "Dengan ini kami menyatakan bahwa Seluruh data yang kami berikan pada formulir ini adalah benar adanya. Jika dikemudian hari BPDLH menemukan unsur kebohongan dan atau pemalsuan data, kami siap dan bersedia untuk diajukan ke ranah hukum sesuai dengan undang-undang yang berlaku. ",
    }));
  };

  const onSubmit = () => {
    postFile.mutate();
  };

  const postFile = useMutation({
    mutationKey: ["post-file-beneficiaries"],
    mutationFn: async () => {
      const data = payload.indigenous_communities_document?.map(
        async (item) => {
          const formData = new FormData();
          formData.append("file", item);
          const res = await fileUpload(formData);
          return {
            fileId: res.data.data.id,
            fileName: res.data.data.name,
            fileSize: res.data.data.size,
            mimeType: res.data.data.mimeType,
            path: res.data.data.key,
          };
        }
      );
      const data2 = payload.traditional_law_communities_document?.map(
        async (item) => {
          const formData = new FormData();
          formData.append("file", item);
          const res = await fileUpload(formData);
          return {
            fileId: res.data.data.id,
            fileName: res.data.data.name,
            fileSize: res.data.data.size,
            mimeType: res.data.data.mimeType,
            path: res.data.data.key,
          };
        }
      );
      const data3 = payload.vulnerable_communities_document?.map(
        async (item) => {
          const formData = new FormData();
          formData.append("file", item);
          const res = await fileUpload(formData);
          return {
            fileId: res.data.data.id,
            fileName: res.data.data.name,
            fileSize: res.data.data.size,
            mimeType: res.data.data.mimeType,
            path: res.data.data.key,
          };
        }
      );
      return Promise.all([...(data || []), ...(data2 || []), ...(data3 || [])]);
    },
    onSuccess: (data) => {
      submitMutation.mutate({
        ...payload,
        call_for_proposal_id: id,
        indigenous_communities_document: data?.[0]
          ? [data[0], ...initIndigenousDocs]
          : [...initIndigenousDocs],
        traditional_law_communities_document: data?.[1]
          ? [data[1], ...initTraditionalDocs]
          : [...initTraditionalDocs],
        vulnerable_communities_document: data?.[2]
          ? [data?.[2], ...initVulnerableDocs]
          : [...initVulnerableDocs],
      });
    },
  });

  const submitMutation = useMutation({
    mutationKey: ["post-beneficiaries"],
    mutationFn: async (payload) => {
      const res = await postBeneficiaries(payload);
      return res;
    },
    onSuccess: (res) => {
      enqueueSnackbar("Data Berhasil Disimpan", { variant: "success" });
      navigate(`/proposal/call-for-proposal/${id}`);
      setConfirmModal(false);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const totalWoman = useWatch({
    control,
    name: "total_woman",
  });

  const totalMan = useWatch({
    control,
    name: "total_man",
  });

  useEffect(() => {
    setValue(
      "total_beneficiaries",
      parseInt(totalMan) + parseInt(totalWoman) || "0"
    );
  }, [totalMan, totalWoman]);

  // for detail beneficiaries

  const { data: detailGeneralInformation } = useQuery({
    enabled: !beneficiariesId && !!generalInformationId,
    queryKey: [`get-general-information`],
    queryFn: async () => {
      const res = await getGeneralInformation({ id: generalInformationId });
      return res.data.data;
    },
  });

  useEffect(() => {
    if (detailGeneralInformation) {
      getValues(`beneficiaries`)?.map((item, index) => {
        setValue(
          `beneficiaries.${index}.total_man`,
          detailGeneralInformation?.note_concept?.total_beneficiaries?.male
        );
        setValue(
          `beneficiaries.${index}.total_woman`,
          detailGeneralInformation?.note_concept?.total_beneficiaries?.female
        );
        setValue(
          `beneficiaries.${index}.total_beneficiaries`,
          detailGeneralInformation?.note_concept?.total_beneficiaries?.total
        );
      });
      setValue(
        `total_man`,
        detailGeneralInformation?.note_concept?.total_beneficiaries?.male
      );
      setValue(
        `total_woman`,
        detailGeneralInformation?.note_concept?.total_beneficiaries?.female
      );
    }
  }, [detailGeneralInformation]);

  const { data } = useQuery({
    enabled: !!beneficiariesId,
    queryKey: [`get-detail-beneficiaries`],
    queryFn: async () => {
      const res = await getDetailBeneficiaries(beneficiariesId);
      return res.data.data;
    },
  });

  const { data: proposalDetail } = useQuery({
    enabled: !!generalInformationId,
    queryKey: ["get-detail-proposal"],
    queryFn: async () => {
      const res = await getDetailProposal(proposalPpdId);

      return res.data.data;
    },
  });

  useEffect(() => {
    if (data) {
      // Set Accordion

      let accordionTotal = data.beneficiaries.map((item, index) => {
        return {
          open: true,
          index: index,
          name: `Beneficiaries ${index + 1}`,
        };
      });

      setAccordion(accordionTotal);

      // Reset Form with Detail Value
      methods.reset({
        indigenous_communities: data.indigenous_communities,
        indigenous_communities_description:
          data.indigenous_communities_description,
        traditional_law_communities: data.traditional_law_communities,
        traditional_law_communities_description:
          data.traditional_law_communities_description,
        vulnerable_communities: data.vulnerable_communities,
        total_man: data.total_man,
        total_woman: data.total_woman,
        total_beneficiaries: data.total_beneficiaries,
        beneficiaries: data.beneficiaries,
      });
      setInitIndigenousDocs(data.indigenous_communities_document || []);
      setInitTraditionalDocs(data.traditional_law_communities_document || []);
      setInitVulnerableDocs(data.vulnerable_communities_document || []);
    }
  }, [data]);

  // end for detail beneficiaries

  return (
    <Fragment>
      <ConfirmationModal
        useGraphic
        open={showConfirmModal.open}
        onCancel={() => {
          setConfirmModal((curr) => ({
            ...curr,
            open: false,
          }));
          setPayload(null);
        }}
        isLoading={postFile.isLoading || submitMutation.isLoading}
        cancelLabel={showConfirmModal.cancelLabel}
        confirmLabel={showConfirmModal.confirmLabel}
        onSubmit={onSubmit}
      >
        <div className="space-y-1">
          <div className="text-lg text-gray-900 font-semibold">
            {showConfirmModal.title}
          </div>
          <div className="text-sm text-gray-600">
            {showConfirmModal.description}
          </div>
        </div>
      </ConfirmationModal>
      <div className="space-y-6 w-full">
        <div className="bg-white rounded-xl p-10 space-y-8">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: "Call For Proposal",
                  path: `/proposal/call-for-proposal`,
                },
                {
                  label: "Pengajuan Full Proposal",
                },
              ]}
            />
            <div className="flex mt-5 gap-4">
              <div className="flex-1">
                <HeaderLabel text="Beneficiaries" />
              </div>
            </div>
          </div>
          <div className="w-full">
            <AlertBlankData
              title={`Lengkapi Data`}
              body={`Silakan isi semua data dibawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya.`}
            />
          </div>
          <FormProvider {...methods}>
            <CardForm label="Beneficiaries/Informasi Kelompok Rentan">
              <div className="space-y-5">
                <WarningAlert text="Lengkapi daftar beneficiaries yang akan dilibatkan dalam kegiatan Anda!" />
                {beneficiaryFields.map((field, index) => (
                  <BeneficiariesSection
                    field={field}
                    index={index}
                    accordion={accordion}
                    setAccordion={setAccordion}
                    beneficiaryFields={beneficiaryFields}
                    appendBeneficiary={appendBeneficiary}
                    removeBeneficiary={removeBeneficiary}
                    proposalDetail={proposalDetail}
                  />
                ))}
                <hr />
                <QuestionAlert text="Apakah kegiatan anda melibatkan beneficiaries dari kalangan masyarakat adat?" />
                <div className="flex gap-4">
                  <div className="flex-1">
                    <InputFormRadio
                      controllerName={`indigenous_communities`}
                      values={[
                        {
                          label: "Ya",
                          value: true,
                        },
                        {
                          label: "Tidak",
                          value: false,
                        },
                      ]}
                    />
                  </div>
                </div>
                <InputForm
                  controllerName={"indigenous_communities_description"}
                  className={`mt-2 border w-full rounded-md flex-1`}
                  disabled={!indigenousRadio}
                  label="Tuliskan Masyarakat Adat yang Dilibatkan Tersebut"
                  required
                />
                <div className="space-y-2">
                  <div className="text-[#1D2939] font-semibold text-sm">
                    Upload Dokumen Bukti Pendukung (Surat Kebersediaan/ Dokumen
                    Kerjasama dengan Beneficieries / dll)
                  </div>
                  {initIndigenousDocs.length > 0 ? (
                    <>
                      {initIndigenousDocs.map((item) => (
                        <FileDetail
                          file={item}
                          onRemove={() => {
                            const filtered = initIndigenousDocs.filter(
                              (file) => file.fileId !== item.fileId
                            );
                            setInitIndigenousDocs(filtered);
                          }}
                        />
                      ))}
                    </>
                  ) : (
                    <InputFormDropzone
                      name="indigenous_communities_document"
                      accept={{
                        "image/png": [],
                        "image/jpeg": [],
                        "application/pdf": [],
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                          [],
                      }}
                      maxFiles={1}
                      maxSize={10}
                      multiple={false}
                      informationText="PNG, JPG, PDF, Docx"
                    />
                  )}
                </div>
                <QuestionAlert text="Apakah Masyarakat sudah menerima dokumen SK dari pemerintah?" />
                <div className="flex gap-4">
                  <div className="flex-1">
                    <InputFormRadio
                      controllerName={`traditional_law_communities`}
                      values={[
                        {
                          label: "Ya",
                          value: true,
                        },
                        {
                          label: "Tidak",
                          value: false,
                        },
                      ]}
                    />
                  </div>
                </div>
                <InputForm
                  controllerName={"traditional_law_communities_description"}
                  className={`mt-2 border w-full rounded-md flex-1`}
                  disabled={!traditionalLaw}
                  label="Tuliskan Masyarakat Hukum Adat yang Dilibatkan Tersebut"
                  required
                />
                <div className="space-y-2">
                  <div className="text-[#1D2939] font-semibold text-sm">
                    Upload Dokumen Bukti Pendukung (Surat Kebersediaan/ Dokumen
                    Kerjasama dengan Beneficieries / dll)
                  </div>
                  {initTraditionalDocs.length > 0 ? (
                    <>
                      {initTraditionalDocs.map((item) => (
                        <FileDetail
                          file={item}
                          onRemove={() => {
                            const filtered = initTraditionalDocs.filter(
                              (file) => file.fileId !== item.fileId
                            );
                            setInitTraditionalDocs(filtered);
                          }}
                        />
                      ))}
                    </>
                  ) : (
                    <InputFormDropzone
                      name="traditional_law_communities_document"
                      accept={{
                        "image/png": [],
                        "image/jpeg": [],
                        "application/pdf": [],
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                          [],
                      }}
                      maxFiles={1}
                      maxSize={10}
                      multiple={false}
                      informationText="PNG, JPG, PDF, Docx"
                    />
                  )}
                </div>
                <QuestionAlert text="Apakah kegiatan anda melibatkan beneficiaries dari perempuan dan kelompok rentan?" />
                <div className="flex gap-4">
                  <div className="flex-1">
                    <InputFormRadio
                      controllerName={`vulnerable_communities`}
                      values={[
                        {
                          label: "Ya",
                          value: true,
                        },
                        {
                          label: "Tidak",
                          value: false,
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="text-[#1D2939] font-semibold text-sm">
                    Upload Dokumen Bukti Keterlibatan Perempuan dan Kelompok
                    Rentan (Daftar Kelompok/ Dokumen Pendukung Lainnya)
                  </div>
                  {initVulnerableDocs.length > 0 ? (
                    <>
                      {initVulnerableDocs.map((item) => (
                        <FileDetail
                          file={item}
                          onRemove={() => {
                            const filtered = initVulnerableDocs.filter(
                              (file) => file.fileId !== item.fileId
                            );
                            setInitVulnerableDocs(filtered);
                          }}
                        />
                      ))}
                    </>
                  ) : (
                    <InputFormDropzone
                      name="vulnerable_communities_document"
                      accept={{
                        "image/png": [],
                        "image/jpeg": [],
                        "application/pdf": [],
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                          [],
                      }}
                      maxFiles={1}
                      maxSize={10}
                      multiple={false}
                      informationText="PNG, JPG, PDF, Docx"
                    />
                  )}
                </div>
                <QuestionAlert text="Berapa jumlah beneficiaries yang dilibatkan dalam kegiatan ini?" />
                <div className="flex gap-6">
                  <InputForm
                    controllerName={"total_woman"}
                    className={`mt-2 border flex-1 rounded-md w-full`}
                    label="Jumlah Perempuan"
                    required
                  />
                  <InputForm
                    controllerName={"total_man"}
                    className={`mt-2 border flex-1 rounded-md w-full`}
                    label="Jumlah Laki-laki"
                    required
                  />
                  <InputForm
                    controllerName={"total_beneficiaries"}
                    className={`mt-2 border flex-1 rounded-md w-full`}
                    label="Total"
                    required
                    disabled
                  />
                </div>
              </div>
            </CardForm>
            <BottomFormAction
              lastStep
              backButtonAction={() => navigate(-1)}
              disableDrafButton={false}
              drafButtonAction={() => {
                const values = methods.getValues();
                saveDraft(values);
              }}
              // disableButtonSubmit={isValid ? false : true}
              submitActionButton={() => {
                methods.handleSubmit(submitForm)();
              }}
            />
          </FormProvider>
        </div>
      </div>
    </Fragment>
  );
};

const BeneficiariesSection = ({
  field,
  index,
  accordion,
  setAccordion,
  beneficiaryFields,
  appendBeneficiary,
  removeBeneficiary,
  proposalDetail,
}) => {
  const { control, setValue } = useFormContext();
  const toggleAccordion = (index) => {
    const temp = [...accordion];
    temp[index].open = !temp[index].open;
    setAccordion(temp);
  };
  const totalWoman = useWatch({
    name: `beneficiaries.${index}.total_woman`,
  });
  const totalMan = useWatch({
    name: `beneficiaries.${index}.total_man`,
  });

  useEffect(() => {
    setValue(
      `beneficiaries.${index}.total_beneficiaries`,
      parseInt(totalWoman) + parseInt(totalMan) || 0
    );
  }, [totalWoman, totalMan]);

  return (
    <Fragment>
      <AnimatePresence>
        <AccordionButton
          key={field.id}
          label={accordion?.[index]?.name}
          isOpen={accordion?.[index]?.open}
          onClick={() => toggleAccordion(index)}
        />

        {accordion?.[index]?.open && (
          <motion.div
            key={"accordion-currcond-content"}
            className="space-y-4"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <div className="space-y-5">
              <div className="flex gap-6">
                <div className="w-1/3">
                  <InputForm
                    controllerName={`beneficiaries.${index}.community_name`}
                    className={`border flex-1 rounded-md w-full`}
                    label="Nama Kelompok / Lembaga"
                    required
                  />
                </div>
                <div className="w-1/3">
                  <InputForm
                    controllerName={`beneficiaries.${index}.community_leader`}
                    className={`border flex-1 rounded-md w-full`}
                    label="Ketua Kelompok / Lembaga"
                    required
                  />
                </div>
                <div className="w-1/3">
                  <AtomSelect
                    controllerName={`beneficiaries.${index}.beneficiaries_type`}
                    className="basic-single flex-1"
                    wrapperClass={"col-span-1"}
                    label="Tipe Beneficiaries"
                    required
                    isMulti
                    returnObject={true}
                    options={(
                      proposalDetail?.distribution?.beneficiary || []
                    ).map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />
                </div>
              </div>
              <div className="flex gap-6">
                <div className="w-1/3">
                  <InputForm
                    controllerName={`beneficiaries.${index}.total_woman`}
                    className={`border flex-1 rounded-md w-full`}
                    label="Jumlah Perempuan"
                  />
                </div>
                <div className="w-1/3">
                  <InputForm
                    controllerName={`beneficiaries.${index}.total_man`}
                    className={`border flex-1 rounded-md w-full`}
                    label="Jumlah Laki-laki"
                  />
                </div>
                <div className="w-1/3">
                  <InputForm
                    controllerName={`beneficiaries.${index}.total_beneficiaries`}
                    className={`border flex-1 rounded-md w-full`}
                    label="Total"
                    disabled
                  />
                </div>
              </div>
              <NestedActivities control={control} nestedIndex={index} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <hr />
      <div className="flex items-center justify-center gap-6">
        {beneficiaryFields.length > 1 && (
          <Button
            className="rounded-lg border border-error-300 py-2.5 px-4"
            onClick={() => {
              setAccordion((curr) => curr.filter((x) => x.index !== index));
              removeBeneficiary(index);
            }}
            label={
              <div className="flex gap-2 items-center text-sm text-error-700">
                <FaTrashAlt size={20} className="text-error-700" />
                Beneficiaries
              </div>
            }
          />
        )}
        {((index > 0 && index === beneficiaryFields.length - 1) ||
          (index === 0 && beneficiaryFields.length === 1)) && (
          <Button
            className="rounded-lg border border-gray-300 shadow py-2.5 px-4"
            label={
              <div className="flex gap-2 items-center text-gray-700 text-sm">
                <RiAddLine size={20} />
                Beneficiaries
              </div>
            }
            onClick={() => {
              setAccordion((curr) => [
                ...curr,
                {
                  open: false,
                  name: `Beneficiaries ${curr.length + 1}`,
                  index: curr.length,
                },
              ]);
              appendBeneficiary({
                community_name: "",
                community_leader: "",
                beneficiaries_type: null,
                permission_year: null,
                permission_type: null,
                forestry_permit_status: null,
                permitted_land_area: "",
                total_woman: "0",
                total_man: "0",
                total_beneficiaries: "0",
                activities: [
                  {
                    activity: "",
                  },
                ],
              });
            }}
          />
        )}
      </div>
    </Fragment>
  );
};

export default Beneficiaries;
