import { Button } from "components";
import React, { useRef, useState } from "react";

import { Controller, useFormContext } from "react-hook-form";
import {
  RiUploadCloud2Line,
  RiFile2Line,
  RiDeleteBin6Line,
} from "react-icons/ri";
import { humanizeFileSize } from "../../../helpers";
import { useMutation } from "@tanstack/react-query";
import {
  fileUpload,
  getFile,
  getUrlFile,
} from "services/danaProgram/fileService";
import { createPortal } from "react-dom";
import { ConfirmationModal } from "components";
import UploadDocModal from "../Modal/UploadDocModal";
import { template } from "lodash";
import { useEffect } from "react";
import { IoMdImage } from "react-icons/io";
import { useDropzone } from "react-dropzone";

const InputFormUpload = ({
  controllerName,
  className,
  label,
  required = false,
  textArea = false,
  trigger,
  maxSize = 100 * 1024 * 1024,
  fileType = "PNG, JPG, PDF, Docx",
  accept = {
    "image/png": [],
    "image/jpeg": [],
    "application/pdf": [],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      [],
    "application/msword": [],
  },
  buttonLabel,
  isMulti = false,
  onUploadMultiple,
  uploadFile,
  generateUrl,
  setUploadedUrls,
  setControllerTarget,
  isDaprog,
  methods,
  maxFiles,
  onModalSubmit = () => {},
  ...props
}) => {
  const refInput = useRef(controllerName);
  const [valueParams, setValueParams] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [imageUrl, setImageUrl] = useState([]);
  const [prevControllerName, setPrevControllerName] = useState("");
  const [size, setSize] = useState();
  const [images, setImages] = useState([]);
  const { control, setValue, watch } = useFormContext();
  const [hoveredFile, setHoveredFile] = useState(null);
  const [errors, setErrors] = useState([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...props,
    multiple: isMulti,
    maxSize: maxSize,
    maxFiles: maxFiles,
    accept,
  });

  const handleMouseEnter = (id) => {
    setHoveredFile(id);
  };

  const handleMouseLeave = () => {
    setHoveredFile(null);
  };

  const triggerRefMulti = () => {
    setOpenModal(!openModal);
  };

  async function onDrop(droppedFiles, rejectionFiles) {
    setErrors([]);

    if (rejectionFiles) {
      setErrors(rejectionFiles);
    }

    for (const file of droppedFiles) {
      const fileReader = new FileReader();

      fileReader.addEventListener("load", () => {
        setPreviewImage(fileReader?.result);
      });
      fileReader.readAsDataURL(file);

      if (uploadFile) {
        try {
          let target = { file, controllerName };
          await uploadFile.mutateAsync(target);
        } catch (error) {}
      }
    }
  }

  async function handleSelectImage(event, key) {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader?.result);
    });
    fileReader.readAsDataURL(file);
    if (uploadFile) {
      try {
        let target = { file: file, controllerName };
        await uploadFile.mutateAsync(target);
      } catch (error) {}
    }
  }

  const handleDeleteFile = async (id, onChange, value) => {
    try {
      setImages((prevImages) => prevImages.filter((img) => img.id !== id));
      setImageUrl((prevUrls) => prevUrls.filter((url) => url !== id));
      setUploadedUrls((prevUrls) => prevUrls.filter((url) => url !== id));

      onChange(value.filter((fileId) => fileId !== id));
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  useEffect(() => {
    const promises = imageUrl.map((data) =>
      getFileMutation.mutateAsync({ id: data })
    );

    Promise.all(promises)
      .then((results) => {
        setImages(results);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, [imageUrl]);

  const handleContainerClick = (event) => {
    if (
      !event.target.classList.contains("delete-button") &&
      !event.target.closest(".delete-button")
    ) {
      setOpenModal(true); // Open the modal
    }
  };

  const maxFileSize = humanizeFileSize(maxSize, 0);

  const getFileMutation = useMutation(async ({ id, key }) => {
    try {
      if (id != null) {
        const response = await getFile(id);
        let temp = {
          url: response.data.data.url,
          id: response.data.data.id,
          type: response.data.data.mimeType,
          name: response.data.data.name,
          size: (parseInt(response.data.data.size) / 1024 / 1024).toFixed(2),
        };
        return temp;
      }
    } catch (error) {
      throw error;
    }
  });

  const handleUploadMulti = (data) => {
    let url = data?.fileId?.id;
    if (controllerName !== prevControllerName) {
      setImageUrl([]);
      setUploadedUrls([]);
      setImageUrl((prevData) => [...prevData, url]);
      setUploadedUrls((prevData) => [...prevData, url]);
    } else {
      setImageUrl((prevData) => [...prevData, url]);
      setUploadedUrls((prevData) => [...prevData, url]);
    }
    setControllerTarget(controllerName);
    onModalSubmit(data);
  };

  const handleClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const dropzoneHandleError = (error) => {
    if (error.code === "file-invalid-type") {
      return `Format dokumen harus ${fileType}`;
    } else if (error.code === "file-too-large") {
      return `File maksimal berukuran ${humanizeFileSize(maxSize, 0)}`;
    }

    return "";
  };

  return (
    <Controller
      name={controllerName}
      control={control}
      defaultValue={""}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => {
        if (Array.isArray(value)) {
          setImageUrl(value);
        }
        if (value?.size) {
          setSize(value?.size);
        }
        return (
          <>
            {createPortal(
              <UploadDocModal
                open={openModal}
                onClose={triggerRefMulti}
                onSubmit={handleUploadMulti}
                isDaprog={isDaprog}
                setOpenModal={setOpenModal}
                className="space-y-2 mb-4"
              >
                <div className="text-lg font-[600]">
                  Anda yakin akan submit semua data ini?
                </div>
                <div className="text-sm font-[400]">
                  Setelah anda submit anda masih bisa mengubah data.
                </div>
              </UploadDocModal>,
              document.body
            )}
            <div className="form-control w-full">
              {label && (
                <label className="label font-semibold text-[14px] ">
                  <span className={`label-text`}>{label}</span>
                  {required && <span className="text-[#F04438]">*</span>}
                </label>
              )}
              {!isMulti ? (
                <>
                  {previewImage || value ? (
                    <>
                      {previewImage?.includes("image") ||
                      value?.type?.includes("image") ? (
                        <div
                          className={
                            "w-full rounded border border-gray-300 p-4 flex flex-row justify-between my-4"
                          }
                          onMouseEnter={() =>
                            handleMouseEnter(previewImage || value)
                          }
                          onMouseLeave={() =>
                            handleMouseLeave(previewImage || value)
                          }
                        >
                          <div className="flex ">
                            <div
                              className={
                                "bg-[#EEFFF4] p-1 rounded-full self-start"
                              }
                            >
                              <div className={"bg-[#D6FFE8] p-1 rounded-full"}>
                                <IoMdImage
                                  className={"text-[#00BD52] text-3xl"}
                                />
                              </div>
                            </div>
                            <div
                              className={
                                "flex flex-col text-left ml-5 overflow-hidden"
                              }
                            >
                              <span
                                className="inline-block w-full whitespace-nowrap overflow-hidden text-ellipsis align-middle"
                                title={value.name}
                              >
                                {value.name}
                              </span>
                              <span>
                                {(parseInt(size) / 1024).toFixed(2) ?? "0"} mb
                              </span>
                              <span
                                onClick={() =>
                                  handleClick(
                                    previewImage?.url
                                      ? previewImage?.url
                                      : value?.url &&
                                        typeof value?.url === "string"
                                      ? `${value?.url}`
                                      : previewImage?.url
                                  )
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "#00BD52",
                                }}
                              >
                                Lihat berkas
                              </span>
                            </div>
                          </div>
                          <div
                            className="cursor-pointer text-red-500 text-2xl content-center hover:text-red-700 active:text-red-900"
                            onClick={() => {
                              value = "";
                              setPreviewImage("");
                              setValue(controllerName, "");
                            }}
                          >
                            <RiDeleteBin6Line />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            className={
                              "w-full rounded border border-gray-300 p-4 flex justify-between flex-row my-4 "
                            }
                          >
                            <div className="flex">
                              <div
                                className={
                                  "bg-[#EEFFF4] p-1 rounded-full self-start"
                                }
                              >
                                <div
                                  className={"bg-[#D6FFE8] p-1 rounded-full"}
                                >
                                  <RiFile2Line
                                    className={"text-[#00BD52] text-3xl"}
                                  />
                                </div>
                              </div>
                              <div className={"flex flex-col text-left ml-5"}>
                                <span>{value?.name}</span>
                                <span>
                                  {humanizeFileSize(
                                    parseInt(value.size ?? 0),
                                    2
                                  )}
                                </span>
                                <span
                                  onClick={() =>
                                    handleClick(
                                      previewImage?.url
                                        ? previewImage?.url
                                        : value?.url &&
                                          typeof value?.url === "string"
                                        ? `${value?.url}`
                                        : previewImage?.url
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                    color: "#00BD52",
                                  }}
                                >
                                  Lihat berkas
                                </span>
                              </div>
                            </div>
                            <div
                              className="cursor-pointer text-red-500 text-2xl content-center hover:text-red-700 active:text-red-900"
                              onClick={() => {
                                value = "";
                                setPreviewImage("");
                                setValue(controllerName, "");
                              }}
                            >
                              <RiDeleteBin6Line />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <Button
                    type="button"
                    className="w-full h-fit p-0"
                    label={
                      buttonLabel || (
                        <div
                          className={`w-full bg-white hover:bg-gray-100 duration-200 text-center items-center border rounded-md ${
                            value || previewImage
                              ? "h-auto"
                              : "min-h-[8rem] flex justify-center"
                          }`}
                          {...getRootProps()}
                        >
                          <input
                            type="file"
                            className={"hidden"}
                            {...props}
                            onChange={(e) => {
                              console.log(1, { e });
                              handleSelectImage(e, "upload");
                              if (!isMulti) {
                                onChange(e?.target?.files[0]);
                              }
                            }}
                            {...getInputProps()}
                          />
                          <div
                            className={`flex flex-col items-center min-h-[8rem] h-full justify-center ${
                              value || previewImage ? "p-2" : "py-2"
                            }`}
                          >
                            <RiUploadCloud2Line />
                            {isDragActive ? (
                              <div className="flex flex-col items-center space-y-1 p-4">
                                Lepaskan untuk upload
                              </div>
                            ) : (
                              <div className="px-4">
                                <span className="font-semibold text-[#01A24A]">
                                  Klik untuk upload{" "}
                                </span>
                                <span className="font-normal text-[14px]">
                                  atau seret dan lepas kesini
                                </span>
                                <div className="font-normal text-[14px]">
                                  {fileType} up to {maxFileSize}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    }
                  />
                  {errors.length > 0 ? (
                    <small className="text-sm font-medium text-error-600 mt-4">
                      {dropzoneHandleError(errors[0].errors[0])}
                    </small>
                  ) : null}
                </>
              ) : isMulti && isDaprog ? (
                <div onClick={handleContainerClick}>
                  <input
                    type="file"
                    {...props}
                    ref={refInput}
                    className={"hidden"}
                    onChange={(e) => {
                      handleSelectImage(e, "upload");
                      if (!isMulti) {
                        onChange(e?.target?.files[0]);
                      }
                    }}
                    {...getInputProps()}
                  />
                  <Button
                    type="button"
                    className="w-full h-full p-0"
                    label={
                      buttonLabel || (
                        <div className="w-full bg-white hover:bg-gray-100 duration-200 text-center items-center border rounded-md min-h-[8rem] flex justify-center">
                          <div
                            className={`flex ${
                              images.length > 0 ? "flex-row" : "flex-col"
                            } py-2 items-center h-full justify-center`}
                          >
                            {images?.length > 0 ? (
                              images.map((data) => (
                                <>
                                  {data?.type?.includes("image") ||
                                  value?.type?.includes("image") ? (
                                    <>
                                      <div
                                        className={
                                          "rounded border border-gray-300 p-4 my-4 mx-1 flex flex-row delete-button justify-evenly w-fit h-[100%]"
                                        }
                                        onMouseEnter={() =>
                                          handleMouseEnter(data.id)
                                        }
                                        onMouseLeave={() =>
                                          handleMouseLeave(data.id)
                                        }
                                      >
                                        {" "}
                                        <div
                                          className={
                                            "bg-[#EEFFF4] p-1 rounded-full self-start"
                                          }
                                        >
                                          <div
                                            className={
                                              "bg-[#D6FFE8] p-1 rounded-full"
                                            }
                                          >
                                            <IoMdImage
                                              className={
                                                "text-[#00BD52] text-3xl"
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className={
                                            "flex flex-col text-left ml-5"
                                          }
                                        >
                                          <span>{data.name}</span>
                                          <span>
                                            {(
                                              parseInt(data.size) / 1024
                                            ).toFixed(2) ?? "0"}{" "}
                                            mb
                                          </span>
                                          <span
                                            onClick={() =>
                                              handleClick(
                                                data?.url
                                                  ? data?.url
                                                  : value?.url &&
                                                    typeof value?.url ===
                                                      "string"
                                                  ? `${value?.url}`
                                                  : data?.url
                                              )
                                            }
                                            style={{
                                              cursor: "pointer",
                                              color: "#00BD52",
                                            }}
                                          >
                                            Lihat berkas
                                          </span>
                                        </div>
                                        {hoveredFile === data.id && (
                                          <RiDeleteBin6Line
                                            className={
                                              " text-[#ff0000] text-3xl mx-4 self-center z-10"
                                            }
                                            onClick={() =>
                                              handleDeleteFile(
                                                data.id,
                                                onChange,
                                                value
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className={
                                          "rounded border border-gray-300 py-4 px-3 my-4 mx-1 flex delete-button flex-row justify-evenly h-[100%] w-fit"
                                        }
                                        onMouseEnter={() =>
                                          handleMouseEnter(data.id)
                                        }
                                        onMouseLeave={() =>
                                          handleMouseLeave(data.id)
                                        }
                                      >
                                        <div
                                          className={
                                            "bg-[#EEFFF4] p-1 rounded-full self-start"
                                          }
                                        >
                                          <div
                                            className={
                                              "bg-[#D6FFE8] p-1 rounded-full"
                                            }
                                          >
                                            <RiFile2Line
                                              className={
                                                "text-[#00BD52] text-3xl"
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className={`flex flex-col text-left p-2 ${
                                            hoveredFile === data.id &&
                                            "border-r border-gray-300"
                                          }`}
                                        >
                                          <span>{data.name}</span>
                                          <span>{data.size ?? "0"} mb</span>
                                          <span
                                            onClick={() =>
                                              handleClick(
                                                data?.url
                                                  ? data?.url
                                                  : value?.url &&
                                                    typeof value?.url ===
                                                      "string"
                                                  ? `${value?.url}`
                                                  : data?.url
                                              )
                                            }
                                            style={{
                                              cursor: "pointer",
                                              color: "#00BD52",
                                            }}
                                          >
                                            Lihat berkas
                                          </span>
                                        </div>
                                        {hoveredFile === data.id && (
                                          <RiDeleteBin6Line
                                            className={
                                              " text-[#ff0000] text-3xl mx-4 self-center z-10"
                                            }
                                            onClick={() =>
                                              handleDeleteFile(
                                                data.id,
                                                onChange,
                                                value
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              ))
                            ) : (
                              <>
                                <RiUploadCloud2Line />
                                <div className="px-4">
                                  <span className="font-semibold text-[#01A24A]">
                                    Klik untuk upload{" "}
                                  </span>
                                  <span className="font-normal text-[14px]">
                                    atau seret dan lepas kesini
                                  </span>
                                  <div className="font-normal text-[14px]">
                                    {fileType} up to {maxFileSize}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )
                    }
                  />
                  {invalid && (
                    <label className="label">
                      <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                        {error?.message}
                      </div>
                    </label>
                  )}
                </div>
              ) : (
                <div onClick={handleContainerClick}>
                  <input
                    type="file"
                    {...props}
                    ref={refInput}
                    onChange={(e) => {
                      console.log(3, { e });
                      handleSelectImage(e, "upload");
                      if (!isMulti) {
                        onChange(e?.target?.files[0]);
                      }
                    }}
                    // value={valueParams}
                    className={"hidden"}
                    {...getInputProps()}
                  />
                  <Button
                    type="button"
                    className="w-full h-fit p-0"
                    label={
                      buttonLabel || (
                        <div className="w-full bg-white hover:bg-gray-100 duration-200 text-center items-center border rounded-md min-h-[8rem] flex justify-center">
                          <div
                            className={`flex ${
                              images.length > 0 ? "flex-row" : "flex-col"
                            } py-2 items-center h-full justify-center`}
                          >
                            <>
                              {images?.length > 0 ? (
                                <>
                                  {images.map((data) => {
                                    return (
                                      <>
                                        {data?.type?.includes("image") ||
                                        value?.type?.includes("image") ? (
                                          <>
                                            <div
                                              className={
                                                "rounded border border-gray-300 p-4 my-4 mx-1 flex flex-row delete-button justify-evenly w-fit h-[100%]"
                                              }
                                              onMouseEnter={() =>
                                                handleMouseEnter(data.id)
                                              }
                                              onMouseLeave={() =>
                                                handleMouseLeave(data.id)
                                              }
                                            >
                                              {" "}
                                              <div
                                                className={
                                                  "bg-[#EEFFF4] p-1 rounded-full self-start"
                                                }
                                              >
                                                <div
                                                  className={
                                                    "bg-[#D6FFE8] p-1 rounded-full"
                                                  }
                                                >
                                                  <IoMdImage
                                                    className={
                                                      "text-[#00BD52] text-3xl"
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div
                                                className={
                                                  "flex flex-col text-left ml-5"
                                                }
                                              >
                                                <span>{data.name}</span>
                                                <span>
                                                  {(
                                                    parseInt(data.size) / 1024
                                                  ).toFixed(2) ?? "0"}{" "}
                                                  mb
                                                </span>
                                                <span
                                                  onClick={() =>
                                                    handleClick(
                                                      data?.url
                                                        ? data?.url
                                                        : value?.url &&
                                                          typeof value?.url ===
                                                            "string"
                                                        ? `${value?.url}`
                                                        : data?.url
                                                    )
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#00BD52",
                                                  }}
                                                >
                                                  Lihat berkas
                                                </span>
                                              </div>
                                              {hoveredFile === data.id && (
                                                <RiDeleteBin6Line
                                                  className={
                                                    " text-[#ff0000] text-3xl mx-4 self-center z-10"
                                                  }
                                                  onClick={() =>
                                                    handleDeleteFile(
                                                      data.id,
                                                      onChange,
                                                      value
                                                    )
                                                  }
                                                />
                                              )}
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div
                                              className={
                                                "rounded border border-gray-300 py-4 px-3 my-4 mx-1 flex delete-button flex-row justify-evenly h-[100%] w-fit"
                                              }
                                              onMouseEnter={() =>
                                                handleMouseEnter(data.id)
                                              }
                                              onMouseLeave={() =>
                                                handleMouseLeave(data.id)
                                              }
                                            >
                                              <div
                                                className={
                                                  "bg-[#EEFFF4] p-1 rounded-full self-start"
                                                }
                                              >
                                                <div
                                                  className={
                                                    "bg-[#D6FFE8] p-1 rounded-full"
                                                  }
                                                >
                                                  <RiFile2Line
                                                    className={
                                                      "text-[#00BD52] text-3xl"
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div
                                                className={`flex flex-col text-left p-2 ${
                                                  hoveredFile === data.id &&
                                                  "border-r border-gray-300"
                                                }`}
                                              >
                                                <span>{data.name}</span>
                                                <span>
                                                  {(
                                                    parseInt(data.size) / 1024
                                                  ).toFixed(2) ?? "0"}{" "}
                                                  mb
                                                </span>
                                                <span
                                                  onClick={() =>
                                                    handleClick(
                                                      data?.url
                                                        ? data?.url
                                                        : value?.url &&
                                                          typeof value?.url ===
                                                            "string"
                                                        ? `${value?.url}`
                                                        : data?.url
                                                    )
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#00BD52",
                                                  }}
                                                >
                                                  Lihat berkas
                                                </span>
                                              </div>
                                              {hoveredFile === data.id && (
                                                <RiDeleteBin6Line
                                                  className={
                                                    " text-[#ff0000] text-3xl mx-4 self-center z-10"
                                                  }
                                                  onClick={() =>
                                                    handleDeleteFile(
                                                      data.id,
                                                      onChange,
                                                      value
                                                    )
                                                  }
                                                />
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <RiUploadCloud2Line />
                                  <div className="px-4">
                                    <span className="font-semibold text-[#01A24A]">
                                      Klik untuk upload{" "}
                                    </span>
                                    <span className="font-normal text-[14px]">
                                      atau seret dan lepas kesini
                                    </span>
                                    <div className="font-normal text-[14px]">
                                      {fileType} up to {maxFileSize}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          </div>
                        </div>
                      )
                    }
                  />
                  {invalid && (
                    <label className="label">
                      <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                        {error?.message}
                      </div>
                    </label>
                  )}
                </div>
              )}
            </div>
          </>
        );
      }}
    />
  );
};

export default InputFormUpload;
