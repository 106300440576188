import AccordionButton from "components/atoms/AccordionButton";
import CardForm from "components/molecules/CardForm";
import SelectJenisUsaha from "components/organisms/inputs/SelectJenisUsaha";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useLocation } from "react-router";
import { FieldLabel } from "../validation-schema";
import { Button, InputForm } from "components";
import AtomSelect from "components/atoms/Select";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import InputCurrencyForm from "components/V2/shared/input-currency-form";

export const DataUsahaYangAkanDibiayai = () => {
  const location = useLocation();
  const isView = location.pathname.includes("view");

  const [accordionState, setAccordionState] = useState({
    curr_condition: true,
    prospect: true,
  });

  const toggleAccordion = (key) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState?.[key],
    });
  };

  const methods = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: "business_partner",
  });

  return (
    <CardForm label="Data Usaha yang Akan Dibiayai">
      <AnimatePresence>
        <AccordionButton
          key={"accordion-currcond-btn"}
          label={"Kondisi Usaha Saat Ini"}
          isOpen={accordionState?.curr_condition}
          onClick={toggleAccordion("curr_condition")}
        />

        {accordionState?.curr_condition && (
          <motion.div
            key={"accordion-currcond-content"}
            className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <div className="col-span-2">
              <SelectJenisUsaha
                controllerName={`business_type_id`}
                wrapperClass={"col-span-1"}
                label={FieldLabel.PROJECT_TYPE}
                returnObject={false}
                asText={isView}
              />
            </div>

            <div className="col-span-2">
              {/* <SelectKomoditasUsaha
                controllerName={`business_commodity`}
                wrapperClass={"col-span-1"}
                label={FieldLabel.PROJECT_COMMODITY}
                returnObject={false}
                asText={isView}
              /> */}
              <InputForm
                controllerName="business_commodity"
                className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                label={FieldLabel.PROJECT_COMMODITY}
                asText={isView}
              />
            </div>

            <div
              className={[
                "col-span-2 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0",
              ]}
            >
              <InputForm
                controllerName={`business_since`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.PROJECT_AGE}
                placeholder={FieldLabel.PROJECT_AGE}
                required
                asText={isView}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />

              <AtomSelect
                controllerName={`business_since_type`}
                className="basic-single mt-2 flex-1"
                wrapperClass={"col-span-1"}
                returnObject={false}
                label={<span>&nbsp;</span>}
                placeholder={FieldLabel.PROJECT_AGE}
                options={[
                  { value: "Bulan", label: "Bulan" },
                  { value: "Tahun", label: "Tahun" },
                ]}
                asText={isView}
              />
            </div>

            <div className="col-span-2">
              {/* <SelectSumberBahanProduksi
                controllerName={`business_material`}
                wrapperClass={"col-span-1"}
                label={FieldLabel.SOURCE}
                returnObject={false}
                asText={isView}
              /> */}
              <InputForm
                controllerName="business_material"
                className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                label={FieldLabel.SOURCE}
                asText={isView}
              />
            </div>

            <InputForm
              controllerName={`business_capacity`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              wrapperClass={"col-span-1"}
              label={FieldLabel.CAPACITY}
              placeholder={FieldLabel.CAPACITY}
              asText={isView}
            />

            {/* <InputForm
              controllerName={`business_economic_values`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              wrapperClass={"col-span-1"}
              label={FieldLabel.VALUE}
              asText={isView}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            /> */}
            <InputCurrencyForm
              name="business_economic_values"
              label={FieldLabel.PRICE_VALUE}
              required
              asText={isView}
            />

            <div className="flex flex-col col-span-2 gap-2">
              <label className="text-sm font-semibold">
                {FieldLabel.PARTNER}
              </label>
              {fields?.map((field, idx) => (
                <div className="flex gap-4 items-center">
                  <InputForm
                    controllerName={`business_partner.${idx}.value]`}
                    className="w-full rounded-md"
                    placeholder={FieldLabel.PARTNER}
                    asText={isView}
                  />
                  {idx > 0 && (
                    <Button
                      type="button"
                      onClick={() => remove(idx)}
                      size="md"
                      className="rounded-lg bg-error-600 flex-none"
                      label={<BiTrash className="text-white" />}
                    />
                  )}
                </div>
              ))}
            </div>

            {!isView && (
              <div className="col-span-2 flex justify-end">
                <Button
                  label="Tambah Mitra Usaha"
                  className="self-end w-max bg-white"
                  outline
                  type="button"
                  onClick={() => append({ id: undefined, value: "" })}
                />
              </div>
            )}

            <div className="col-span-2">
              <InputForm
                controllerName={`business_target`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                wrapperClass={"col-span-1"}
                label={FieldLabel.GOAL}
                placeholder={FieldLabel.GOAL}
                required
                asText={isView}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        <AccordionButton
          key={"accordion-prospect-btn"}
          label={"Prospek Usaha"}
          isOpen={accordionState?.prospect}
          onClick={toggleAccordion("prospect")}
        />

        {accordionState?.prospect && (
          <motion.div
            key={"accordion-prospect-content"}
            className="md:grid md:grid-cols-3 gap-4 space-y-4 md:space-y-0"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <InputForm
              controllerName={`business_panning_application`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              wrapperClass={"col-span-3"}
              label={FieldLabel.PLAN}
              placeholder={FieldLabel.PLAN}
              required
              asText={isView}
            />

            {/* <InputForm
              controllerName={`business_revenue`}
              className={`py-2 px-4 mt-2 border w-full rounded-md `}
              label={FieldLabel.EST_GROSS_EARN}
              required
              asText={isView}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            /> */}
            <InputCurrencyForm
              name="business_revenue"
              label={FieldLabel.EST_GROSS_EARN}
              required
              asText={isView}
            />

            {/* <InputForm
              controllerName={`business_cost_price`}
              className={`py-2 px-4 mt-2 border w-full rounded-md `}
              label={FieldLabel.EST_COST}
              required
              asText={isView}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            /> */}
            <InputCurrencyForm
              name="business_cost_price"
              label={FieldLabel.EST_COST}
              required
              asText={isView}
            />

            {/* <InputForm
              controllerName={`business_net_income`}
              className={`py-2 px-4 mt-2 border w-full rounded-md `}
              label={FieldLabel.EST_NET_EARN}
              required
              asText={isView}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            /> */}
            <InputCurrencyForm
              name="business_net_income"
              label={FieldLabel.EST_NET_EARN}
              required
              asText={isView}
            />

            {/* <div
              className={[
                "col-span-3 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0",
              ]}
            >
              <InputForm
                controllerName={`business_cycle`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.CYCLE}
                placeholder={FieldLabel.CYCLE}
                required
                asText={isView}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />

              <AtomSelect
                controllerName={`business_cycle_unit`}
                className="basic-single mt-2 flex-1"
                wrapperClass={"col-span-1"}
                returnObject={false}
                label={<span>&nbsp;</span>}
                placeholder={FieldLabel.CYCLE}
                options={[
                  { value: "minggu", label: "Mingguan" },
                  { value: "bulan", label: "Bulanan" },
                ]}
                asText={isView}
              />
            </div> */}
          </motion.div>
        )}
      </AnimatePresence>
    </CardForm>
  );
};
