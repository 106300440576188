import { useEffect, useState, Fragment } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  Controller,
} from "react-hook-form";
import { useNavigate } from "react-router";
import ReactSelectCreatable from "react-select/creatable";
import { useQuery, useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { BiArrowBack } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import { sortBy } from "lodash";
import { CgSpinner } from "react-icons/cg";
import moment from "moment";

import { getErrorMessage } from "helpers";
import { Button, InputForm } from "components";
import AtomDatePicker from "components/atoms/Datepicker";
import AtomSelect from "components/atoms/Select";
import CardForm from "components/molecules/CardForm";
import FileDetail from "components/molecules/FileDetail";
import InputFormDropzone from "components/molecules/InputFormDropzone";
import {
  getDetailSafeguards,
  getListActivityByAwpId,
  getListCallForProposalDeal,
} from "services/danaProgram/callForProposalService";
import { getDetailNoteConcept } from "services/danaProgram/noteConcept";
import CustomDropzoneForm from "pages/Report/Components/Dropzone";
import { fileUpload } from "services/danaProgram/fileService";
import { postResultActivityReport } from "services/danaProgram/callForProposalService";

const ResultActivity = ({ defaultValues, onNext = () => {} }) => {
  const ActivityReportsId = localStorage.getItem("ActivityReportsId") || null;
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let mode = searchParams.get("mode");

  const [proposalOption, setProposalOption] = useState([]);
  const [awpId, setAwpId] = useState("");
  const [mainActivityOpt, setMainActivityOpt] = useState([]);
  const [outputOpt, setOutputOpt] = useState([]);
  const [ispList, setIspList] = useState([]);
  const [selectedIsp, setSelectedIsp] = useState(0);
  const [isChangeIsp, setIsChangeIsp] = useState(true);
  const [fileDoc, setFileDoc] = useState([]);

  // forms
  const methods = useForm({
    defaultValues,
  });
  const { watch, setValue, getValues, control } = methods;
  const selectedProject = watch("proposal_name");
  const activityDate = watch("activity_date");

  // requests
  useQuery({
    queryKey: ["proposal-list"],
    queryFn: () => getListCallForProposalDeal(),
    onSuccess: (data) => {
      const projectList = data.data.data.map((project) => ({
        value: project.program.id,
        label: project.program.name,
        proposalId: project._id,
      }));

      setProposalOption(projectList);
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const { isFetching: isFetchingDetail } = useQuery({
    enabled: !!selectedProject,
    queryKey: ["detail-concept-note", selectedProject],
    queryFn: () => getDetailNoteConcept(selectedProject.value),
    onSuccess: (data) => {
      const { awp_id } = data.data.data;
      let purposesOpt = data.data.data.activity_goals;

      if (Array.isArray(data.data.data.activity_goals)) {
        purposesOpt = data.data.data.activity_goals.join("\n");
      }

      setAwpId(awp_id);
      setValue("purposes", purposesOpt);
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const { isFetching: isFetchingAWPDetail } = useQuery({
    enabled: !!awpId,
    queryKey: ["activity-by-awp-id", awpId],
    queryFn: () => getListActivityByAwpId({ awp_id: awpId }),
    onSuccess: (data) => {
      const activities = data.data.data.map((activity) => ({
        value: activity.name,
        label: activity.name,
      }));

      const outputs = data.data.data.reduce((unique, o) => {
        if (
          !unique.some((obj) => {
            return obj?.output?.name === o?.output?.name;
          })
        ) {
          unique.push(o);
        }

        return unique;
      }, []);

      const uniqueOutputs = outputs.map((output) => ({
        value: output?.output?._id,
        label: output?.output?.name,
      }));

      setMainActivityOpt(activities);
      setOutputOpt(uniqueOutputs);
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const { isFetching: isFetchingIspList } = useQuery({
    enabled: !!selectedProject,
    queryKey: ["detail-safeguard", selectedProject],
    queryFn: () =>
      getDetailSafeguards({ proposal_id: selectedProject.proposalId }),
    onSuccess: (data) => {
      const isp = data.data.data.risk_mitigation.map((item) => item);

      setIspList(isp);
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const postStep1 = useMutation({
    mutationKey: ["post-step-1-activity-report"],
    mutationFn: async (payload) => {
      const res = await postResultActivityReport(payload);
      return res.data.data;
    },
    onSuccess: (res) => {
      if (ActivityReportsId === null) {
        localStorage.setItem("ActivityReportsId", res?._id);
      }
      onNext({
        ...res,
      });
    },
  });

  const postFile = useMutation({
    mutationKey: ["post-file"],
    mutationFn: async (data) => {
      let safeguardFile = data?.safeguards?.map(async (item, index) => {
        const allActivityPromises = item.activity_list.map(async (activity) => {
          const filePromises = activity.document?.map(async (file) => {
            if (!file?.fileId) {
              const formData = new FormData();
              formData.append("file", file);
              const res = await fileUpload(formData);
              return {
                fileId: res.data.data.id,
                fileName: res.data.data.name,
                fileSize: res.data.data.size,
                mimeType: res.data.data.mimeType,
                path: res.data.data.key,
              };
            } else {
              return file;
            }
          });
          return Promise.all(filePromises);
        });
        return Promise.all(allActivityPromises);
      });
      let additionalDocs = data?.additionalDocs?.map(async (item, index) => {
        if (!item?.fileId) {
          const formData = new FormData();
          formData.append("file", item);
          const res = await fileUpload(formData);
          return {
            fileId: res.data.data.id,
            fileName: res.data.data.name,
            fileSize: res.data.data.size,
            mimeType: res.data.data.mimeType,
            path: res.data.data.key,
          };
        } else {
          return item;
        }
      });
      const safeguardResult = await Promise.all(safeguardFile);
      const additionalResult = await Promise.all(additionalDocs);
      return { safeguardResult, additionalResult };
    },
    onSuccess: async (res) => {
      setValue("activity_result.attachment", [...res.additionalResult]);
      res.safeguardResult.map((activity, indexSafeguard) => {
        activity?.map((file, indexActivity) => {
          setValue(
            `safeguards.${indexSafeguard}.activity_list.${indexActivity}.document`,
            file
          );
        });
      });

      let payload = {
        id: ActivityReportsId !== "undefined" ? ActivityReportsId : null,
        proposal_id: getValues("proposal_name.value"),
        proposal_name: getValues("proposal_name.label"),
        work_plan_id: "some-ids",
        activity_name: getValues("activity_name.value"),
        output: getValues("output.value"),
        activity_date: `${moment(activityDate[0]).format(
          "DD/MM/YYYY"
        )} - ${moment(activityDate[1]).format("DD/MM/YYYY")}`,
        activity_goal: getValues("purposes")[0].value,
        purposes: getValues("purposes"),
        partner: getValues("partner"),
        total_woman: getValues("total_woman"),
        total_man: getValues("total_man"),
        activity_result: getValues("activity_result.activity_result"),
        document: getValues("activity_result.attachment"),
        safeguard: getValues("safeguards"),
      };

      postStep1.mutate(payload);
    },
  });

  // methods
  const onSubmit = (payload) => {
    if (mode === "detail") {
      onNext();
    } else {
      postFile.mutate({
        safeguards: payload?.safeguards,
        additionalDocs: payload?.activity_result?.attachment,
      });
    }
  };

  // cycles
  useEffect(() => {
    if (ispList) {
      let sorted = sortBy(ispList, (el) => {
        return parseInt(el?.principle.split(" ")[1]);
      });

      setValue(
        "safeguards",
        sorted.map((item) => {
          return {
            principle_name: item?.desc,
            activity_list: [
              {
                activity: "",
                description: "",
                document: [],
              },
            ],
          };
        })
      );

      setTimeout(() => {
        setIsChangeIsp(false);
      }, 300);
    }
  }, [ispList]);

  return (
    <FormProvider {...methods}>
      {isFetchingDetail || isFetchingIspList || isFetchingAWPDetail ? (
        <div className="w-full h-full flex justify-center">
          <CgSpinner className="animate-spin text-primary-400" />
        </div>
      ) : (
        <div className="space-y-4 w-full overflow-hidden">
          <CardForm label="Pelaksanaan Kegiatan">
            <AtomSelect
              controllerName={`proposal_name`}
              label={"Nama Project"}
              className={`py-2 px-4 mt-2 border w-full rounded-md`}
              options={proposalOption}
            />
            <AtomSelect
              controllerName={`activity_name`}
              label={"Kegiatan Utama"}
              className={`py-2 px-4 mt-2 border w-full rounded-md`}
              options={mainActivityOpt}
              disabled={!!selectedProject}
            />
            <AtomSelect
              controllerName={`output`}
              label={"Output"}
              className={`py-2 px-4 mt-2 border w-full rounded-md`}
              options={outputOpt}
            />
            <AtomDatePicker
              controllerName={"activity_date"}
              label="Tanggal Kegiatan"
              isRangeDatePicker
            />
            <div className="font-semibold text-[14px]">
              <label className="flex gap-1">Tujuan Kegiatan</label>
              <Controller
                name="purposes"
                control={control}
                defaultValue={[]}
                render={({ field: { onChange, value } }) => {
                  return (
                    <InputForm
                      controllerName="purposes"
                      textArea
                      rows={3}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
              />
            </div>
            <div className="font-semibold text-[14px]">
              <label className="flex gap-1">
                Mitra/Pemangku yang dilibatkan
              </label>
              <Controller
                name="partner"
                control={control}
                defaultValue={[]}
                render={({ field: { onChange, value } }) => {
                  return (
                    <InputForm
                      controllerName="partner"
                      textArea
                      rows={3}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
              />
            </div>
            <div className="space-y-4 font-semibold text-[16px]">
              <label>Keterlibatan</label>
              <div className="flex space-x-4 w-1/2">
                <div className="">
                  <InputForm controllerName={`total_woman`} label="Perempuan" />
                </div>
                <div className="">
                  <InputForm controllerName={`total_man`} label="Laki-laki" />
                </div>
              </div>
            </div>
          </CardForm>
          <CardForm label="Hasil Kegiatan">
            <InputForm
              controllerName={`activity_result.activity_result`}
              label={"Hasil Kegiatan dan atau kemajuan"}
              textArea={true}
              rows={5}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            />
            <div className="space-y-2">
              <div className="text-[#1D2939] font-semibold text-sm">
                Upload Dokumen Bukti Pelaksanaan kegiatan
              </div>
              {fileDoc.length > 0 ? (
                fileDoc.map((item, key) => (
                  <FileDetail
                    key={"file-doc-" + key}
                    file={item}
                    onRemove={() => {
                      const filtered = fileDoc.filter(
                        (file) => file.fileId !== item.fileId
                      );

                      setFileDoc(filtered);
                    }}
                  />
                ))
              ) : (
                <InputFormDropzone
                  name="activity_result.attachment"
                  accept={{
                    "image/png": [],
                    "image/jpeg": [],
                    "application/pdf": [],
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                      [],
                  }}
                  maxFiles={10}
                  maxSize={1}
                  multiple={true}
                  informationText="PNG, JPG, PDF, Docx"
                />
              )}
            </div>
          </CardForm>
          <CardForm label={"Safeguard yang Diimplementasi"}>
            <div className="flex justify-start px-4 border ">
              <div className="flex gap-3 max-w-full overflow-x-scroll">
                {sortBy(ispList, (el) => {
                  return parseInt(el?.principle.split(" ")[1]);
                }).map((item, index) => (
                  <div
                    key={index}
                    className={`px-5 py-3 whitespace-nowrap rounded-md cursor-pointer ${
                      index === selectedIsp
                        ? "bg-primary-700 text-white font-semibold"
                        : "bg-slate-200"
                    }`}
                    onClick={() => {
                      setIsChangeIsp(true);
                      setSelectedIsp(index);
                      setTimeout(() => {
                        setIsChangeIsp(false);
                      }, 300);
                    }}
                  >
                    {item.principle}
                  </div>
                ))}
              </div>
            </div>
            <div>
              {isChangeIsp ? (
                <div className="w-full flex justify-center m-5">
                  <CgSpinner className="animate-spin text-primary-400" />
                </div>
              ) : (
                <>
                  <InputForm
                    controllerName={`safeguards.${selectedIsp}.principle_name`}
                    className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 w-full`}
                    label={""}
                    disabled
                    rows={5}
                  />
                  <SafeguardActivitySection
                    alias={`safeguards.${selectedIsp}`}
                    mode={mode}
                  />
                </>
              )}
            </div>
          </CardForm>
          <div className="flex justify-end space-x-4 sticky w-full bottom-4 py-5 px-10 bg-white border border-gray-200 rounded-3xl">
            <Button
              onClick={() => navigate(-1)}
              label={
                <div className="flex items-center gap-2">
                  <BiArrowBack />
                  <span>Kembali</span>
                </div>
              }
              size="md"
              className="bg-white"
            />
            <Button
              onClick={methods.handleSubmit(onSubmit)}
              label="Selanjutnya"
              size="md"
              className="bg-primary-600 hover:bg-primary-600/90 text-white"
            />
          </div>
        </div>
      )}
    </FormProvider>
  );
};

const SafeguardActivitySection = ({ alias, mode }) => {
  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: `${alias}.activity_list`,
    keyName: "id",
  });

  return (
    <Fragment>
      {fields.map((item, index) => (
        <div key={item.id} className="mt-3 flex flex-col gap-3">
          <div className="w-full p-3 bg-primary-600 font-semibold text-white flex justify-between items-center">
            <div>Kegiatan {index + 1}</div>
            {fields.length > 1 && mode !== "detail" && (
              <div
                className="p-2 bg-error-500 rounded-md cursor-pointer"
                onClick={() => {
                  remove(index);
                }}
              >
                <BiTrash className=" text-white" />
              </div>
            )}
          </div>
          <div className="w-full flex flex-col gap-2">
            <InputForm
              controllerName={`${alias}.activity_list.${index}.activity`}
              className={`w-full text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
              label={"Kegiatan"}
              placeholder={"-"}
              disabled={mode === "detail"}
            />
            <InputForm
              controllerName={`${alias}.activity_list.${index}.description`}
              className={`w-full text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
              label={"Deskripsi"}
              placeholder={"-"}
              textArea={true}
              rows={4}
              disabled={mode === "detail"}
            />
            <div className="w-full">
              <label className="label font-semibold text-[14px]">
                <span className={`label-text`}>Dokumen Pendukung</span>
              </label>
              {methods.watch(`${alias}.activity_list.${index}.document`)
                ?.length > 0 ? (
                <>
                  {methods
                    .getValues(`${alias}.activity_list.${index}.document`)
                    .map((item) => (
                      <FileDetail
                        file={item}
                        onRemove={() => {
                          methods.setValue(
                            `${alias}.activity_list.${index}.document`,
                            []
                          );
                        }}
                        hideDelete={mode === "detail"}
                      />
                    ))}
                </>
              ) : (
                <CustomDropzoneForm
                  name={`${alias}.activity_list.${index}.document`}
                  maxSize={1}
                />
              )}
            </div>
          </div>
        </div>
      ))}
      <div className="w-full flex justify-center mt-3">
        <Button
          className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          label={
            <div className="flex items-center gap-2">
              <AiOutlinePlus className="text-white" />
              <span>Tambah Kegiatan</span>
            </div>
          }
          onClick={() => {
            append({
              activity: "",
              description: "",
              document: [],
            });
          }}
        />
      </div>
    </Fragment>
  );
};

export default ResultActivity;
