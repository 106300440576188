import classNames from "classnames";
import { forwardRef } from "react";
import CurrencyInput from "react-currency-input-field";
import { useFormContext, Controller } from "react-hook-form";

const InputCurrencyForm = forwardRef(
  (
    {
      name,
      label,
      required = false,
      asText,
      defaultValue,
      onValueChange = (val) => { },
      ...rest
    },
    ref
  ) => {
    const methods = useFormContext();

    return (
      <Controller
        name={name}
        control={methods.control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => {
          return (
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-1">
                <label className="flex items-center gap-1 text-sm font-semibold">
                  {label}
                </label>
                {required && (
                  <span className="font-semibold text-error-500 text-base">
                    *
                  </span>
                )}
              </div>
              <CurrencyInput
                className={classNames(
                  "h-10 border border-gray-200 rounded-md outline-none px-4",
                  asText && "bg-transparent border-none !p-0",
                  fieldState.error && "!border-error-500"
                )}
                prefix={asText ? "Rp " : rest.prefix}
                value={field?.value}
                onValueChange={(value, name, values) => {
                  if (value) {
                    field.onChange(value);
                    onValueChange(value);
                  } else {
                    field.onChange(null); // Clear the value if input is null
                    onValueChange(null); // Notify parent of null value
                  }
                }}
                disabled={asText}
                {...rest}
                ref={ref}
              />
              {fieldState.invalid && fieldState.error && (
                <small className="text-xs text-error-500">
                  {fieldState.error.message}
                </small>
              )}
            </div>
          );
        }}
      />
    );
  }
);

export default InputCurrencyForm;
