import { yupResolver } from "@hookform/resolvers/yup";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormUpload,
} from "components";
import InputCurrencyForm from "components/V2/shared/input-currency-form";
import AtomDatePicker from "components/atoms/Datepicker";
import FileInfoField from "components/atoms/FileInfoField";
import CardForm from "components/molecules/CardForm";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import {
  debtorLembagaValidator,
  debtorNonPeroranganValidator,
} from "./ValidationSchema/debitur.scheme";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getAgencyRequestById,
  patchAgencyRequestDebtor,
} from "services/fdb/lembagaPenyalur";
import { useSearchParams } from "react-router-dom";
import AsyncDropzoneFieldForm from "components/molecules/async-dropzone-field-form";
import { getFile } from "services/danaProgram/fileService";
import moment from "moment";
import { humanizeFileSize } from "helpers";

const DebturAccessForm = ({ prefix, onSubmit, onBack }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const request_id = urlParams.get("request");
  const url = location.pathname.split("/");

  const typeLembaga = url[3] ?? "bank-ventura";
  const group = url[2] ?? "lembaga-penyalur";
  const is_view = url[4] === "view" ? true : false;

  const methods = useForm({
    resolver: yupResolver(
      group === "non-perorangan"
        ? debtorNonPeroranganValidator
        : debtorLembagaValidator
    ),
  });

  const { control } = methods;

  const [urlFile, setUrlFile] = useState("");

  const { data: dataDebtor } = useQuery({
    queryKey: ["request-profile-by-id"],
    queryFn: () => (request_id ? getAgencyRequestById(request_id) : null),
    select: (data) => data?.data?.data?.debtor_access,
  });

  const mutateAgencyRequsetDebtor = useMutation(
    ["PATCH AGENCY REQUEST DEBTOR"],
    ({ data, params }) => {
      return patchAgencyRequestDebtor(data, params);
    }
  );

  const getDocsMutation = useMutation({
    mutationKey: ["get-detail-information-docs"],
    mutationFn: async ({ payload }) => {
      const res = await getFile(payload);
      return { res: res.data.data };
    },
    onSuccess: ({ res }) => {
      setUrlFile(res.url);
    },
  });

  const openDocsGrowFile = async () => {
    window.open(perkembaganAnggotaFile.url, "_blank");
  };

  const openDocsFile = async () => {
    window.open(accessFile.url, "_blank");
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    fields: fieldsSectors,
    append: appendSectors,
    remove: removeSectors,
  } = useFieldArray({
    control,
    name: "sectors",
  });

  const {
    fields: fieldsProducts,
    append: appendProducts,
    remove: removeProducts,
  } = useFieldArray({
    control,
    name: "products",
  });

  useEffect(() => {
    if (dataDebtor) {
      methods.reset({
        debtor_development_file: dataDebtor?.debtor_development_file ?? null,
        branch_file: dataDebtor?.branch_file ?? null,
        products: dataDebtor?.agency_debtor_access_product
          ? dataDebtor.agency_debtor_access_product.map((dataProduct) => {
            return {
              type: dataProduct?.type,
              name: dataProduct?.name,
              nominal_low: dataProduct?.nominal_low,
              nominal_high: dataProduct?.nominal_high,
              average_plafond: dataProduct?.average_plafond,
              average_tenor: dataProduct?.average_tenor,
              margin: dataProduct?.margin,
              income: dataProduct?.income,
              notes: dataProduct?.notes,
            };
          })
          : [
            {
              type: "",
              name: "",
              nominal_low: 0,
              nominal_high: 0,
              average_palfond: 0,
              average_tenor: 0,
              margin: 0,
              notes: "",
            },
          ],
        sectors: dataDebtor?.agency_debtor_access_sector
          ? dataDebtor.agency_debtor_access_sector.map((dataSector) => {
            return {
              sector: dataSector?.sector,
              total_member: dataSector?.total_member,
              nominal: dataSector?.nominal,
              year: dataSector?.year ? new Date(dataSector.year) : null,
            };
          })
          : [
            {
              sector: "",
              total_member: 0,
              nominal: 0,
              year: undefined,
            },
          ],
      });
    } else {
      methods.reset({
        products: dataDebtor?.agency_debtor_access_product
          ? dataDebtor.agency_debtor_access_product.map((dataProduct) => {
            return {
              type: dataProduct?.type,
              name: dataProduct?.name,
              nominal_low: dataProduct?.nominal_low,
              nominal_high: dataProduct?.nominal_high,
              average_plafond: dataProduct?.average_plafond,
              average_tenor: dataProduct?.average_tenor,
              margin: dataProduct?.margin,
              income: dataProduct?.income,
              notes: dataProduct?.notes,
            };
          })
          : [
            {
              type: "",
              name: "",
              nominal_low: 0,
              nominal_high: 0,
              average_palfond: 0,
              average_tenor: 0,
              margin: 0,
              notes: "",
            },
          ],
        sectors: dataDebtor?.agency_debtor_access_sector
          ? dataDebtor.agency_debtor_access_sector.map((dataSector) => {
            return {
              sector: dataSector?.sector,
              total_member: dataSector?.total_member,
              nominal: dataSector?.nominal,
              year: dataSector?.year ? new Date(dataSector.year) : null,
            };
          })
          : [
            {
              sector: "",
              total_member: 0,
              nominal: 0,
              year: undefined,
            },
          ],
      });
    }
  }, [methods, dataDebtor]);

  const onFormSubmit = () => {
    if (is_view) {
      setSearchParams(`step=4&request=${request_id}`);
      return;
    }

    methods.handleSubmit((data) => {
      const mapData = {
        ...data,
        products:
          data.products.length > 0
            ? data?.products?.map((el) => ({
              ...el,
              margin: parseFloat(el?.margin),
            }))
            : [],
      };
      mutateAgencyRequsetDebtor.mutate(
        {
          data: mapData,
          params: {
            request_id: request_id ?? null,
            is_draft: false,
            agency_type:
              group === "non-perorangan"
                ? "Non Perorangan"
                : "Lembaga Penyalur",
          },
        },
        {
          onSuccess: () => setSearchParams(`step=4&request=${request_id}`),
        }
      );
    })();
  };

  const onFormDraft = () => {
    const data = methods.getValues();

    const mapData = {
      ...data,
      products:
        data.products.length > 0
          ? data?.products?.map((el) => ({
            ...el,
            margin: parseFloat(el?.margin),
          }))
          : [],
    };

    mutateAgencyRequsetDebtor.mutate(
      {
        data: mapData,
        params: {
          request_id: request_id ?? null,
          is_draft: true,
          agency_type:
            group === "non-perorangan" ? "Non Perorangan" : "Lembaga Penyalur",
        },
      },
      {
        onSuccess: () =>
          navigate(`/permohonan-pembiayaan/${group}/${typeLembaga}`),
      }
    );
  };

  const handleBack = () => {
    navigate(-1);
  };

  const [perkembaganAnggotaFile, setPerkemabganAnggotaFile] = useState();
  const [accessFile, setAccessFile] = useState();

  const handleGetFile = useCallback(async () => {
    const resPerkembaganAnggotaFile = await getFile(
      "80a56cfc-9f2b-4abb-a925-38b1c1f926ba"
    );
    const resAccessFile = await getFile("05680ee5-86c5-4428-a9aa-672ee30ca595");

    setPerkemabganAnggotaFile(resPerkembaganAnggotaFile?.data?.data);
    setAccessFile(resAccessFile?.data?.data);
  }, []);

  useEffect(() => {
    handleGetFile();
  }, [handleGetFile]);

  return (
    <FormProvider {...methods}>
      <div className="space-y-10 gap-4">
        <CardForm label="Perkembangan Anggota/Debitur">
          <div className="space-y-6">
            <FileInfoField
              title={"Format Perkembangan Anggota"}
              desc={humanizeFileSize(perkembaganAnggotaFile?.size)}
              showDownload
              showIcon
              onDownloadClick={() => openDocsGrowFile()}
            />
            <AsyncDropzoneFieldForm
              maxFiles={1}
              informationText="PNG, JPG, PDF, DOCX"
              name={`debtor_development_file`}
              label={"Perkembangan 3 Tahun Terakhir"}
              maxSize={10}
              isView={is_view}
              required
            />
          </div>
        </CardForm>

        <CardForm label="Jaringan Cabang">
          <div className="space-y-6">
            <div className="space-y-1 mb-5">
              <label htmlFor="" className="font-semibold">
                File
              </label>
              <FileInfoField
                title={"Format Jaringan Cabang FDB"}
                desc={humanizeFileSize(perkembaganAnggotaFile?.size)}
                showDownload
                showIcon
                onDownloadClick={() => openDocsFile()}
              />
            </div>
            <AsyncDropzoneFieldForm
              maxFiles={1}
              informationText="PNG, JPG, PDF, DOCX"
              name={`branch_file`}
              label={"Jaringan Cabang"}
              maxSize={10}
              isView={is_view}
              required
            />
          </div>
        </CardForm>

        <CardForm label="Produk/Layanan Ditawarkan Pada Debitur">
          <div className="space-y-6">
            {fieldsProducts &&
              fieldsProducts.map((field, index) => (
                <>
                  {group === "non-perorangan" ? (
                    <>
                      <div
                        className="grid grid-cols-1 md:grid-cols-3 gap-4"
                        key={field.id}
                      >
                        <InputForm
                          label={"Jenis Produk"}
                          controllerName={`products.${index}.type`}
                          required
                          placeholder="Pembiayaan/Simpanan Lainnya"
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          asText={is_view}
                        />
                        <InputForm
                          label={"Nama Produk"}
                          controllerName={`products.${index}.name`}
                          required
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          asText={is_view}
                        />
                        <InputCurrencyForm
                          label="Pendapatan"
                          required
                          prefix="Rp "
                          placeholder="Rp"
                          name={`products.${index}.income`}
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          asText={is_view}
                        />
                      </div>
                      <div className="space-y-6">
                        <InputForm
                          label={"Keterangan"}
                          controllerName={`products.${index}.notes`}
                          required
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          asText={is_view}
                        />
                      </div>
                      {index > 0 && !is_view && (
                        <div className="flex justify-end pt-3">
                          <Button
                            label={
                              <>
                                <RiDeleteBin6Line size={20} />
                                Hapus
                              </>
                            }
                            theme="error"
                            size="sm"
                            type="button"
                            className="text-white h-10"
                            onClick={() => removeProducts(field.id)}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className="grid grid-cols-1 md:grid-cols-4 gap-4"
                        key={field.id}
                      >
                        <InputForm
                          label={"Jenis Produk"}
                          controllerName={`products.${index}.type`}
                          required
                          placeholder="Pembiayaan/Simpanan Lainnya"
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          asText={is_view}
                        />
                        <InputForm
                          label={"Nama Produk"}
                          controllerName={`products.${index}.name`}
                          required
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          asText={is_view}
                        />
                        <InputCurrencyForm
                          label="Nominal Terendah"
                          required
                          prefix="Rp "
                          placeholder="Rp"
                          name={`products.${index}.nominal_low`}
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          asText={is_view}
                        />
                        <InputCurrencyForm
                          label="Nominal Tertinggi"
                          required
                          prefix="Rp "
                          placeholder="Rp"
                          name={`products.${index}.nominal_high`}
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          asText={is_view}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 ">
                        {/* <InputForm
                          label={"Rata-Rata Plafond"}
                          controllerName={`products.${index}.average_plafond`}
                          required
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          asText={is_view}
                        /> */}
                        <InputCurrencyForm
                          label="Rata-Rata Plafond/Simpanan"
                          required
                          prefix="Rp "
                          placeholder="Rp"
                          name={`products.${index}.average_plafond`}
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          asText={is_view}
                        />
                        <InputForm
                          label={"Rata-Rata Tenor (Bulan)"}
                          controllerName={`products.${index}.average_tenor`}
                          required
                          wrapperClass="h-10 gap-[3px]"
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          asText={is_view}
                        />
                        <div className="flex flex-col gap-2">
                          <InputForm
                            label="Suku Bunga Marjin"
                            controllerName={`products.${index}.margin`}
                            required
                            subfix="%"
                            className={
                              "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                            }
                            // disabled
                            asText={is_view}
                          />
                          <p className="text-xs text-gray-400">
                            Koma menggunakan titik.
                          </p>
                        </div>
                      </div>
                      <div className="space-y-6">
                        <InputForm
                          label={"Keterangan"}
                          controllerName={`products.${index}.notes`}
                          required
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          asText={is_view}
                        />
                      </div>
                      {index > 0 && !is_view && (
                        <div className="flex justify-end pt-3">
                          <Button
                            label={
                              <>
                                <RiDeleteBin6Line size={20} />
                                Hapus
                              </>
                            }
                            theme="error"
                            size="sm"
                            type="button"
                            className="text-white h-10"
                            onClick={() => removeProducts(field.id)}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              ))}

            <div className="border-b" />
            <div className="flex flex-row-reverse my-5 mx-4 ">
              {!is_view && (
                <Button
                  className="p-4 border rounded-lg"
                  label={
                    <div className="flex items-center gap-2 font-[14px]">
                      <RiAddLine />
                      Tambah Data Lain
                    </div>
                  }
                  onClick={() => {
                    appendProducts({
                      type: "",
                      name: "",
                      nominal_low: undefined,
                      nominal_high: undefined,
                      average_palfond: undefined,
                      average_tenor: undefined,
                      margin: undefined,
                      notes: "",
                    });
                  }}
                />
              )}
            </div>
          </div>
        </CardForm>

        <CardForm label="Sektor Usaha Dibiayai">
          <div className="space-y-6">
            {fieldsSectors.map((field, index) => {
              return (
                <>
                  <div
                    key={field.id}
                    className="grid grid-cols-1 md:grid-cols-4 gap-4"
                  >
                    <InputForm
                      label={"Sektor"}
                      controllerName={`sectors.${index}.sector`}
                      required
                      placeholder="Pembiayaan/Simpanan Lainnya"
                      className={
                        "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                      }
                      asText={is_view}
                    />
                    <InputForm
                      label={"Jumlah Anggota"}
                      controllerName={`sectors.${index}.total_member`}
                      required
                      className={
                        "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                      }
                      asText={is_view}
                    />
                    <InputCurrencyForm
                      label="Nominal O/S"
                      required
                      prefix="Rp "
                      placeholder="Rp"
                      name={`sectors.${index}.nominal`}
                      className={
                        "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                      }
                      asText={is_view}
                    />
                    <AtomDatePicker
                      // customPicker={true}
                      // setStartDate={new Date()}
                      label="Per 31 Desember Tahun"
                      required
                      controllerName={`sectors.${index}.year`}
                      customInputClassname={`py-2 px-4 border w-full rounded-md flex-1`}
                      asText={is_view}
                      dateFormat="yyyy"
                      datepickerOptions={{
                        dateFormat: "yyyy",
                        showYearPicker: true,
                      }}
                    />
                  </div>
                  {index > 0 && !is_view && (
                    <div className="flex justify-end pt-3">
                      <Button
                        label={
                          <>
                            <RiDeleteBin6Line size={20} />
                            Hapus
                          </>
                        }
                        theme="error"
                        size="sm"
                        type="button"
                        className="text-white h-10"
                        onClick={() => removeSectors(field.id)}
                      />
                    </div>
                  )}
                </>
              );
            })}
            <div className="border-b" />
            <div className="flex flex-row-reverse my-5 mx-4 ">
              {!is_view && (
                <Button
                  className="p-4 border rounded-lg"
                  label={
                    <div className="flex items-center gap-2 font-[14px]">
                      <RiAddLine />
                      Tambah Data Lain
                    </div>
                  }
                  onClick={() => {
                    appendSectors({
                      sector: "",
                      total_member: 0,
                      nominal: 0,
                      year: new Date(),
                    });
                  }}
                />
              )}
            </div>
          </div>
        </CardForm>
      </div>
      <BottomFormAction
        // lastStep={true}
        backButtonProps={{
          type: "button",
        }}
        draftButtonProps={{
          type: "button",
        }}
        submitButtonProps={{
          type: "submit",
        }}
        submitActionButton={onFormSubmit}
        backButtonAction={handleBack}
        disableDrafButton={is_view ? true : false}
        drafButtonAction={onFormDraft}
      />
    </FormProvider>
  );
};

export default DebturAccessForm;
