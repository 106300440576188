import { HeaderLabel, Spinner } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import Stepper from "pages/DanaProgram/CallForProposal/components/Stepper";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import GeneralInformationInsentif from "./GeneralInformation";
import ProgramImplementation from "./ProgramImplementation";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { array, boolean, date, mixed, number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { getDirectSubmissionDetail } from "services/danaProgram/directSubmissionService";

const stepperItems = [
  {
    label: "Informasi Umum",
    id: "general_information_insentif",
    component: <GeneralInformationInsentif />,
  },
  {
    label: "Pelaksanaan Kegiatan / Program",
    id: "program_implementation",
    component: <ProgramImplementation />,
  },
];

const Insentif = () => {
  let [searchParams] = useSearchParams();

  const [currentStep, setCurrentStep] = useState(0);
  const [payload, setPayload] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading } = useQuery({
    enabled: !!searchParams.get("id"),
    queryKey: ["get-detail-direct-submission"],
    queryFn: async () => {
      const res = await getDirectSubmissionDetail(searchParams.get("id"));

      return res.data.data;
    },
  });

  let validationSchema = object({
    isStep2: boolean(),
    project: object().when("isStep2", {
      is: false,
      then: () =>
        object()
          .shape({
            label: string(),
            value: string(),
          })
          .required("Project wajib diisi"),
    }),
    program_name: string().when("isStep2", {
      is: false,
      then: () => string().max(255).required("Judul Program wajib diisi"),
    }),
    desc: string().when("isStep2", {
      is: false,
      then: () => string().max(255).required("Deskripsi Program wajib diisi"),
    }),
    sector: object().when("isStep2", {
      is: false,
      then: () =>
        object()
          .shape({
            label: string(),
            value: string(),
          })
          .required("Sektor wajib diisi"),
    }),
    periode: array().when("isStep2", {
      is: false,
      then: () => array().min(2).required("Periode wajib diisi"),
    }),
    program_goal: string().when("isStep2", {
      is: false,
      then: () => string().max(255).required("Tujuan Program wajib diisi"),
    }),
    scope: string().when("isStep2", {
      is: false,
      then: () => string().max(255).required("Ruang Lingkup wajib diisi"),
    }),
    involvement: object().when("isStep2", {
      is: false,
      then: () =>
        object().shape({
          total_man: number()
            .min(0)
            .required("Jumlah Laki-Laki wajib diisi")
            .label("Jumlah Laki-Laki")
            .typeError("Jumlah Laki-Laki wajib diisi"),
          total_woman: number()
            .min(0)
            .required("Jumlah Perempuan wajib diisi")
            .label("Jumlah Perempuan")
            .typeError("Jumlah Perempuan wajib diisi"),
        }),
    }),
    tor: array().when("isStep2", {
      is: false,
      then: () =>
        array().of(
          object().shape({
            output: object()
              .shape({
                label: string().required("Output wajib diisi"),
                value: string().required("Output wajib diisi"),
              })
              .required("Output wajib diisi"),
          })
        ),
    }),
    location_address: array().when("isStep2", {
      is: false,
      then: () =>
        array().of(
          object().shape({
            province: object()
              .shape({
                label: string().required("Provinsi wajib diisi"),
                value: string().required("Provinsi wajib diisi"),
              })
              .required("Provinsi wajib diisi"),
            city: object()
              .shape({
                label: string().required("Kota/Kab wajib diisi"),
                value: string().required("Kota/Kab wajib diisi"),
              })
              .required("Kota/Kab wajib diisi"),
          })
        ),
    }),
    proposal: mixed().when("isStep2", {
      is: false,
      then: () => array().min(1, "Proposal wajib diisi"),
    }),
    proposal_id: mixed().when("isStep2", {
      is: true,
      then: () => string().required("Proposal Id wajib diisi"),
    }),
    doc: mixed().when("isStep2", {
      is: false,
      then: () => array().min(1, "Dokumen pendukung wajib diisi"),
    }),
    activity_summary: string().when("isStep2", {
      is: true,
      then: () =>
        string()
          .required("Ringkasan Kegiatan wajib diisi")
          .label("Ringkasan Kegiatan"),
    }),
    activity: array().when("isStep2", {
      is: true,
      then: () =>
        array().of(
          object().shape({
            main_activity: object()
              .shape({
                label: string().required("Kegiatan Utama wajib diisi"),
                value: string().required("Kegiatan Utama wajib diisi"),
              })
              .label("Kegiatan Utama"),
            activity_date: mixed()
              .required("Tanggal Kegiatan wajib diisi")
              .label("Tanggal Kegiatan"),
            obstacle: string().notRequired(),
            solution: string().notRequired(),
            detail_activity: array().of(
              object().shape({
                name: object()
                  .shape({
                    label: string().required("Detail Kegiatan wajib diisi"),
                    value: string().required("Detail Kegiatan wajib diisi"),
                  })
                  .label("Detail Kegiatan"),
                budget: number().required("Biaya wajib diisi"),
              })
            ),
          })
        ),
    }),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      project: null,
      program_name: "",
      desc: "",
      program_goal: "",
      scope: "",
      involvement: {
        total_man: 0,
        total_woman: 0,
      },
      sector: null,
      periode: null,
      tor: [
        {
          output: null,
        },
      ],
      location_address: [
        {
          province: null,
          city: null,
        },
      ],
      activity_summary: "",
      isStep2: false,
      activity: [
        {
          main_activity: null,
          activity_date: "",
          obstacle: "",
          solution: "",
          detail_activity: [
            {
              name: null,
              budget: 0,
            },
          ],
          total_budget: 0,
        },
      ],
      proposal: [],
      doc: [],
    },
  });

  useEffect(() => {
    if (data) {
      let activityArr = data?.activity?.map((item) => ({
        ...item,
        activity_date: new Date(item?.activity_date),
      }));
      methods.reset({
        project: data?.project,
        program_name: data?.program_name,
        desc: data?.desc,
        sector: data?.sector,
        periode: [new Date(data?.periode[0]), new Date(data?.periode[1])],
        program_goal: data?.program_goal,
        scope: data?.scope,
        involvement: data?.involvement,
        tor: data?.tor,
        location_address: data?.location_address,
        initProposal: data?.proposal,
        initDoc: data?.doc,
        activity_summary: data?.activity_summary,
        activity: activityArr,
        note: data?.note,
        activity_result: data?.activity_result,
        initActivityDoc: data?.activity_doc,
      });
    }
  }, [data]);

  const onNext = (type, values) => {
    setCurrentStep((curr) => curr + 1);
    setPayload((curr) => ({
      ...curr,
      [type]: {
        ...curr[type],
        ...values,
      },
    }));
  };

  const onBack = () => {
    if (currentStep >= 1) setCurrentStep((curr) => curr - 1);
    else navigate(-1);
  };

  return (
    <Fragment>
      <div className="space-y-6">
        <div className="bg-white rounded-xl p-10 space-y-8">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: "Insentif",
                  path: location?.pathname?.includes("/beneficiaries/insentif")
                    ? "/beneficiaries/insentif"
                    : "/request-insentif",
                },
                {
                  label: "Buat Pengajuan Baru",
                  path: location?.pathname?.includes("/beneficiaries/insentif")
                    ? "/beneficiaries/insentif/form"
                    : "/request-insentif/form",
                },
              ]}
            />
          </div>
          <div className="w-full mt-6 mb-6 flex justify-center items-center">
            <Stepper currentStep={currentStep} items={stepperItems} />
          </div>
          <FormProvider {...methods}>
            {isLoading && !!searchParams.get("id") ? (
              <div className="w-full flex justify-center items-center">
                <Spinner />
              </div>
            ) : (
              <>
                {React.cloneElement(stepperItems[currentStep].component, {
                  type: stepperItems[currentStep].id,
                  onNext,
                  onBack,
                  payload,
                })}
              </>
            )}
          </FormProvider>
        </div>
      </div>
    </Fragment>
  );
};

export default Insentif;
