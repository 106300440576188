import React, { Fragment, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";

import {
  BottomFormAction,
  Button,
  ConfirmationModal,
  HeaderLabel,
  InputForm,
  Spinner,
} from "components";
import AlertBlankData from "components/molecules/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import AtomSelect from "components/atoms/Select";
import { RiAddLine, RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import WarningAlert from "../components/WarningAlert";
import { saveIdProposalPpd, updateFormCfP } from "app/actions";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getDetailNoteConcept,
  getListNoteConcept,
} from "services/danaProgram/noteConcept";
import AtomDatePicker from "components/atoms/Datepicker";
import dayjs from "dayjs";
import { useAuth } from "hooks/auth";
import { useSelector } from "react-redux";
import {
  getGeneralInformation,
  postGeneralInformation,
} from "services/danaProgram/callForProposalService";
import { enqueueSnackbar } from "notistack";

const validationSchema = yup.object({
  project: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    // .test(
    //   "required",
    //   "Proyek yang akan diajukan wajib diisi",
    //   (value) => value && !!value?.value && !!value?.label
    // )
    .nullable(),
  thematic: yup.array().of(
    yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .test(
        "required",
        "Thematic Programmes wajib diisi",
        (value) => value && !!value?.value && !!value?.label
      )
      .nullable()
  ),
  proposal_title: yup.string().required("Judul Proposal wajib diisi"),
  proposal_description: yup.string().required("Deskripsi Proposal wajib diisi"),
  // duration: yup.mixed().required("Durasi Pelaksanaan wajib diisi"),
  activities: yup.array().of(
    yup.object().shape({
      value: yup.string(),
    })
  ),
  goals: yup.array().of(
    yup.object().shape({
      value: yup.string(),
    })
  ),
  strategies: yup.array().of(
    yup.object().shape({
      value: yup.string().required("Strategi wajib diisi"),
    })
  ),
  applicant_information: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Nama Pengusul wajib diisi"),
      nip: yup.string().required("NIDN/NIP Pengusul wajib diisi"),
      description: yup.string().required("Deskripsi wajib diisi"),
    })
  ),
});

const defaultValues = {
  project: null,
  thematic: [],
  proposal_title: "",
  proposal_description: "",
  duration: null,
  activities: [
    {
      value: "",
    },
  ],
  goals: [
    {
      value: "",
    },
  ],
  strategies: [
    {
      value: "",
    },
  ],
  applicant_information: [
    {
      name: "",
      nip: "",
      description: "",
    },
  ],
};

const GeneralInformation = () => {
  const navigate = useNavigate();
  const userId = useSelector((state) => state.auth.user.UserId);
  const { id } = useSelector((state) => state?.callForProposalData?.data);
  const [page, setPage] = useState(1);
  const [showConfirmModal, setConfirmModal] = useState({
    open: false,
    type: "draft",
    cancelLabel: "Tidak",
    confirmLabel: "Iya, simpan draft",
    title: "",
    description: "",
  });
  const [payload, setPayload] = useState(null);
  const dispatch = useDispatch();
  const [projectOptions, setProjectOptions] = useState([]);
  const [thematic, setThematic] = useState([]);
  const [outcome, setOutcome] = useState([]);
  const [output, setOutput] = useState([]);
  const [lastPage, setLastPage] = useState(0);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { control, setValue, reset } = methods;

  const project = useWatch({
    control,
    name: "project",
  });

  const { fields: activityFields } = useFieldArray({
    control,
    name: "activities",
  });

  const { fields: goalFields } = useFieldArray({
    control,
    name: "goals",
  });

  const {
    fields: strategyFields,
    append: appendStrategy,
    remove: removeStrategy,
  } = useFieldArray({
    control,
    name: "strategies",
  });

  const { fields: applicantFields } = useFieldArray({
    control,
    name: "applicant_information",
  });

  const { data, isLoading } = useQuery({
    queryKey: ["application-get-concept-notes-list", page],
    queryFn: async () => {
      const res = await getListNoteConcept({
        page,
        limit: 10,
        status: "APPROVED",
      });
      return res.data.data;
    },
    onSuccess: (res) => {
      const arr = res.data.map((item) => {
        return {
          label: item.project_name + " | " + item.proposal_title,
          value: item._id,
        };
      });
      setLastPage(res.lastPage);
      setProjectOptions((state) => [...state, ...arr]);
    },
  });

  const { data: detail, isLoading: detailLoading } = useQuery({
    queryKey: ["application-get-concept-notes-detail", project],
    queryFn: async () => {
      const res = await getDetailNoteConcept(project.value);
      return res.data.data;
    },
  });

  useEffect(() => {
    if (!!detail) {
      const thematicArr = detail.thematic_programmes.map((item) => item);
      const activityGoalArr = (detail?.activity_goals ?? []).map((item) => ({
        value: item,
      }));
      const activityMainArr = (detail?.main_activity ?? []).map((item) => ({
        value: item,
      }));
      const applicantArr = (detail?.applicant_information ?? []).map((item) => {
        return {
          name: item.name,
          nip: item.NIDN_NIP,
          description: item.description,
        };
      });

      let outcome = detail?.outcome ?? [];
      const outcomeArr = outcome
        .map((item) => item.value)
        .filter((item) => !!item);

      let output = detail?.output ?? [];
      const outputArr = output
        .map((item) => item.value)
        .filter((item) => !!item);

      setThematic(thematicArr);
      setOutcome(outcomeArr);
      setOutput(outputArr);

      const investmentStartPeriod = detail.investment?.start_period
        ? new Date(detail.investment?.start_period)
        : new Date();

      const investmentEndPeriod = detail.investment?.end_period
        ? new Date(detail.investment?.end_period)
        : new Date();

      reset({
        project: project,
        thematic: thematicArr,
        activities: activityMainArr,
        goals: activityGoalArr,
        output: outputArr,
        outcome: outcomeArr,
        proposal_description: detail.description_proposal,
        proposal_title: detail.proposal_title,
        applicant_information: applicantArr,
        duration: [investmentStartPeriod, investmentEndPeriod],
      });
    }
  }, [detail]);

  const { data: generalInformationData } = useQuery({
    enabled: !!id,
    queryKey: ["get-detail-general-information"],
    queryFn: async () => {
      const res = await getGeneralInformation({ id });
      return res.data.data;
    },
  });

  useEffect(() => {
    if (generalInformationData) {
      const strategy = generalInformationData?.strategy_program?.map((item) => {
        return { value: item };
      });

      methods.setValue("strategies", strategy);

      methods.setValue("project", {
        label: generalInformationData?.cfp?.program?.name,
        value: generalInformationData?.cfp?.program?.id,
      });
    }
  }, [generalInformationData]);

  const saveDraft = (values) => {
    let payload = {
      call_for_proposal_id: id || "",
      note_concept_id: values.project.value,
      user_id: "64c8e54d793cf0001848cb14",
      proposal_title: values.proposal_title,
      program: {
        id: values.project.value,
        name: values.project.label,
      },
      proposal_description: values.proposal_description,
      status: "DRAFT",
      duration_start: dayjs(values.duration[0]).format("DD-MM-YYYY"),
      duration_end: dayjs(values.duration[1]).format("DD-MM-YYYY"),
      thematic_programmes: values.thematic?.map((item) => item.value),
      output: values.output,
      outcome: values.outcome,
      main_activity_project: values.activities?.map((item) => item.value),
      objective_project: values.goals?.map((item) => item.value),
      strategy_program: values.strategies?.map((item) => item.value),
      proposer_information: values.applicant_information?.map((item) => {
        return {
          name: item.name,
          NIDN_NIP: item.nip,
          description: item.description,
        };
      }),
    };
    setPayload(payload);
    setConfirmModal((curr) => ({
      ...curr,
      open: true,
      type: "draft",
      cancelLabel: "Tidak",
      confirmLabel: "Iya, simpan draft",
      title: "Anda yakin akan menyimpan ini sebagai draft?",
      description:
        "Setelah anda menyimpan sebagai draft anda masih bisa melanjutkannya",
    }));
  };

  const submitForm = (values) => {
    let payload = {
      call_for_proposal_id: id || "",
      note_concept_id: values.project.value,
      user_id: "64c8e54d793cf0001848cb14",
      proposal_title: values.proposal_title,
      program: {
        id: values.project.value,
        name: values.project.label,
      },
      proposal_description: values.proposal_description,
      status: "SUBMIT",
      duration_start: dayjs(values.duration[0]).format("DD-MM-YYYY"),
      duration_end: dayjs(values.duration[1]).format("DD-MM-YYYY"),
      thematic_programmes: values.thematic?.map((item) => item.value),
      output: values.output,
      outcome: values.outcome,
      main_activity_project: values.activities?.map((item) => item.value),
      objective_project: values.goals?.map((item) => item.value),
      strategy_program: values.strategies?.map((item) => item.value),
      proposer_information: values.applicant_information?.map((item) => {
        return {
          name: item.name,
          NIDN_NIP: item.nip,
          description: item.description,
        };
      }),
    };
    setPayload(payload);
    setConfirmModal((curr) => ({
      ...curr,
      open: true,
      type: "submit",
      cancelLabel: "Tidak, ubah data",
      confirmLabel: "Ya, kirimkan",
      title: "Anda yakin akan mengirimkan semua data ini?",
      description:
        "Dengan ini kami menyatakan bahwa Seluruh data yang kami berikan pada formulir ini adalah benar adanya. Jika dikemudian hari BPDLH menemukan unsur kebohongan dan atau pemalsuan data, kami siap dan bersedia untuk diajukan ke ranah hukum sesuai dengan undang-undang yang berlaku. ",
    }));
  };

  const onSubmit = () => {
    submitMutation.mutate(payload);
  };

  const submitMutation = useMutation({
    mutationKey: ["application-post-general-information"],
    mutationFn: async (payload) => {
      const res = await postGeneralInformation(payload);
      return res.data.data;
    },
    onSuccess: (res) => {
      let idCfP = res?.id || id;

      setConfirmModal(false);
      enqueueSnackbar("Data berhasil disimpan", { variant: "success" });
      navigate(`/proposal/call-for-proposal/${idCfP}`);
      dispatch(saveIdProposalPpd(res?.proposal_id));
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const reachBottom = () => {
    if (page < lastPage) {
      setPage(page + 1);
    }
  };

  return (
    <Fragment>
      <ConfirmationModal
        useGraphic
        open={showConfirmModal.open}
        onCancel={() => {
          setConfirmModal((curr) => ({
            ...curr,
            open: false,
          }));
          setPayload(null);
        }}
        cancelLabel={showConfirmModal.cancelLabel}
        confirmLabel={showConfirmModal.confirmLabel}
        onSubmit={onSubmit}
      >
        <div className="space-y-1">
          <div className="text-lg text-gray-900 font-semibold">
            {showConfirmModal.title}
          </div>
          <div className="text-sm text-gray-600">
            {showConfirmModal.description}
          </div>
        </div>
      </ConfirmationModal>
      <div className="space-y-6 w-full">
        <div className="bg-white rounded-xl p-10 space-y-8">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: "Call For Proposal",
                  path: `/proposal/call-for-proposal`,
                },
                {
                  label: "Pengajuan Full Proposal",
                },
              ]}
            />
            <div className="flex mt-5 gap-4">
              <div className="flex-1">
                <HeaderLabel text="General Informasi" />
              </div>
            </div>
          </div>
          <div className="w-full">
            <AlertBlankData
              title={`Lengkapi Data`}
              body={`Silakan isi semua data dibawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya.`}
            />
          </div>
          <div className="space-y-8">
            <FormProvider {...methods}>
              <CardForm label="Informasi Dasar">
                <AtomSelect
                  controllerName={`project`}
                  options={projectOptions}
                  className="basic-single mt-2 w-2/5"
                  label={
                    "Pilih Proyek yang Akan Diajukan Proposal Program dan Atau Kegiatan"
                  }
                  required={true}
                  onMenuScrollToBottom={reachBottom}
                />
                <AtomSelect
                  className="basic-single mt-2"
                  label={"Thematic Prgrammes"}
                  required={true}
                  controllerName="thematic"
                  isMulti
                  options={thematic}
                  closeMenuOnSelect={false}
                  disable={true}
                />
                <div className="space-y-2">
                  <div className="text-gray-800 font-semibold text-sm">
                    Outcome
                  </div>
                  <ul className="list-disc list-inside">
                    {outcome.map((item, key) => (
                      <li key={key} className="text-gray-900 text-sm">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="space-y-2">
                  <div className="text-gray-800 font-semibold text-sm">
                    Output
                  </div>
                  <ul className="list-disc list-inside">
                    {output.map((item, key) => (
                      <li key={key} className="text-gray-900 text-sm">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </CardForm>
              <CardForm label="Informasi Umum Proposal">
                <InputForm
                  controllerName={"proposal_title"}
                  className={`mt-2 border w-full rounded-md flex-1`}
                  disabled
                  label="Judul Proposal"
                  placeholder="Judul Proposal"
                  required
                />
                <div className="space-y-2">
                  <div className="flex gap-1 font-semibold text-[14px]">
                    <span className={`label-text`}>Deskripsi Proposal</span>
                    <span className="text-[#F04438]">*</span>
                  </div>
                  <WarningAlert text="Jelaskan secara singkat & padat terkait isi dan substansi dari proposal program dan atau kegiatan yang diajukan" />
                  <InputForm
                    controllerName={"proposal_description"}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    disabled
                    placeholder="Deskripsi Proposal"
                    textArea={true}
                    rows={5}
                  />
                </div>
                <div className="space-y-2">
                  <AtomDatePicker
                    controllerName={"duration"}
                    isRangeDatePicker={true}
                    label="Durasi Pelaksanaan"
                    required={true}
                    disable={true}
                    customInputClassname="!bg-[#F4F5F2]"
                  />
                </div>
                {detailLoading ? (
                  <div className="w-full flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div>
                      <div className="space-y-5">
                        {activityFields.map((field, key) => (
                          <InputForm
                            key={field.id}
                            controllerName={`activities.${key}.value`}
                            label={`${
                              key === 0 ? "Aktivitas Utama Proyek" : ""
                            }`}
                            className={`border w-full rounded-md flex-1 ${
                              key === 0 ? "mt-2" : ""
                            }`}
                            disabled
                            placeholder="Aktivitas Utama Proyek"
                          />
                        ))}
                      </div>
                    </div>
                    <div>
                      <div className="space-y-5">
                        {goalFields.map((field, key) => (
                          <InputForm
                            key={field.id}
                            controllerName={`goals.${key}.value`}
                            label={`${
                              key === 0 ? "Tujuan Proyek / Kegiatan" : ""
                            }`}
                            className={`border w-full rounded-md flex-1 ${
                              key === 0 ? "mt-2" : ""
                            }`}
                            disabled
                            placeholder="Tujuan Proyek / Kegiatan"
                          />
                        ))}
                      </div>
                    </div>
                    <div>
                      <div className="flex gap-1 font-semibold text-[14px] mb-2">
                        <span className={`label-text`}>
                          Strategi untuk menjamin keberlanjutan progam atau
                          kegiatan yang diusulkan
                        </span>
                        <span className="text-[#F04438]">*</span>
                      </div>
                      <div className="space-y-5">
                        {strategyFields.map((field, key) => (
                          <div
                            className="flex gap-6 items-start"
                            key={field.id}
                          >
                            <InputForm
                              controllerName={`strategies.${key}.value`}
                              className={`border w-full rounded-md flex-1`}
                            />
                            {key === 0 ? (
                              <Button
                                type="button"
                                className="rounded-lg bg-primary-600 text-white shadow py-2 px-3.5 items-center font-semibold h-10"
                                label={
                                  <RiAddLine size={20} className="text-white" />
                                }
                                onClick={() =>
                                  appendStrategy({
                                    strategy: "",
                                  })
                                }
                              />
                            ) : (
                              <Button
                                type="button"
                                className="rounded-lg bg-error-600 text-white shadow py-2 px-3.5 items-center font-semibold h-10"
                                label={
                                  <RiDeleteBinLine
                                    size={20}
                                    className="text-white"
                                  />
                                }
                                onClick={() => {
                                  removeStrategy(key);
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </CardForm>
              <CardForm label="Informasi Pengusul">
                {detailLoading ? (
                  <div className="w-full flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : (
                  <div className="space-y-5">
                    {applicantFields.map((field, index) => (
                      <div className="flex gap-6">
                        <InputForm
                          controllerName={`applicant_information.${index}.name`}
                          className={`mt-2 border w-full rounded-md`}
                          disabled
                          label="Nama"
                          placeholder="Nama"
                          required
                        />
                        <InputForm
                          controllerName={`applicant_information.${index}.nip`}
                          className={`mt-2 border w-full rounded-md`}
                          disabled
                          label="NIDN/NIP"
                          placeholder="NIDN/NIP"
                          required
                        />
                        <InputForm
                          controllerName={`applicant_information.${index}.description`}
                          className={`mt-2 border w-full rounded-md`}
                          disabled
                          label="Deskripsi"
                          placeholder="Deskripsi"
                          required
                        />
                      </div>
                    ))}
                  </div>
                )}
              </CardForm>
              <BottomFormAction
                lastStep
                backButtonAction={() => navigate(-1)}
                disableDrafButton={false}
                drafButtonAction={() => {
                  const values = methods.getValues();
                  saveDraft(values);
                }}
                // disableButtonSubmit={isValid ? false : true}
                submitActionButton={() => {
                  methods.handleSubmit(submitForm)();
                }}
              />
            </FormProvider>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GeneralInformation;
