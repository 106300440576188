import * as yup from "yup";

export const debtorLembagaValidator = yup.object({
  debtor_development_file: yup
    .string()
    .required()
    .label("Perkembangan 3 Tahun Terakhir"),
  branch_file: yup.string().required().label("Jaringan Cabang"),
  products: yup.array(
    yup.object({
      type: yup.string().required().label("Jenis Produk"),
      name: yup.string().required().label("Nama Produk"),
      nominal_low: yup
        .number()
        .transform((val) => (val === 0 ? undefined : (isNaN(val) ? undefined : val)))
        .required()
        .label("Nominal Terendah"),
      nominal_high: yup
        .number()
        .transform((val) => (val === 0 ? undefined : (isNaN(val) ? undefined : val)))
        .required()
        .label("Nominal Tertinggi"),
      average_plafond: yup
        .number()
        .transform((val) => (val === 0 ? undefined : (isNaN(val) ? undefined : val)))
        .required()
        .label("Rata-Rata Plafond"),
      average_tenor: yup
        .number()
        .transform((val) => (val === 0 ? undefined : (isNaN(val) ? undefined : val)))
        .required()
        .label("Rata-Rata Tenor"),
      margin: yup
        .number()
        .transform((val) => (val === 0 ? undefined : (isNaN(val) ? undefined : val)))
        .required()
        .label("Suku Bunga Marjin"),
      notes: yup.string().required().label("Keterangan"),
    })
  ),
  sectors: yup.array(
    yup.object({
      sector: yup.string().required().label("Sektor"),
      total_member: yup.number().transform((val) => (val === 0 ? undefined : (isNaN(val) ? undefined : val))).required().label("Jumlah Anggota"),
      nominal: yup.number().required().transform((val) => (val === 0 ? undefined : (isNaN(val) ? undefined : val))).label("Nominal O/S"),
      year: yup.string().required().label("Tahun"),
    })
  ),
});

export const debtorNonPeroranganValidator = yup.object({
  debtor_development_file: yup
    .string()
    .required()
    .label("Perkembangan 3 Tahun Terakhir"),
  products: yup.array(
    yup.object({
      type: yup.string().required().label("Jenis Produk"),
      name: yup.string().required().label("Nama Produk"),
      income: yup
        .number()
        .transform((val) => (isNaN(val) ? undefined : val))
        .required()
        .label(),
      notes: yup.string().required().label("Keterangan"),
    })
  ),
  sectors: yup.array(
    yup.object({
      sector: yup.string().required().label("Sektor"),
      total_member: yup
        .number()
        .transform((val) => (isNaN(val) ? undefined : val))
        .required()
        .label("Jumlah Anggota"),
      nominal: yup
        .number()
        .transform((val) => (isNaN(val) ? undefined : val))
        .required()
        .label("Nominal O/S"),
      year: yup.string().required().label("Tahun"),
    })
  ),
});
