import { BottomFormAction, Button, InputForm } from "components";
import AccordionButton from "components/atoms/AccordionButton";
import AtomDatePicker from "components/atoms/Datepicker";
import AtomSelect from "components/atoms/Select";
import CardForm from "components/molecules/CardForm";
import InputFormDropzone from "components/molecules/InputFormDropzone";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { RiAddLine, RiPercentLine } from "react-icons/ri";
import { BiTrash } from "react-icons/bi";
import CurrencyInput from "components/organisms/inputs/InputCurrencyNoPackage";
import { formatCurrency } from "helpers";
import { useMutation } from "@tanstack/react-query";
import { postDirectSubmissionStep2 } from "services/danaProgram/directSubmissionService";
import { enqueueSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router";
import { fileUpload } from "services/danaProgram/fileService";
import FileDetail from "components/molecules/FileDetail";
import { useSearchParams } from "react-router-dom";

const ProgramImplementation = ({ onNext, onBack, type, payload }) => {
  const [searchParams] = useSearchParams();

  const methods = useFormContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [initDocs, setInitDocs] = useState([]);

  useEffect(() => {
    methods.setValue("isStep2", true);
  }, []);

  useEffect(() => {
    setInitDocs(methods.getValues("initActivityDoc"));
  }, [methods.watch("initActivityDoc")]);

  const { control, getValues } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "activity",
  });

  const { fields: torField } = useFieldArray({
    control,
    name: "tor",
  });

  const onSubmit = () => {
    let payload = {
      activity_doc: getValues("activity_doc"),
    };

    postFile.mutate(payload);
  };

  const postFile = useMutation({
    mutationKey: ["post-file-direct-submission-2"],
    mutationFn: async (payload) => {
      const data = payload.activity_doc?.map(async (item) => {
        const formData = new FormData();
        formData.append("file", item);
        const res = await fileUpload(formData);
        return {
          fileId: res.data.data.id,
          fileName: res.data.data.name,
          fileSize: res.data.data.size,
          mimeType: res.data.data.mimeType,
          path: res.data.data.key,
        };
      });

      return Promise.all([...(data || [])]);
    },
    onSuccess: (data) => {
      submitMutate.mutate({
        id: payload?.general_information_insentif.id,
        proposal_id: getValues("proposal_id"),
        activity_summary: getValues("activity_summary"),
        activity: getValues("activity"),
        activity_result: getValues("activity_result"),
        activity_doc: data?.[0]
          ? [data[0], ...(initDocs || [])]
          : [...(initDocs || [])],
        note: getValues("note"),
        status: "NEED_APPROVAL",
      });
    },
  });

  const submitMutate = useMutation({
    mutationKey: ["step-2-insentif"],
    mutationFn: async (payload) => {
      const res = await postDirectSubmissionStep2(payload);

      return res.data.data;
    },
    onSuccess: (res) => {
      enqueueSnackbar("Data Berhasil Disimpan", { variant: "success" });
      navigate(
        location?.pathname?.includes("/beneficiaries/insentif")
          ? "/beneficiaries/insentif"
          : "/request-insentif"
      );
    },
  });

  return (
    <div className="flex flex-col gap-3">
      <FormProvider {...methods}>
        <CardForm label="Pelaksanaan Project">
          <InputForm
            label={"Ringkasan Pelaksanaan Kegiatan"}
            controllerName={"activity_summary"}
            required
            className={`py-2 px-4 border w-full rounded-md flex-1 mt-2`}
            textArea
            asText={searchParams.get("mode") === "detail"}
          />
          {fields?.map((item, index) => (
            <ActivitySection
              key={item?.id}
              item={item}
              alias={`activity.${index}`}
              index={index}
              length={fields.length}
              remove={() => {
                remove(index);
              }}
              asText={searchParams.get("mode") === "detail"}
            />
          ))}
          {!searchParams.get("mode") === "detail" && (
            <div className="w-full flex items-center justify-center">
              <Button
                type="button"
                size="sm"
                className="p-3 border border-primary-50 bg-primary-700 hover:bg-primary-800 rounded-lg h-full"
                label={
                  <div className="flex items-center gap-2 text-white text-xs font-semibold">
                    <RiAddLine size={20} />
                    <span>Tambah Kegiatan</span>
                  </div>
                }
                onClick={() => {
                  append({
                    main_activity: "",
                    activity_date: "",
                    obstacle: "",
                    solution: "",
                    detail_activity: [
                      {
                        name: null,
                        budget: null,
                      },
                    ],
                  });
                }}
              />
            </div>
          )}
        </CardForm>
        <CardForm label="Hasil Kegiatan">
          {torField?.map((item, index) => (
            <OutputSection
              key={item?.id}
              label={item?.output?.label}
              indicator={item?.output?.indicator}
              index={index}
              asText={searchParams.get("mode") === "detail"}
            />
          ))}
        </CardForm>
        <CardForm label="Catatan atas Pelaksanaan Project">
          <InputForm
            label={"Catatan"}
            controllerName={"note"}
            className={`py-2 px-4 border w-full rounded-md flex-1 mt-2`}
            textArea
            asText={searchParams.get("mode") === "detail"}
          />
        </CardForm>
        <CardForm label="Dokumen Pendukung">
          <div className="flex flex-col gap-3">
            <label className="label-text font-semibold">
              Dokumentasi pelaksanaan project; dapat berupa foto, video,
              rekaman, dan/atau media dokumentasi lainnya
            </label>
            {(!searchParams.get("mode") === "detail" ||
              !searchParams.get("mode")) && (
              <InputFormDropzone
                name="activity_doc"
                accept={{
                  "image/png": [],
                  "image/jpeg": [],
                  "application/pdf": [],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [],
                }}
                maxFiles={1}
                maxSize={10}
                multiple={false}
                informationText="PNG, JPG, PDF, Docx"
              />
            )}
            {initDocs?.map((item, index) => (
              <FileDetail
                key={index}
                file={item}
                onRemove={() => {
                  const filtered = initDocs.filter(
                    (file) => file.fileId !== item.fileId
                  );
                  setInitDocs(filtered);
                }}
                hideDelete={searchParams.get("mode") === "detail"}
              />
            ))}
          </div>
        </CardForm>
      </FormProvider>
      <BottomFormAction
        lastStep={true}
        backButtonAction={() => onBack()}
        hideDraft
        hideSubmit={searchParams.get("mode") === "detail"}
        submitActionButton={methods.handleSubmit(onSubmit)}
      />
    </div>
  );
};

const ActivitySection = ({
  item,
  alias,
  index,
  length,
  remove,
  asText = false,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const { control, watch, getValues, setValue } = useFormContext();
  const {
    fields: fieldsDetail,
    append: appendDetail,
    remove: removeDetail,
  } = useFieldArray({
    control,
    name: `${alias}.detail_activity`,
  });

  return (
    <>
      <div className="flex gap-3 items-center">
        <AccordionButton
          key={"accordion-activity-btn"}
          label={`Kegiatan ${index + 1}`}
          info={formatCurrency(getValues(`${alias}.total_budget`))}
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        />
        {length > 1 && (
          <Button
            type="button"
            size="sm"
            className="p-3 border border-red-50 bg-red-700 hover:bg-red-800 rounded-lg h-fit"
            label={
              <div className="flex items-center gap-2 text-white text-xs font-semibold">
                <BiTrash size={20} />
              </div>
            }
            onClick={remove}
          />
        )}
      </div>
      {isOpen && (
        <div>
          <div className="grid grid-cols-2 gap-3">
            <div className="col-span-2">
              <AtomSelect
                label={"Kegiatan Utama"}
                controllerName={`${alias}.main_activity`}
                className={"mt-2"}
                options={watch("master_activity") ?? []}
                required
                asText={asText}
              />
            </div>
            <AtomDatePicker
              label="Tanggal Kegiatan"
              controllerName={`${alias}.activity_date`}
              // isRangeDatePicker
              className={"border-gray-200"}
              showErrorLabel
              asText={asText}
            />

            <div className="col-span-2">
              <InputForm
                label={"Hambatan (jika ada)"}
                controllerName={`${alias}.obstacle`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                textArea
                asText={asText}
              />
            </div>
            <div className="col-span-2">
              <InputForm
                label={"Solusi (jika ada)"}
                controllerName={`${alias}.solution`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                textArea
                asText={asText}
              />
            </div>
          </div>
          <hr className="my-5" />
          <div className="flex flex-col gap-3">
            {watch(`${alias}.detail_activity`).map((item, indexDetail) => (
              <div key={item?.id} className="flex flex-col gap-3">
                <DetailActivitySection
                  item={item}
                  index={indexDetail}
                  parentAlias={`${alias}`}
                  alias={`${alias}.detail_activity.${indexDetail}`}
                  fieldsDetail={fieldsDetail}
                  remove={() => {
                    removeDetail(indexDetail);
                  }}
                  append={() => {
                    appendDetail({
                      name: "",
                      outcome: 0,
                    });
                  }}
                  asText={asText}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const DetailActivitySection = ({
  fieldsDetail,
  item,
  index,
  alias,
  parentAlias,
  remove,
  append,
  asText = false,
}) => {
  const [detailActivity, setDetailActivity] = useState([]);
  const { setValue, getValues, watch } = useFormContext();

  useEffect(() => {
    let total = getValues(`${parentAlias}.detail_activity`)
      ?.map((item) => item?.budget)
      ?.reduce((item, currValue) => {
        return parseInt(item || 0) + parseInt(currValue || 0);
      }, 0);

    setValue(`${parentAlias}.total_budget`, total);
  }, [watch(`${alias}.budget`)]);

  return (
    <>
      <AtomSelect
        label={"Detail Kegiatan"}
        controllerName={`${alias}.name`}
        className={"mt-2"}
        options={watch(`${parentAlias}.main_activity.detail`) ?? []}
        required
        asText={asText}
      />
      <div className="flex items-end gap-3">
        <CurrencyInput
          label="Pengeluaran Biaya"
          controllerName={`${alias}.budget`}
          className={`py-2 px-4 border w-full rounded-md flex-1 mt-2`}
          asText={asText}
        />
        {!asText && (
          <>
            {index !== 0 && (
              <Button
                type="button"
                size="sm"
                className="p-3 border border-red-50 bg-red-700 hover:bg-red-800 rounded-lg h-fit"
                label={
                  <div className="flex items-center gap-2 text-white text-xs font-semibold">
                    <BiTrash size={20} />
                  </div>
                }
                onClick={remove}
              />
            )}
            {index === 0 && (
              <Button
                type="button"
                size="sm"
                className="p-3 border border-primary-50 bg-primary-700 hover:bg-primary-800 rounded-lg h-fit"
                label={
                  <div className="flex items-center gap-2 text-white text-xs font-semibold">
                    <RiAddLine size={20} />
                  </div>
                }
                onClick={append}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

const OutputSection = ({ label, indicator, index, asText }) => {
  const { setValue } = useFormContext();

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setValue(`activity_result.${index}.output.name`, label);
  }, []);

  return (
    <>
      <AccordionButton
        key={"accordion-activity-result-btn"}
        label={label}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div className="grid grid-cols-3 gap-3">
          {indicator?.map((item, indexIndicator) => (
            <IndicatorOutputSection
              key={item?.id}
              item={item}
              indicator={indicator}
              index={index}
              indexIndicator={indexIndicator}
              asText={asText}
            />
          ))}
        </div>
      )}
    </>
  );
};

const IndicatorOutputSection = ({
  item,
  indicator,
  index,
  indexIndicator,
  asText = false,
}) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    indicator?.map((item, indexDetail) => {
      setValue(
        `activity_result.${index}.output.indicator.${indexDetail}.name`,
        item
      );
    });
  }, []);
  return (
    <>
      <div className="col-span-2">
        <InputForm
          label={`Indikator ${index + 1}`}
          controllerName={`activity_result.${index}.output.indicator.${indexIndicator}.name`}
          className={`py-2 px-4 border w-full rounded-md flex-1 mt-2`}
          disabled
          asText={asText}
        />
      </div>
      <InputForm
        label="Update Kontribusi Capaian"
        controllerName={`activity_result.${index}.output.indicator.${indexIndicator}.achived_contribution`}
        className={`py-2 px-4 border w-full rounded-md flex-1 mt-2`}
        required
        showPrefix
        subfix={<RiPercentLine className="text-slate-400" />}
        asText={asText}
      />
    </>
  );
};

export default ProgramImplementation;
