import { useQuery } from "@tanstack/react-query";
import Alert from "components/atoms/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiAddLine, RiEyeLine, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { Button, InputForm, Table } from "../../../../components";
import RangeDatepicker from "../../../../components/atoms/RangeDatepicker";
import AtomSelect from "../../../../components/atoms/Select";
import Tag from "../../../../components/atoms/Tag";
import { formatCurrency } from "../../../../helpers";
import { getPengajuanAnggota } from "services/fdb/akunKelompok";
import { useMemo } from "react";
import dayjs from "dayjs";

const Status = {
  ON_PROGRESS: "on_progress",
  WAIT_VERIF: "waiting_verified",
  REJECTED: "rejected",
  VERIFIED: "verified",
  DRAFT: "draft",
};
// On Progress", "Menunggu Verifikasi", "Disetujui", "Ditolak
const statusOpts = [
  { value: Status.ON_PROGRESS, label: "On Progress" },
  { value: Status.WAIT_VERIF, label: "Menunggu Verifikasi" },
  { value: Status.REJECTED, label: "Proses Verifikasi" },
  { value: Status.VERIFIED, label: "Terverifikasi" },
];

const AkunKelompokPengajuanDaftarPermohonan = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      pageSize: 5,
      page: 1,
    },
  });

  useEffect(() => {
    const layouContentEl = document.getElementById("_layout-content");

    layouContentEl?.classList?.add("overflow-hidden");

    return () => {
      layouContentEl?.classList?.remove("overflow-hidden");
    };
  }, []);

  const { data, isFetching: listLoading } = useQuery({
    queryKey: [
      "fdb-kelompok-pengajuan-daftar-permohonan-list",
      {
        params: {
          page: page,
          limit: limit,
        },
      },
    ],
    queryFn: async ({ queryKey }) => {
      const res = await getPengajuanAnggota(queryKey[1].params);

      return (
        { results: res.data.data.data, meta: res.data.data.meta } || {
          results: [],
          meta: {},
        }
      );
    },
  });

  const paginator = useMemo(() => {
    return {
      page: page,
      limit: limit,
      total: data?.meta?.total,
      previous_pages: data?.meta?.previous_page_url,
      next_pages: data?.meta?.next_page_url,
    };
  }, [data, page, limit]);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "createdAt",
        title: "Tanggal Pengajuan Anggota",
        dataIndex: "created_at",
        className: "",
        columnClassName:
          "w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => dayjs(value).format("DD MMM YYYY"),
      },
      {
        id: "totalMember",
        title: "Total Anggota Diajukan",
        dataIndex: "total_member",
        className: "",
        columnClassName:
          "w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value} Anggota
          </div>
        ),
      },
      {
        id: "applicationValue",
        title: "Total Nilai Permohonan",
        dataIndex: "total_application_value",
        className: "",
        columnClassName:
          "w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {formatCurrency(value)}
          </div>
        ),
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        columnClassName: "w-[200px] text-ellipsis overflow-hidden",
        sortable: true,
        render: (value) => (
          <div>
            {value === Status.ON_PROGRESS ? (
              <Tag
                wrapperClass={
                  "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                }
                theme={"neutral"}
              >
                On Progress
              </Tag>
            ) : undefined}
            {value === Status.WAIT_VERIF ? (
              <Tag
                wrapperClass={
                  "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                }
                theme={"warning"}
              >
                Menunggu Verifikasi
              </Tag>
            ) : undefined}
            {value === Status.VERIFIED ? (
              <Tag
                wrapperClass={
                  "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                }
                theme={"success"}
              >
                Terverifikasi
              </Tag>
            ) : undefined}
            {value === Status.REJECTED ? (
              <Tag
                wrapperClass={
                  "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                }
                theme={"info"}
              >
                Ditolak
              </Tag>
            ) : undefined}
            {value === Status.DRAFT ? (
              <Tag
                wrapperClass={
                  "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                }
                theme={"neutral"}
              >
                Draft
              </Tag>
            ) : undefined}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "id",
        className: "",
        sortable: true,
        columnClassName: "w-[150px] text-center text-ellipsis overflow-hidden",
        fixed: "right",
        render: (value, data, index) => {
          return (
            <div className="flex flex-col justify-center items-center">
              {data?.status === Status.ON_PROGRESS || data?.status === Status.DRAFT ? (
                <Button
                  type={"button"}
                  className="bg-[#00BD52] text-white py-2 px-3 rounded-lg font-semibold text-[12px]"
                  label={
                    <div className="flex items-center gap-2 font-[14px]">
                      Lanjutkan Registrasi
                    </div>
                  }
                  onClick={() => {
                    if (data?.status === Status.DRAFT) navigate(`${location.pathname}/add/${value}`)
                    else navigate(`${location.pathname}/edit/${value}`)
                  }}
                />
              ) : (
                <Button
                  type={"button"}
                  className="p-4 bg-[transparent] rounded-full"
                  label={<RiEyeLine />}
                  onClick={() => navigate(`${location.pathname}/view/${value}`)}
                />
              )}
            </div>
          );
        },
      },
    ];
  }, [navigate, location.pathname, paginator.limit, paginator.page]);

  const handleChangeLimit = (e) => {
    setLimit(e);
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  return (
    <div className="w-full space-y-4 overflow-hidden">
      <div className="flex flex-col gap-y-6">
        {/* Header Section */}
        <div className="bg-[#FFFFFF] rounded-xl p-10">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: "Pengajuan Daftar Permohonan",
                  path: location.pathname,
                },
              ]}
            />
            <div className="mt-6 flex flex-row">
              <div className="flex-1">
                <div className="flex flex-col">
                  <span className="font-semibold text-[24px] text-[#101828]">
                    Pengajuan Daftar Permohonan
                  </span>
                  <span className="font-normal text-[14px] text-[#667085]"></span>
                </div>
              </div>
              <div className="flex-1 text-right">
                <button
                  className={
                    "py-3 px-4 rounded-lg text-sm bg-[#E34F13] text-white"
                  }
                  onClick={() => navigate(`${location.pathname}/add`)}
                >
                  <div className="flex flex-row gap-2 items-center">
                    <RiAddLine className={"w-5 h-5"} />
                    <span className="font-semibold">Ajukan Anggota</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <Alert
          theme={"warning"}
          title={"Ajukan Data Anggota"}
          description={
            "Harap mengisi data anggota yang akan mengajukan pinjaman.."
          }
        />

        <div
          className={"rounded-lg border border-gray-200 bg-white p-3 space-y-4"}
        >
          <FormProvider {...methods}>
            <div className={"flex flex-wrap items-center gap-4"}>
              <InputForm
                controllerName={"business_type"}
                wrapperClass={`py-2 px-4 mt-2 border w-[350px] rounded-md gap-2`}
                className={"flex-1"}
                placeholder={"Cari bedasarkan jumlah anggota"}
                prepend={<RiSearchLine />}
              />
              <AtomSelect
                controllerName={"filter"}
                wrapperClass={"!w-auto min-w-[170px]"}
                className="basic-single mt-2"
                placeholder={"Status"}
                options={statusOpts}
              />

              <RangeDatepicker
                controllerName={"picker"}
                wrapperClass={"inline-block w-auto min-w-[330px] -mt-4 -pt-2"}
                placeholder={"Tanggal range permohonan"}
                isClearable={true}
              />
            </div>
          </FormProvider>

          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4"
            columns={tableColumns}
            dataSource={data?.results}
            isLoading={listLoading}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeLimit}
            pagination={paginator}
          />
        </div>
      </div>
    </div>
  );
};

export default AkunKelompokPengajuanDaftarPermohonan;
