import PenilaianLemtara from 'pages/DanaProgram/PenilaianLemtara';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import Login from 'pages/Login';
import User from 'pages/User';
import ProfileUmum from 'pages/DanaProgram/ProfileUmum';
import RegisterDanaProgram from 'pages/RegisterDanaProgram';
import Register from 'pages/Register';
import RegistrationTermsAndConditions from 'pages/registrationTermsAndConditions';
import Portal from 'pages/FDB/Portal';
import PengalamanProgram from 'pages/DanaProgram/pengalamanProgram';
import Safeguards from 'pages/DanaProgram/safeguards';

import FinancingRequest from 'pages/FDB/FinancingRequest';
import SupplierApplication from 'pages/FDB/FinancingRequest/SupplierApplication';
import ApplicationForm from 'pages/FDB/FinancingRequest/SupplierApplication/ApplicationForm';
import ITSystemForm from 'pages/FDB/FinancingRequest/SupplierApplication/ITSystemForm';
import DebiturAksesForm from 'pages/FDB/FinancingRequest/SupplierApplication/DebiturAksesForm';
import NeracaKeuanganForm from 'pages/FDB/FinancingRequest/SupplierApplication/NeracaKeuanganForm';

import RegisterAccountGroup from 'pages/RegisterAccountGroup';
import ProfileOrganisasi from 'pages/DanaProgram/ProfileOrganisasi';
import SumberDaya from 'pages/DanaProgram/Sumberdaya';
import ProfileKeuangan from 'pages/DanaProgram/profileKeuangan';

import CallForProposal from 'pages/DanaProgram/CallForProposal';
import NewProposal from 'pages/DanaProgram/CallForProposal/new';
import GeneralInformation from 'pages/DanaProgram/CallForProposal/GeneralInformation';
import Beneficiaries from 'pages/DanaProgram/CallForProposal/Beneficiaries';
import ImplementationLocation from 'pages/DanaProgram/CallForProposal/Location';
import Documents from 'pages/DanaProgram/CallForProposal/Document';
import Workplan from 'pages/DanaProgram/CallForProposal/WorkPlan';
import ProposalSafeguards from 'pages/DanaProgram/CallForProposal/Safeguards';
import KonsepNotesList from 'pages/DanaProgram/KonsepNotes';
import KonsepNotesForm from 'pages/DanaProgram/KonsepNotes/Form';
import KonsepNotesDetail from 'pages/DanaProgram/KonsepNotes/Detail';
import DetailProposal from 'pages/DanaProgram/CallForProposal/Detail';
import DetailRencanaKemajuan from 'pages/DanaProgram/CallForProposal/DetailRencanaKemajuan';
import DetailRencanaKegiatan from 'pages/DanaProgram/CallForProposal/DetailRencanaKegiatan';
import InsentifList from 'pages/DanaProgram/Insentif';
import Insentif from 'pages/DanaProgram/Insentif/Form';

import kelompokRoute from './kelompokRoute';
import peroranganRoute from './peroranganRoute';
import lembagaRoute from './lembagaRoute';
import prospectingLemabagaRoute from './prospectingLembagaRoute';
import nonPeroranganRoute from './nonPeroranganRoute';
import bumdesRoute from './bumdesRoute';
import ptRoute from './ptRoute';
import cvRoute from './cvRoute';
import firmaRoute from './firmaRoute';
import RegisterBeneficiaries from 'pages/Beneficiaries';
import DisbursementList from 'pages/DanaProgram/Disbursement';
import DisbursementForm from 'pages/DanaProgram/Disbursement/Form';
import DisbursementDetail from 'pages/DanaProgram/Disbursement/Detail';
import ReportActivity from 'pages/Report/ReportActivity';
import ReportProgress from 'pages/Report/ReportProgress';
import ReportCommitment from 'pages/Report/ReportCommitment';
import ReportActivityForm from 'pages/Report/ReportActivity/Form';
import ReportProgressForm from 'pages/Report/ReportProgress/Form';
import ReportCommitmentForm from 'pages/Report/ReportCommitment/Form';
import finanncingRoute from './finanncingRoute';
import RegistrationFormRoute from './RegistrationFormRoute';
import offerRoute from './offerRoute';
import agreementRoute from './agreementRoute';
import DisbursementRequestRoute from './DisbursementRequestRoute';

const routesName = [
  {
    routeType: 'public',
    path: '/login',
    element: Login,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'public',
    path: '/reset-password',
    element: ResetPassword,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/',
    element: Portal,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'public',
    path: '/register-account-group',
    element: RegisterAccountGroup,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'public',
    path: '/register-dana-program',
    element: RegisterDanaProgram,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'public',
    path: '/register',
    element: Register,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'public',
    path: '/forgot-password',
    element: ForgotPassword,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/user',
    element: User,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/penilaian-lemtara',
    element: PenilaianLemtara,
    exact: true,
    sidebar: true,
  },

  // PROPOSAL
  {
    routeType: 'private',
    path: '/proposal/call-for-proposal',
    element: CallForProposal,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/proposal/call-for-proposal/new',
    element: NewProposal,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/proposal/call-for-proposal/:id',
    element: DetailProposal,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/proposal/call-for-proposal/general-information',
    element: GeneralInformation,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/proposal/call-for-proposal/beneficiaries',
    element: Beneficiaries,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/proposal/call-for-proposal/location',
    element: ImplementationLocation,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/proposal/call-for-proposal/documents',
    element: Documents,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/proposal/call-for-proposal/workplan',
    element: Workplan,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/proposal/call-for-proposal/safeguards',
    element: ProposalSafeguards,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/proposal/call-for-proposal/rencana-kemajuan/:id',
    element: DetailRencanaKemajuan,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/proposal/call-for-proposal/rencana-kegiatan/:id',
    element: DetailRencanaKegiatan,
    exact: true,
    sidebar: true,
  },

  // ==========================
  // kelembagaan

  ...lembagaRoute,

  // end of kelembagaan
  // ==========================

  // ==========================
  // Financing Routes

  ...finanncingRoute,

  // end of Financing Routes
  // ==========================

  // ==========================
  // AKUN KELOMPOK

  ...kelompokRoute,

  // END AKUN KELOMPOK
  // ==========================

  // ==========================
  // AKUN PERORANGAN

  ...peroranganRoute,

  // END AKUN PERORANGAN
  // ==========================

  {
    routeType: 'private',
    path: '/penilaian-lemtara/profile-umum',
    element: ProfileUmum,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/penilaian-lemtara/profile-organisasi',
    element: ProfileOrganisasi,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/penilaian-lemtara/sumber-daya',
    element: SumberDaya,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/penilaian-lemtara/profile-keuangan',
    element: ProfileKeuangan,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'public',
    path: '/portal/terms-and-conditions',
    element: RegistrationTermsAndConditions,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/penilaian-lemtara/pengalaman-program',
    element: PengalamanProgram,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/penilaian-lemtara/safeguards',
    element: Safeguards,
    exact: true,
    sidebar: false,
  },

  // Register Beneficiaries
  {
    routeType: 'public',
    path: '/beneficiaries/register',
    element: RegisterBeneficiaries,
    exact: true,
    sidebar: false,
  },

  // Report Progress (Laporan Kemajuan)
  {
    routeType: 'private',
    path: '/report/progress',
    element: ReportProgress,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/report/progress/form',
    element: ReportProgressForm,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/report/progress/detail/:id',
    element: ReportProgressForm,
    exact: true,
    sidebar: false,
  },
  // Report Activity (Laporan Kegiatan)
  {
    routeType: 'private',
    path: '/report/activity',
    element: ReportActivity,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/report/activity/form',
    element: ReportActivityForm,
    exact: true,
    sidebar: false,
  },

  // Report Commitment (Laporan Komitmen)
  {
    routeType: 'private',
    path: '/report/commitment',
    element: ReportCommitment,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/report/commitment/form',
    element: ReportCommitmentForm,
    exact: true,
    sidebar: false,
  },

  // Konsep Notes
  {
    routeType: 'private',
    path: '/proposal/konsep-notes',
    element: KonsepNotesList,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/proposal/konsep-notes/form',
    element: KonsepNotesForm,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/proposal/konsep-notes/:id',
    element: KonsepNotesDetail,
    exact: true,
    sidebar: false,
  },

  // ====== End Konsep Notes =====

  // Pencairan Dana
  {
    routeType: 'private',
    path: '/disbursement/application',
    element: DisbursementList,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/disbursement/application/:id',
    // element: DisbursementDetail,
    element: DisbursementForm,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/disbursement/application/form',
    element: DisbursementForm,
    exact: true,
    sidebar: false,
  },
  // End Pencairan Dana

  // {
  //   routeType: "private",
  //   path: "/financing-request",
  //   element: FinancingRequest,
  //   exact: true,
  //   sidebar: true,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/supplier-application",
  //   element: SupplierApplication,
  //   exact: true,
  //   sidebar: true,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/supplier-application/aplication-form",
  //   element: ApplicationForm,
  //   exact: true,
  //   sidebar: false,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/supplier-application/it-system",
  //   element: ITSystemForm,
  //   exact: true,
  //   sidebar: false,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/supplier-application/debtor-access",
  //   element: DebiturAksesForm,
  //   exact: true,
  //   sidebar: false,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/supplier-application/finance/:role",
  //   element: NeracaKeuanganForm,
  //   exact: true,
  //   sidebar: false,
  // },
  // ==========================
  // PROSPECTING LEMBAGA
  ...prospectingLemabagaRoute,

  // ==========================
  // NON PERORANGAN
  ...nonPeroranganRoute,

  // ==========================
  // BUMDES
  ...bumdesRoute,

  // ==========================
  // PT
  ...ptRoute,

  // ==========================
  // CV
  ...cvRoute,

  // ==========================
  // Firma
  ...firmaRoute,
  {
    routeType: 'private',
    path: '/request-insentif',
    element: InsentifList,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/request-insentif/form',
    element: Insentif,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/request-insentif/form:id',
    element: Insentif,
    exact: true,
    sidebar: false,
  },
  // ============ Request Insentif Beneficiaries
  {
    routeType: 'private',
    path: '/beneficiaries/insentif',
    element: InsentifList,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/beneficiaries/insentif/form',
    element: Insentif,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/beneficiaries/insentif/form:id',
    element: Insentif,
    exact: true,
    sidebar: false,
  },

  // ==========================
  // Registration Form
  ...RegistrationFormRoute,

  // ==========================
  // Penawaran
  ...offerRoute,

  // ==========================
  // Perjanjian
  ...agreementRoute,

  // ==========================
  // Permohonan Pencairan
  ...DisbursementRequestRoute,
];

export default routesName;
