import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import {
  RiAddLine,
  RiCheckLine,
  RiDeleteBin2Line,
  RiEyeLine,
} from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import {
  BottomFormAction,
  Button,
  ConfirmationModal,
  HeaderLabel,
  InputForm,
} from "../../../../../components";
import AtomDatePicker from "../../../../../components/atoms/Datepicker";
import FormattedDate from "../../../../../components/atoms/FormattedDate";
import BreadCrumbs from "../../../../../components/molecules/Breadcrumbs";
import CardForm from "../../../../../components/molecules/CardForm";
import ProgressModal from "../../../../../components/molecules/Modal/ProgressModal";
import { getErrorMessage } from "../../../../../helpers";
import {
  getPengajuanAnggotaMembers,
  postPengajuanAnggota,
  submitPengajuanAnggota,
} from "services/fdb/akunKelompok";
import SelectGender from "components/organisms/inputs/SelectGender";
import SelectPurpose from "components/organisms/inputs/SelectPurpose";
import InputMaskForm from "components/V2/shared/input-mask-form";
import classNames from "classnames";
import moment from "moment";

const FieldLabel = {
  NAME: "Nama Debitur",
  KTP: "NIK",
  DATE_OF_BIRTH: "Tgl Lahir",
  EMAIL: "Email",
  PHONE: "No Telepon",
  NPWP: "NPWP",
  GENDER: "Jenis Kelamin",
  LOAN_AMOUNT: "Nilai Permohonan",
  SERVICE_TYPE: "Jenis Layanan",
  SUBMISSION_PURPOSE: "Tujuan Penggunaan",
  DETAIL_SUBMISSION_PURPOSE: "Penjelasan Tujuan Penggunaan",
  MEMBERS: "Daftar Anggota Pemohon",
  TOTAL_REQ_VALUE: "Total Nilai Permohonan",
  TOTAL_REQ_VALUE_WORD: "Terbilang",
};

function AkunKelompokPSPengajuanDaftar() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { id } = params || {};

  const memberCounter = useRef(0);

  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);
  const [canEditList, setCanEditList] = useState(false);
  const [renderCount, setRenderCount] = useState(0);
  const [isDraft, setIsDraft] = useState(false);

  const validationSchema = Yup.object().shape({
    members: Yup.array()
      .of(
        Yup.object().shape({
          name_debtor: Yup.string().trim().required().label(FieldLabel.NAME),
          identity_number: Yup.string()
            .trim()
            .length(16)
            .required()
            .label(FieldLabel.KTP),
          date_of_birth: Yup.string().required().label(FieldLabel.DATE_OF_BIRTH),
          email: Yup.string().email().required().label(FieldLabel.EMAIL),
          phone: Yup.string().required().label(FieldLabel.PHONE),
          npwp: Yup.string()
            .notRequired()
            .test("lengthIfFilled", "NPWP harus tepat 15 karakter", (val) => {
              if (typeof val !== "undefined" && val !== "" && val !== null) {
                return val.length >= 20;
              }

              return true;
            })
            .label(FieldLabel.NPWP),
          gender: Yup.string().required().label(FieldLabel.GENDER),
          objective: Yup.string()
            .trim()
            .required()
            .label(FieldLabel.SUBMISSION_PURPOSE),
          objective_desc: Yup.string().when(["isDraft"], ([is_draft], schema) => {
            if (!isDraft) {
              return schema.trim().required().label(FieldLabel.DETAIL_SUBMISSION_PURPOSE);
            }
          })
          // .trim()
          // .required()
          // .label(FieldLabel.DETAIL_SUBMISSION_PURPOSE),
        })
      )
      .required()
      .min(1)
      .label(FieldLabel.MEMBERS),
    membersLen: Yup.number()
      .when(["members"], ([member], schema) => {
        if (member.length) {
          return schema;
        }

        return Yup.number().min(1).required().label(FieldLabel.MEMBERS);
      })
      .notRequired()
      .label(FieldLabel.MEMBERS),
  });

  // get mode from path
  const splitPath = location?.pathname?.split("/").filter((e) => !!e);
  const mode = params?.id ? splitPath.at(-2) : splitPath?.pop();
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const generateNewEntry = () => ({
    name_debtor: "",
    identity_number: "",
    date_of_birth: "",
    email: "",
    phone: "",
    gender: "",
    objective: "",
    objective_desc: "",
  });

  const onBack = useCallback(() => {
    navigate("/kelompok/perhutanan-sosial/pengajuan-daftar-permohonan");
  }, []);

  useEffect(() => {
    const layouContentEl = document.getElementById("_layout-content");

    layouContentEl?.classList?.add("overflow-hidden");

    return () => {
      layouContentEl?.classList?.remove("overflow-hidden");
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const routes = useMemo(
    () => [
      {
        label: "Pengajuan Daftar Permohonan",
        path: location?.pathname?.split("/")?.slice(0, -1)?.join("/"),
      },
      {
        label: "Daftar Anggota Pemohon",
        path: location?.pathname,
      },
    ],
    [location]
  );

  // setup form
  const methods = useForm({
    resolver: isAdd
      ? yupResolver(validationSchema)
      : yupResolver(Yup.object().notRequired()),
    mode: "onChange",
    defaultValues: {
      members: [generateNewEntry()],
      membersLen: 1, // this is a proxy field to enable `members` field validation without triggering array content validation
    },
  });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "members",
  });
  const watchFieldArray = useWatch({
    control: methods.control,
    name: "members",
  });
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  // api call
  const { data } = useQuery({
    queryKey: ["pengajuan-permohonan-non-perhutanan-sosial-detail", id],
    queryFn: async () => {
      const res = await getPengajuanAnggotaMembers(id);
      return {
        appliant: res.data.data.appliant.data,
        submission: res.data.data.submission,
      };
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!id,
  });

  const submitMutation = useMutation({
    mutationFn: (params) => {
      return postPengajuanAnggota(params);
    },
    onSuccess: (res) => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      isDraft ?
        navigate(
          `/kelompok/perhutanan-sosial/pengajuan-daftar-permohonan`
        ) : navigate(
          `/kelompok/perhutanan-sosial/pengajuan-daftar-permohonan/edit/${res?.data?.data}`
        );
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      setConfirmModal(false);
    },
  });

  useEffect(() => {
    // TODO: Add logic to determine if member list can stil be change (in terms of addition and deletion)
    if (isAdd) {
      setCanEditList(true);
    } else {
      setCanEditList(false);
    }
  }, [data, mode]);

  useEffect(() => {
    if (isAdd && id && data) {
      methods.reset({
        members: data?.appliant?.map((el) => ({
          ...el,
          date_of_birth: new Date(el?.date_of_birth),
        })),
      })
    }
  }, [data, isAdd, id, methods]);

  const submitAnggotaMutation = useMutation({
    mutationFn: (params) => {
      return submitPengajuanAnggota(id);
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      navigate("/kelompok/perhutanan-sosial/pengajuan-daftar-permohonan");
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      setConfirmModal(false);
    },
  });

  // methods
  const onFormSubmit = () => {
    setTimeout(() => {
      if (!isView) {
        methods.handleSubmit((data) => {
          setConfirmModal(true);
        })();
      } else {
        return (e) => {
          e.stopPropagation();
          setConfirmModal(true);
        };
      }
    }, 0)
  };

  const doSubmit = () => {
    if (isAdd) {
      const dataMethods = methods.getValues();
      const payload = {
        members: dataMethods?.members.map((el) => ({
          ...el,
          date_of_birth: moment(el?.date_of_birth).format("YYYY-MM-DD"),
        })),
        submission_application_id: id ? data?.submission?.id : null,
        is_draft: false,
      };

      submitMutation.mutate(payload);
    } else {
      submitAnggotaMutation.mutate();
    }
  };

  const onDeleteRow = (index) => async () => {
    remove(index);

    // update proxy value
    methods.setValue("membersLen", fields.length - 1);
    await methods.trigger("membersLen");

    // force rerender to trigger form error update
    setRenderCount(renderCount + 1);
  };

  const onAddRow = async () => {
    append(generateNewEntry());

    // update proxy value
    methods.setValue("membersLen", fields.length + 1);
    await methods.trigger("membersLen");

    // force rerender to trigger form error update
    setRenderCount(renderCount + 1);
  };

  const onMemberEditClick = (item) => () => {
    const currLoc = location?.pathname || "";
    let target = currLoc?.endsWith("/") ? currLoc : currLoc + "/";

    navigate(`${target}member/edit/${item?.id}`, {
      state: {
        member: {
          ...item,
          group_name: data.submission.group_profile.name,
        },
      },
    });
  };

  const onMemberViewClick = (item) => () => {
    const currLoc = location?.pathname || "";
    let target = currLoc?.endsWith("/") ? currLoc : currLoc + "/";

    navigate(`${target}member/view/${item?.id}`, {
      state: {
        member: {
          ...item,
          group_name: data.submission.group_profile.name,
        },
      },
    });
  };

  const onSaveDraft = (val) => {
    const dataMethods = methods.getValues();
    const payload = {
      members: dataMethods?.members.map((el) => ({
        ...el,
        date_of_birth: el?.date_of_birth ? moment(el?.date_of_birth).format("YYYY-MM-DD") : '',
      })),
      submission_application_id: id ? data?.submission?.id : null,
      is_draft: true,
    };
    submitMutation.mutate(payload);
  }

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={doSubmit}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ProgressModal
          open={confirmModal && submitMutation.isLoading}
          className="space-y-2 mb-4"
        />,
        document.body
      )}

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel
          text="Daftar Anggota Pemohon"
          description="Buat permohonan baru."
        />

        <div className="border-b" />

        <FormProvider {...methods}>
          {/* <form
            // onSubmit={}
            className="space-y-8"
            id={"main-content"}
          > */}
          <CardForm label="Daftar Anggota Pemohon">
            <div className={"w-full overflow-y-visible overflow-x-auto"}>
              <table className={"tb-app table-fixed w-full relative"}>
                <thead>
                  <tr>
                    <th className={"w-[18rem]"}>{FieldLabel.NAME}</th>
                    <th className={"w-[15rem]"}>{FieldLabel.KTP}</th>
                    <th className={"w-[15rem]"}>
                      {FieldLabel.DATE_OF_BIRTH}
                    </th>
                    <th className={"w-[15rem]"}>{FieldLabel.EMAIL}</th>
                    <th className={"w-[16rem]"}>{FieldLabel.PHONE}</th>
                    <th className={"w-[16rem]"}>{FieldLabel.NPWP}</th>
                    <th className={"w-[16rem]"}>{FieldLabel.GENDER}</th>
                    <th className={"w-[16rem]"}>
                      {FieldLabel.SUBMISSION_PURPOSE}
                    </th>
                    <th className={"w-[22rem]"}>
                      {FieldLabel.DETAIL_SUBMISSION_PURPOSE}
                    </th>
                    <th className={"w-[6rem] sticky right-0 bg-[inherit]"}>
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!isAdd &&
                    data?.appliant?.map((entry, index) => (
                      <tr key={entry?.id}>
                        <td>{entry?.name_debtor}</td>
                        <td>{entry?.identity_number}</td>
                        <td>
                          <FormattedDate date={entry?.date_of_birth} />
                        </td>
                        <td>{entry?.email}</td>
                        <td>{entry?.phone}</td>
                        <td>{entry?.group_profile_member?.npwp}</td>
                        <td>
                          {entry?.gender === "m" ? "Laki laki" : "Perempuan"}
                        </td>
                        <td>{entry?.objective}</td>
                        <td>{entry?.objective_desc}</td>
                        <td
                          className={
                            "space-x-1 space-y-1 sticky right-0 bg-[#F9FCF6] !align-middle"
                          }
                        >
                          {isView ? (
                            data?.submission?.status !== "on_progress" && (
                              <Button
                                type={"button"}
                                className="text-xl bg-[transparent] rounded-[100%] w-12 h-12"
                                label={<RiEyeLine />}
                                onClick={onMemberViewClick(entry)}
                              />
                            )
                          ) : (
                            <div>
                              <Button
                                type={"button"}
                                className="text-xl bg-[transparent] rounded-[100%] h-12 w-12"
                                label={
                                  <TbEdit
                                    className={classNames(
                                      entry?.group_profile_member
                                        ?.is_draft === false &&
                                      "text-primary-700"
                                    )}
                                  />
                                }
                                onClick={onMemberEditClick(entry)}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  {isAdd &&
                    fields?.map((entry, index) => (
                      <tr key={entry?.id}>
                        <td>
                          <InputForm
                            controllerName={`members.${index}.name_debtor`}
                            className={`py-2 px-4 border w-full rounded-md flex-1`}
                            placeholder={FieldLabel.NAME}
                          />
                        </td>
                        <td>
                          <InputForm
                            controllerName={`members.${index}.identity_number`}
                            className={`py-2 px-4 border w-full rounded-md flex-1`}
                            placeholder={FieldLabel.KTP}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength="16"
                          />
                        </td>
                        <td>
                          <AtomDatePicker
                            controllerName={`members.${index}.date_of_birth`}
                            className={`py-2 pl-4 pr-8 border w-full rounded-md flex-1`}
                            placeholder={FieldLabel.DATE_OF_BIRTH}
                            label={null}
                            showErrorLabel={true}
                            datepickerOptions={{
                              portalId: "main-content",
                              maxDate: new Date(),
                              showMonthDropdown: true,
                              showYearDropdown: true,
                              scrollableYearDropdown: true,
                              dropdownMode: "select",
                            }}
                          />
                        </td>
                        <td>
                          <InputForm
                            controllerName={`members.${index}.email`}
                            className={`py-2 px-4 border w-full rounded-md flex-1`}
                            placeholder={FieldLabel.EMAIL}
                          />
                        </td>
                        <td>
                          <InputForm
                            controllerName={`members.${index}.phone`}
                            className={`py-2 px-4 border w-full rounded-md flex-1`}
                            maxLength={14}
                            placeholder={FieldLabel.PHONE}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </td>
                        <td>
                          <InputMaskForm
                            name={`members.${index}.npwp`}
                            placeholder={FieldLabel.NPWP}
                            mask="##.###.###.#-###.###"
                            replacement={{ "#": /\d/ }}
                          />
                        </td>
                        <td>
                          <SelectGender
                            controllerName={`members.${index}.gender`}
                            returnObject={false}
                            menuPortalTarget={document.body}
                          />
                        </td>
                        <td>
                          <SelectPurpose
                            controllerName={`members.${index}.objective`}
                            returnObject={false}
                            menuPortalTarget={document.body}
                          />
                        </td>
                        <td>
                          <InputForm
                            controllerName={`members.${index}.objective_desc`}
                            className={`py-2 px-4 border w-full rounded-md flex-1`}
                            placeholder={FieldLabel.DETAIL_SUBMISSION_PURPOSE}
                          />
                        </td>
                        <td
                          className={
                            "space-x-1 space-y-1 sticky right-0 bg-[#F9FCF6] !align-middle"
                          }
                        >
                          {isAdd ? (
                            <Button
                              type={"button"}
                              className="text-xl bg-[transparent] rounded-[100%] w-12 h-12"
                              size="sm"
                              label={<RiDeleteBin2Line />}
                              onClick={onDeleteRow(index)}
                            />
                          ) : undefined}

                          {isEdit || isView ? (
                            <Button
                              type={"button"}
                              className="text-xl bg-[transparent] rounded-[100%] h-12 w-12"
                              label={<TbEdit />}
                              onClick={onMemberEditClick(entry)}
                            />
                          ) : undefined}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {methods.getFieldState("membersLen")?.invalid && (
              <label className="label">
                <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                  {methods.getFieldState("members")?.error?.message}
                </div>
              </label>
            )}
          </CardForm>

          <div className="border-b" />

          {isAdd && (
            <div className={"text-right"}>
              <Button
                type={"button"}
                className="p-4 bg-[transparent] border rounded-lg"
                label={
                  <div className="flex items-center gap-2 font-[14px]">
                    <RiAddLine /> Tambah Debitur
                  </div>
                }
                onClick={onAddRow}
              />
            </div>
          )}

          <BottomFormAction
            lastStep={true}
            backButtonAction={onBack}
            backButtonProps={{
              type: "button",
            }}
            hideDraft={isView}
            disableButtonSubmit={false}
            disableDrafButton={false}
            hideSubmit={isView}
            drafButtonAction={() => {
              setIsDraft(true);
              setTimeout(() => {
                methods.handleSubmit((data) => {
                  onSaveDraft(data); // Call your save draft function here
                })();
              }, 0);
            }}
            // drafButtonAction={onSaveDraft}
            submitActionButton={() => {
              setIsDraft(false);
              onFormSubmit();
            }}
            submitButtonProps={{
              className: "text-white",
              type: "submit",
              label: (
                <div className={"flex items-center gap-2 text-white"}>
                  <RiCheckLine className={"w-5 h-5 shrink-0"} />
                  {isEdit ? "Submit" : "Finalisasi Registrasi"}
                </div>
              ),
            }}
          />
          {/* </form> */}
        </FormProvider>
      </div>
    </>
  );
}

export default AkunKelompokPSPengajuanDaftar;
