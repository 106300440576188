import { Button } from "components";
import AlertBlankData from "components/molecules/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, { useMemo, useCallback } from "react";
import {
  RiComputerLine,
  RiEdit2Line,
  RiUploadCloud2Line,
} from "react-icons/ri";
import { useNavigate } from "react-router";
import ProfileUmum from "../../../assets/illustrations/Profil Umum.png";
import Alert from "components/atoms/Alert";
import Tag from "components/atoms/Tag";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { getAgencyProfile } from "services/fdb/lembagaPenyalur";
import { useAuth } from "hooks/auth";

const RegistrationForm = (props) => {
  const { type, group } = props;
  const navigate = useNavigate();

  const { id } = useAuth();
  const { data } = useQuery({
    queryKey: ["agency-profile"],
    queryFn: getAgencyProfile,
    select: (data) => data?.data?.data,
  });

  const moduleItems = useMemo(() => {
    return [
      {
        icon: <RiComputerLine className="text-white" />,
        label: "profil Umum",
        path: `/formulir-pendaftaran/${group}/${type}/profile-umum`,
        background: "bg-orangedark-600",
        backgroundOuter: "bg-orangedark-100",
        bgIlustration: ProfileUmum,
        isDraft: data?.agency_profile?.is_draft,
        status: data?.agency_profile?.status,
        status_string: data?.agency_profile?.status_string,
        isSubmitted: false,
        isSubmittable: true,
      },
    ];
  }, [type, group, data]);

  return (
    <div className="w-full space-y-4">
      <div className="flex flex-col gap-y-6">
        {/* Header Section */}
        <div className="bg-[#FFFFFF] rounded-xl p-10">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: "Formulir Pendaftaran",
                  // path: `/lembaga-penyalur/${type}`,
                },
                {
                  label: `Registrasi Prospecting ${group === "non-perorangan"
                    ? "Non Perorangan"
                    : "Lembaga Penyalur"
                    }`,
                  path: `/formulir-pendaftaran/${group}/${type}`,
                },
              ]}
            />
            <div className="mt-6 flex flex-row">
              <div className="flex-1">
                <div className="flex flex-col">
                  <span className="font-semibold text-[24px] text-[#101828]">
                    Registrasi Prospecting{" "}
                    {group === "non-perorangan"
                      ? "Non Perorangan"
                      : "Lembaga Penyalur"}
                  </span>
                  <span className="font-normal text-[14px] text-[#667085]">
                    Lengkapi Data Registrasi Prospecting{" "}
                    {group === "non-perorangan"
                      ? "Non Perorangan"
                      : "Lembaga Penyalur"}
                    .
                  </span>
                </div>
              </div>
              {/* <div className="flex-1 text-right">
                <Button
                  type="button"
                  // disabled={!isSubmittable}
                  color="orange"
                  label={
                    <>
                      <RiUploadCloud2Line />
                      <span className="font-semibold font-[14px]">
                        Upload Semua Persyaratan
                      </span>
                    </>
                  }
                  // onClick={submitProfile}
                />
              </div> */}
            </div>
          </div>
        </div>

        {
          _.map(moduleItems, ({ status_string }) => (
            <React.Fragment>
              {status_string === "Revisi" && (
                <AlertBlankData
                  title={"Harap Kembali Upload Persyaratan"}
                  body={
                    "Setelah proses mengubah data yang ditolak harap kembali mengupload persyaratan."
                  }
                />
              )}
              {status_string === "Belum Direview" && (
                <AlertBlankData
                  title={"Harap Kembali Upload Persyaratan"}
                  body={
                    "Harap melengkapi formulir dengan baik dan benar sehingga dapat dilanjutkan ke tahap berikutnya."
                  }
                />
              )}
            </React.Fragment>
          ))
          // status_string !== "Revisi"
        }

        <div className="flex flex-wrap gap-4">
          {_.map(
            moduleItems,
            (
              {
                icon,
                label,
                path,
                background,
                backgroundOuter,
                isSubmittable,
                bgIlustration,
                isDraft,
                status,
                status_string,
                isSubmitted,
              },
              index
            ) => (
              <div
                className="flex flex-col items-center w-[285px] gap-4 bg-white shadow-xl rounded-xl relative"
                key={index}
              >
                <div className="p-4 absolute -top-10">
                  <div className="flex items-center gap-2">
                    <div className={`${backgroundOuter} p-3 rounded-full`}>
                      <div className={`${background} p-3 rounded-full`}>
                        {icon}
                      </div>
                    </div>
                  </div>
                </div>
                <span className="mt-12 font-semibold">
                  {_.upperFirst(label)}
                </span>
                <img src={bgIlustration} alt="general-information-img" />

                <div className="px-4 space-y-4">
                  {isDraft === null && status === null && (
                    <Alert
                      theme={"warning"}
                      title={"Lengkapi Dokumen"}
                      description={
                        "Lengkapi kesesuaian dokumen administrasi yang dipersyaratkan"
                      }
                    />
                  )}

                  {isDraft === true && (
                    <>
                      {status === null && (
                        <Alert
                          theme={"warning"}
                          title={"Lengkapi Dokumen"}
                          description={
                            "Lengkapi kesesuaian dokumen administrasi yang dipersyaratkan"
                          }
                        />
                      )}

                      {status_string === "Belum Direview" && (
                        <Tag label={"Data Belum Lengkap"} theme={"warning"} />
                      )}

                      {status_string === "Revisi" && (
                        <Tag label={"Revisi"} theme={"warning"} />
                      )}
                    </>
                  )}

                  {isDraft === false && (
                    <>
                      {status === null && (
                        <Tag label={"Data Sudah Lengkap"} theme={"success"} />
                      )}

                      {status_string === "Belum Direview" && (
                        <Tag label={"Data Sudah Lengkap"} theme={"success"} />
                      )}

                      {status_string === "Revisi" && (
                        <Tag label={"Revisi"} theme={"warning"} />
                      )}

                      {status_string === "Terverifikasi" && (
                        <Tag label={"Terverifikasi"} theme={"success"} />
                      )}
                    </>
                  )}
                </div>

                <div className="p-4 w-full border-t">
                  <div className="text-right">
                    {isDraft === null && status === null && (
                      <button
                        onClick={() => navigate(`${path}`)}
                        className="bg-[#00BD52] text-white py-2 px-4 rounded-lg w-full font-semibold text-[12px]"
                      >
                        Lengkapi
                      </button>
                    )}

                    {isDraft === true && (
                      <>
                        {status === null && (
                          <button
                            onClick={() => navigate(`${path}`)}
                            className="bg-[#00BD52] text-white py-2 px-4 rounded-lg w-full font-semibold text-[12px]"
                          >
                            Lengkapi
                          </button>
                        )}

                        {status !== null && (
                          <button
                            onClick={() => navigate(`${path}`)}
                            className="bg-white text-gray-700 border-[1px] border-gray-700 py-2 px-4 rounded-lg  w-full font-semibold text-[12px]"
                          >
                            <RiEdit2Line className="inline-block w-4 h-4 mr-2 align-middle " />
                            <span className={"align-middle "}>Ubah</span>
                          </button>
                        )}
                      </>
                    )}

                    {isDraft === false && (
                      <>
                        {status_string === "Revisi" && (
                          <button
                            onClick={() => navigate(`${path}`)}
                            className="bg-white text-gray-700 border-[1px] border-gray-700 py-2 px-4 rounded-lg  w-full font-semibold text-[12px]"
                          >
                            <RiEdit2Line className="inline-block w-4 h-4 mr-2 align-middle " />
                            <span className={"align-middle "}>Ubah</span>
                          </button>
                        )}

                        {status_string !== "Revisi" && (
                          <button
                            onClick={() => navigate(`${path}?status=view`)}
                            className="bg-[#00BD52] text-white py-2 px-4 rounded-lg w-full font-semibold text-[12px]"
                          >
                            Lihat
                          </button>
                        )}

                        {/* <button
                          onClick={() => navigate(`${path}?status=view`)}
                          className="bg-[#00BD52] text-white py-2 px-4 rounded-lg w-full font-semibold text-[12px]"
                        >
                          Lihat
                        </button> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
