import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import AlertBlankData from 'components/molecules/Alert';
import { ConfirmationModal, HeaderLabel, StepProgress } from 'components';
import InformasiRekeningDanDaftarKepemilikan from './informasiDaftarKepemilikan';
import InformasiKeuanganLembaga from './informasiKeuanganLembaga';
import InformasiKeuanganPajak from './Pajak';
import ProcurmentPage from './procurment';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { updateFinancialDraftData, updateCompletedStatus } from 'app/actions';
import { isEqual } from 'lodash';
import { ToastContext } from 'components/atoms/Toast';
import { useContext } from 'react';
import {
  getFinancialData,
  submitFinancialData,
} from 'services/danaProgram/financialServices';
import moment from 'moment';

const ProfileKeuangan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wrapperRef = useRef();
  const [activeStep, setActiveStep] = useState(1);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmBackModal, setConfirmBackModal] = useState(false);
  const [confirmDraftModal, setConfirmDraftModal] = useState(false);
  const { showToastMessage, showToastDescription } = useContext(ToastContext);
  const [financialDraftData, setFinancialDraftData] = useState();
  const draftDataOnRedux = useSelector(
    (state) => state?.financialDraftData?.data || {}
  );
  const draftDataLemtara = useSelector(
    (state) => state?.draftDataGeneralProfile?.data || {}
  );

  const userId = localStorage.getItem(`userId`);

  const mutation = useMutation({
    mutationFn: (params) => {
      return submitFinancialData(params);
    },
  });

  const fetchMutation = useMutation({
    mutationFn: async () => {
      try {
        const promise = await getFinancialData();
        const dataStatus = promise?.data?.data;
        setFinancialDraftData(dataStatus);
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
  });

  useEffect(() => {
    fetchMutation.mutate();
  }, [activeStep]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const consumeAPI = (data, status) => {
    let params;

    if (status === 'DRAFTED') {
      switch (activeStep) {
        case 1:
          params = {
            user_id: userId,
            status: status,
            current_step: activeStep,
            step_1: {
              account: {
                account_name: data?.account_name,
                account_number: data?.account_number,
                bank_name: data?.bank_name,
                branch_name: data?.branch_name,
                account_type: data?.account_type,
              },
              stock_ownership: { list: data?.list } || {
                list: [{}],
              },
            },
          };
          break;
        case 2:
          params = {
            user_id: userId,
            status: status,
            current_step: activeStep,
            step_2: {
              year_range: {
                start_year: moment(data?.yearRange).year() - 2,
                end_year: moment(data?.yearRange).year(),
              },
              financial_report: data?.financial_report,
            },
          };
          break;
        case 3:
          params = {
            user_id: userId,
            status: status,
            current_step: activeStep,
            step_3: {
              tax: {
                npwp_personal: data?.npwp_personal,
                npwp_company: data?.npwp_company?.id,
                npwp_address: data?.npwp_address,
                url_file_skt: data?.url_file_skt?.id,
                no_certificate_domicile: data?.no_certificate_domicile,
                expire_date: data?.expire_date,
                is_pkp: data?.is_pkp,
                url_file_pkp: data?.url_file_pkp?.id,
                register_date_pkp: data?.register_date_pkp,
                is_tax_activity: data?.is_tax_activity,
                url_file_tax_activity: data?.url_file_tax_activity?.id,
                is_tax_collectors: data?.is_tax_collectors,
                url_file_tax_collectors: data?.url_file_tax_collectors?.id,
                is_income_cut: data?.is_income_cut,
                tax_payment: data?.tax_payment.map((dat) => ({
                  is_loss: dat.is_loss,
                  year: dat.year,
                  no_payment_evidence: dat.no_payment_evidence,
                  tax_payment_evidence_date: dat.tax_payment_evidence_date,
                  tax_type: dat.tax_type,
                  url_file_payment_evidence: dat.url_file_payment_evidence?.id,
                })),
                ppn_report: [
                  {
                    month: data?.ppnMonthFirst,
                    year: data?.ppnYearFirst,
                    no_report: data?.ppnNumberFirstMonth,
                    no_description: data?.ppnDescFirstMonth,
                    url_file_report_evidence: data?.ppnFileFirstMonth?.id,
                  },
                  {
                    month: data?.ppnMonthSecond,
                    year: data?.ppnYearSecond,
                    no_report: data?.ppnNumberSecondMonth,
                    no_description: data?.ppnDescSecondMonth,
                    url_file_report_evidence: data?.ppnFileSecondMonth?.id,
                  },
                  {
                    month: data?.ppnMonthThird,
                    year: data?.ppnYearThird,
                    no_report: data?.ppnNumberThirdMonth,
                    no_description: data?.ppnDescThirdMonth,
                    url_file_report_evidence: data?.ppnFileThirdMonth?.id,
                  },
                ],
                pph_report: [
                  {
                    month: data?.pphMonthFirst,
                    year: data?.pphYearFirst,
                    no_report: data?.pphNumberFirstMonth,
                    no_description: data?.pphDescFirstMonth,
                    url_file_report_evidence: data?.pphFileFirstMonth?.id,
                  },
                  {
                    month: data?.pphMonthSecond,
                    year: data?.pphYearSecond,
                    no_report: data?.pphNumberSecondMonth,
                    no_description: data?.pphDescSecondMonth,
                    url_file_report_evidence: data?.pphFileSecondMonth?.id,
                  },
                  {
                    month: data?.pphMonthThird,
                    year: data?.pphYearThird,
                    no_report: data?.pphNumberThirdMonth,
                    no_description: data?.pphDescThirdMonth,
                    url_file_report_evidence: data?.pphFileThirdMonth?.id,
                  },
                ],
                spt_report: [
                  {
                    year: data?.firstSptYear,
                    no_spt_annual_report: data?.firstSptNumber,
                    report_date: data?.firstSptDate,
                    description: data?.firstSptDesc,
                    url_file_spt_report: data?.firstSptFile?.id,
                  },
                  {
                    year: data?.secondSptYear,
                    no_spt_annual_report: data?.secondSptNumber,
                    report_date: data?.secondSptDate,
                    description: data?.secondSptDesc,
                    url_file_spt_report: data?.secondSptFile?.id,
                  },
                ],
              },

              document_support: {
                url_file_other_document: data?.document_support,
              },
            },
          };
          break;
        case 4:
          params = {
            user_id: userId,
            status: status,
            current_step: activeStep,
            step_4: {
              institutional_experience: {
                is_running_mechanism_procurement:
                  data?.is_running_mechanism_procurement,
                is_any_sop: data?.is_running_mechanism_procurement,
                url_file_sop: data?.url_file_sop?.id,
                description_sop: data?.description_sop,
                formulate_strategic_plan: data?.formulate_strategic_plan,
                custom_option_minimum_value_procurement:
                  data?.custom_option_minimum_value_procurement,
                minimum_value_procurement:
                  data?.minimum_value_procurement === '' ||
                  data?.minimum_value_procurement === null
                    ? data?.custom_option_minimum_value_procurement
                    : data?.minimum_value_procurement,
                value_implementation_procurement:
                  data?.value_implementation_procurement,
                url_file_support_document: data?.url_file_support_document,
                structure_member: data?.structure_member,
                procurement_experience: data?.procurement_experience,
                vat_report: [
                  {
                    month: data?.data,
                  },
                ],
              },
              document_support: {
                url_file_cooperative_contract:
                  data?.url_file_cooperative_contract?.id,
                description_cooperative_contract:
                  data?.description_cooperative_contract,
                url_file_completion_report:
                  data?.url_file_completion_report?.id,
                description_completion_report:
                  data?.description_completion_report,
              },
            },
          };
          break;
        default:
          break;
      }
    } else {
      params = {
        user_id: userId,
        status: status,
        current_step: activeStep,
        step_1: {
          account: {
            account_name: draftDataOnRedux?.account_information?.account_name,
            account_number:
              draftDataOnRedux?.account_information?.account_number,
            bank_name: draftDataOnRedux?.account_information?.bank_name,
            branch_name: draftDataOnRedux?.account_information?.branch_name,
            account_type: draftDataOnRedux?.account_information?.account_type,
          },
          stock_ownership: {
            list: draftDataOnRedux?.account_information?.list,
          } || {
            list: [{}],
          },
        },
        step_2: {
          year_range: {
            start_year:
              moment(
                draftDataOnRedux?.financial_information?.year_range
              ).year() - 2,
            end_year: moment(
              draftDataOnRedux?.financial_information?.year_range
            ).year(),
          },

          financial_report:
            draftDataOnRedux?.financial_information?.financial_report,
        },
        step_3: {
          tax: {
            npwp_personal: draftDataOnRedux?.tax?.npwp_personal,
            npwp_company: draftDataOnRedux?.tax?.npwp_company?.id,
            npwp_address: draftDataOnRedux?.tax?.npwp_address,
            url_file_skt: draftDataOnRedux?.tax?.url_file_skt?.id,
            no_certificate_domicile:
              draftDataOnRedux?.tax?.no_certificate_domicile,
            expire_date: draftDataOnRedux?.tax?.expire_date,
            is_pkp: draftDataOnRedux?.tax?.is_pkp,
            url_file_pkp: draftDataOnRedux?.tax?.url_file_pkp?.id,
            register_date_pkp: draftDataOnRedux?.tax?.register_date_pkp,
            is_tax_activity: draftDataOnRedux?.tax?.is_tax_activity,
            url_file_tax_activity:
              draftDataOnRedux?.tax?.url_file_tax_activity?.id,
            is_tax_collectors: draftDataOnRedux?.tax?.is_tax_collectors,
            is_income_cut: draftDataOnRedux?.tax?.is_income_cut,
            tax_payment: draftDataOnRedux?.tax?.tax_payment.map((dat) => ({
              is_loss: dat.is_loss,
              year: dat.year,
              no_payment_evidence: dat.no_payment_evidence,
              tax_payment_evidence_date: dat.tax_payment_evidence_date,
              tax_type: dat.tax_type,
              url_file_payment_evidence: dat.url_file_payment_evidence?.id,
            })),
            ppn_report: [
              {
                month: draftDataOnRedux?.tax?.ppnMonthFirst,
                year: draftDataOnRedux?.tax?.ppnYearFirst,
                no_report: draftDataOnRedux?.tax?.ppnNumberFirstMonth,
                no_description: draftDataOnRedux?.tax?.ppnDescFirstMonth,
                url_file_report_evidence:
                  draftDataOnRedux?.tax?.ppnFileFirstMonth?.id,
              },
              {
                month: draftDataOnRedux?.tax?.ppnMonthSecond,
                year: draftDataOnRedux?.tax?.ppnYearSecond,
                no_report: draftDataOnRedux?.tax?.ppnNumberSecondMonth,
                no_description: draftDataOnRedux?.tax?.ppnDescSecondMonth,
                url_file_report_evidence:
                  draftDataOnRedux?.tax?.ppnFileSecondMonth?.id,
              },
              {
                month: draftDataOnRedux?.tax?.ppnMonthThird,
                year: draftDataOnRedux?.tax?.ppnYearThird,
                no_report: draftDataOnRedux?.tax?.ppnNumberThirdMonth,
                no_description: draftDataOnRedux?.tax?.ppnDescThirdMonth,
                url_file_report_evidence:
                  draftDataOnRedux?.tax?.ppnFileThirdMonth?.id,
              },
            ],
            pph_report: [
              {
                month: draftDataOnRedux?.tax?.pphMonthFirst,
                year: draftDataOnRedux?.tax?.pphYearFirst,
                no_report: draftDataOnRedux?.tax?.pphNumberFirstMonth,
                no_description: draftDataOnRedux?.tax?.pphDescFirstMonth,
                url_file_report_evidence:
                  draftDataOnRedux?.tax?.pphFileFirstMonth?.id,
              },
              {
                month: draftDataOnRedux?.tax?.pphMonthSecond,
                year: draftDataOnRedux?.tax?.pphYearSecond,
                no_report: draftDataOnRedux?.tax?.pphNumberSecondMonth,
                no_description: draftDataOnRedux?.tax?.pphDescSecondMonth,
                url_file_report_evidence:
                  draftDataOnRedux?.tax?.pphFileSecondMonth?.id,
              },
              {
                month: draftDataOnRedux?.tax?.pphMonthThird,
                year: draftDataOnRedux?.tax?.pphYearThird,
                no_report: draftDataOnRedux?.tax?.pphNumberThirdMonth,
                no_description: draftDataOnRedux?.tax?.pphDescThirdMonth,
                url_file_report_evidence:
                  draftDataOnRedux?.tax?.pphFileThirdMonth?.id,
              },
            ],
            spt_report: [
              {
                year: draftDataOnRedux?.tax?.firstSptYear,
                no_spt_annual_report: draftDataOnRedux?.tax?.firstSptNumber,
                report_date: draftDataOnRedux?.tax?.firstSptDate,
                description: draftDataOnRedux?.tax?.firstSptDesc,
                url_file_spt_report: draftDataOnRedux?.tax?.firstSptFile?.id,
              },
              {
                year: draftDataOnRedux?.tax?.secondSptYear,
                no_spt_annual_report: draftDataOnRedux?.tax?.secondSptNumber,
                report_date: draftDataOnRedux?.tax?.secondSptDate,
                description: draftDataOnRedux?.tax?.secondSptDesc,
                url_file_spt_report: draftDataOnRedux?.tax?.secondSptFile?.id,
              },
            ],
          },

          document_support: {
            url_file_other_document: draftDataOnRedux?.tax?.document_support,
          },
        },
        step_4: {
          institutional_experience: {
            is_running_mechanism_procurement:
              draftDataOnRedux?.procurement?.is_running_mechanism_procurement,
            is_any_sop:
              draftDataOnRedux?.procurement?.is_running_mechanism_procurement,
            url_file_sop: draftDataOnRedux?.procurement?.url_file_sop?.id,
            description_sop: draftDataOnRedux?.procurement?.description_sop,
            formulate_strategic_plan:
              draftDataOnRedux?.procurement?.formulate_strategic_plan,
            custom_option_minimum_value_procurement:
              draftDataOnRedux?.procurement
                ?.custom_option_minimum_value_procurement,
            minimum_value_procurement:
              draftDataOnRedux?.procurement?.minimum_value_procurement === ''
                ? draftDataOnRedux?.procurement
                    ?.custom_option_minimum_value_procurement
                : draftDataOnRedux?.procurement?.minimum_value_procurement,
            value_implementation_procurement:
              draftDataOnRedux?.procurement?.value_implementation_procurement,
            url_file_support_document:
              draftDataOnRedux?.procurement?.url_file_support_document,
            structure_member: draftDataOnRedux?.procurement?.structure_member,
            procurement_experience:
              draftDataOnRedux?.procurement?.procurement_experience,
            vat_report: draftDataOnRedux?.procurement?.vat_report,
          },
          document_support: {
            url_file_cooperative_contract:
              draftDataOnRedux?.procurement?.url_file_cooperative_contract?.id,
            description_cooperative_contract:
              draftDataOnRedux?.procurement?.description_cooperative_contract,
            url_file_completion_report:
              draftDataOnRedux?.procurement?.url_file_completion_report?.id,
            description_completion_report:
              draftDataOnRedux?.procurement?.description_completion_report,
          },
        },
      };
    }

    mutation.mutate(params);
  };

  const dispatchData = (data) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) =>
          value !== null &&
          value !== undefined &&
          value !== '' &&
          !key.includes('Document')
      )
    );

    if (activeStep === 1) {
      if (
        Object.keys(filteredData).length > 0 &&
        !isEqual(financialDraftData?.account_information, filteredData)
      ) {
        dispatch(
          updateFinancialDraftData({
            account_information: { ...filteredData },
          })
        );
      }
    } else if (activeStep === 2) {
      if (
        Object.keys(filteredData).length > 0 &&
        !isEqual(financialDraftData?.financial_information, filteredData)
      ) {
        dispatch(
          updateFinancialDraftData({
            financial_information: { ...filteredData },
          })
        );
      }
    } else if (activeStep === 3) {
      if (
        Object.keys(filteredData).length > 0 &&
        !isEqual(financialDraftData?.tax, filteredData)
      ) {
        dispatch(
          updateFinancialDraftData({
            tax: { ...filteredData },
          })
        );
      }
    } else if (activeStep === 4) {
      if (
        Object.keys(filteredData).length > 0 &&
        !isEqual(financialDraftData?.procurement, filteredData)
      ) {
        dispatch(
          updateFinancialDraftData({
            procurement: { ...filteredData },
          })
        );
      }
    }
  };

  const onDraftSubmit = (data) => {
    consumeAPI(data, 'DRAFTED');
    // dispatchData(data);
    setConfirmDraftModal(true);
  };

  const onBack = (data) => {
    consumeAPI(data, 'DRAFTED');
    // dispatchData(data);
    setConfirmBackModal(true);
  };

  const onSubmit = (data) => {
    consumeAPI(data, 'DRAFTED');
    dispatchData(data);

    wrapperRef.current.scrollIntoView({ behaviour: 'smooth' });

    if (activeStep < stepProgress.length) {
      setActiveStep(activeStep + 1);
    } else {
      dispatchData(data);
      setConfirmModal(true);
    }
  };

  const onSubmitConfirmModal = (data) => {
    consumeAPI(data, 'SUBMITTED');
    dispatch(
      updateCompletedStatus({ profil_keuangan: true }, () => {
        navigate('/penilaian-lemtara');
        showToastMessage(
          'Data Berhasil Disimpan!',
          'Data yang anda masukan telah berhasil disimpan.'
        );
      })
    );
  };

  const onSubmitConfirmBackModal = () => {
    if (activeStep === 1) {
      navigate(-1);
      showToastMessage(
        'Data Berhasil Disimpan!',
        'Data yang anda masukan telah berhasil disimpan.'
      );
    } else {
      setActiveStep(activeStep - 1);
    }
    setConfirmBackModal(false);
  };

  const onSubmitConfirmDraftModal = () => {
    navigate('/penilaian-lemtara');
    showToastMessage(
      'Data Berhasil Disimpan!',
      'Data yang anda masukan telah berhasil disimpan.'
    );
  };

  const breadCrumbs = [
    {
      label: 'Penilaian LEMTARA',
      path: '/penilaian-lemtara',
    },
    {
      label: 'Profil Keuangan',
      path: '/profile-keuangan',
    },
  ];

  const stepProgress = useMemo(() => [
    {
      label: 'informasi rekening dan daftar kepemilikan',
      step: 1,
    },
    {
      label: 'Informasi Keuangan Lembaga',
      step: 2,
    },
    {
      label: 'Pajak',
      step: 3,
    },
    {
      label: 'Procurement',
      step: 4,
    },
  ]);

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={onSubmitConfirmModal}
          onCancel={() => setConfirmBackModal(false)}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {createPortal(
        <ConfirmationModal
          open={confirmDraftModal}
          onCancel={() => setConfirmBackModal(false)}
          onClose={setConfirmDraftModal}
          onSubmit={() => onSubmitConfirmDraftModal()}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin menyimpan ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda menyimpan sebagai draft anda masih bisa melanjutkan.
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {createPortal(
        <ConfirmationModal
          open={confirmBackModal}
          onCancel={() => setConfirmBackModal(false)}
          onClose={setConfirmBackModal}
          onSubmit={() => onSubmitConfirmBackModal()}
          className="space-y-2 mb-4"
        >
          <div>
            <div className="text-lg font-[600]">
              Apakah anda yakin untuk kembali?
            </div>
            <div className="text-sm font-[400]">
              Setelah anda kembali semua data akan disimpan pada draft.
            </div>
          </div>
        </ConfirmationModal>,
        document.body
      )}

      <div
        className="w-full space-y-6 bg-white  rounded-3xl border p-10 mb-6"
        ref={wrapperRef}
      >
        <BreadCrumbs routes={breadCrumbs} />

        <div className="flex">
          <HeaderLabel
            text="Profil Keuangan"
            description="Silahkan daftar menggunakan data yang Anda miliki."
          />
        </div>

        <div className="border-b" />
        <div className="px-10">
          <StepProgress
            stepProgressData={stepProgress}
            activeStep={activeStep}
          />
        </div>

        <div className="w-full pt-4">
          <AlertBlankData
            title={`Lengkapi Data`}
            body="Silahkan isi semua data di bawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya."
          />
        </div>

        {activeStep === 1 && (
          <InformasiRekeningDanDaftarKepemilikan
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={financialDraftData?.step_1}
          />
        )}
        {activeStep === 2 && (
          <InformasiKeuanganLembaga
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={financialDraftData?.step_2}
            draftLemtara={
              draftDataLemtara?.general_information?.institution_type
            }
          />
        )}
        {activeStep === 3 && (
          <InformasiKeuanganPajak
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={financialDraftData?.step_3}
          />
        )}
        {activeStep === 4 && (
          <ProcurmentPage
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={financialDraftData?.step_4}
          />
        )}
      </div>
    </>
  );
};

export default ProfileKeuangan;
