import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import AtomSelect from "../../../atoms/Select";
import { getZipCodeFDBService } from "services/fdb/region";

let HAS_MORE = false;

const SelectFDBZipCode = React.forwardRef((props, ref) => {
  const { className, villageId, ...restProps } = props;

  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);

  useQuery({
    queryKey: ["zip-fdb", villageId, page],
    queryFn: async () => {
      // TODO: Adjust logic once actual api exist
      const res = await getZipCodeFDBService({
        [`filter[village]`]: villageId,
        page,
        limit: 100,
      });
      if (res?.data?.data?.meta?.next_page_url) HAS_MORE = true;
      else HAS_MORE = false;

      return res?.data?.data?.data || [];
    },
    enabled: !!villageId && !props.asText,
    retry: false,
    onSuccess: (data) => {
      setOptions((curr) => [...curr, ...data.map(x => ({ value: x.id, label: x.zip_code }))]);
    },
  });

  useEffect(() => {
    setOptions([]);
    setPage(1);
  }, [villageId]);

  const fetchMore = () => {
    if (HAS_MORE) {
      setPage((curr) => curr + 1);
    }
  }

  return (
    <AtomSelect
      options={options}
      className={["basic-single mt-2 flex-1", className].join(" ")}
      onMenuScrollToBottom={fetchMore}
      {...restProps}
    />
  );
});

export default SelectFDBZipCode;
