import angkaTerbilangJs from "@develoka/angka-terbilang-js";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  BottomFormAction,
  ConfirmationModal,
  InputForm,
  InputFormRadio,
} from "components";
import FileInfoField from "components/atoms/FileInfoField";
import AtomSelect from "components/atoms/Select";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import { startCase, toLower } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import {
  getOfferDetail,
  postOffer,
} from "services/fdb/offer-institution-offer";
import { validationScheme } from "./validation-scheme";
import { enqueueSnackbar } from "notistack";
import RichEditorForm from "components/molecules/RichEditorForm";
import InputCurrencyForm from "components/V2/shared/input-currency-form";
import { getFile } from "services/danaProgram/fileService";
import { useSearchParams } from "react-router-dom";

export default function OfferDetail(props) {
  const { type, group } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  const [confirmModal, setConfirmModal] = useState(false);

  const breadCrumbs = [
    {
      label: "Daftar Penawaran",
      path: `/penawaran/${group}/${type}`,
    },
    {
      label: "Detail",
      path: `/penawaran/${group}/${type}/detail/${id}`,
    },
  ];

  const onBack = () => {
    navigate(-1);
  };

  const onFormSubmit = () => {
    setConfirmModal(true);
  };

  const queryClient = useQueryClient();
  const mutatePostOffer = useMutation({ mutationFn: postOffer });
  const { data } = useQuery(["GET_DETAIL_OFFER", id], getOfferDetail, {
    select: (data) => data?.data?.data,
  });

  const methods = useForm({
    resolver: yupResolver(validationScheme(data?.structure?.offered)),
    mode: "onChange",
  });

  const isNegotiationField = useWatch({
    control: methods.control,
    name: "is_negotiation",
  });

  const isNegotiationFinancing = useWatch({
    control: methods.control,
    name: "is_negotiation_financing",
  });

  const onSubmit = () => {
    const data = methods.getValues();

    const mapData = {
      ...data,
      mandatory: data?.mandatory?.split(","),
    };

    mutatePostOffer.mutate(
      { id, data: mapData, params: { approval: "negotiation" } },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Berhasil Submit Penawaran",
          });
          queryClient.invalidateQueries();
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Gagal Submit Penawaran",
          });
        },
      }
    );
  };

  const [offerFile, setOfferFile] = useState();

  const handleGetOfferFile = useCallback(async () => {
    if (!data?.structure?.file) return;

    const response = await getFile(data?.structure?.file);

    setOfferFile(response?.data?.data);
  }, [data?.structure?.file]);

  useEffect(() => {
    handleGetOfferFile();
  }, [handleGetOfferFile]);

  useEffect(() => {
    if (data) {
      methods.reset({
        ...data?.structure?.filed,
        ...data?.condition?.filed,
        is_negotiation_financing: data?.structure?.is_negotiation_financing,
        is_negotiation: data?.condition?.is_negotiation,
        fdb_value_spell: data?.structure?.filed?.fdb_value
          ? startCase(
            toLower(angkaTerbilangJs(data?.structure?.filed?.fdb_value))
          )
          : null,
      });
    }
  }, [data, methods]);

  const [searchParams] = useSearchParams();

  const isView = searchParams.get("status") !== "Menunggu Keputusan";

  return (
    <div>
      <ConfirmationModal
        open={confirmModal}
        onClose={setConfirmModal}
        onSubmit={onSubmit}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan submit semua data ini?
        </div>
        <div className="text-sm font-[400]">
          Setelah anda submit anda masih bisa mengubah data.
        </div>
      </ConfirmationModal>

      <div className="w-full">
        <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-36 z-[99]">
          <BreadCrumbs routes={breadCrumbs} />
          <div className="border-b" />

          <FormProvider {...methods}>
            <div className="space-y-10">
              <CardForm label="Permohonan Pembiayaan Lembaga Penyalur">
                <div className="space-y-6">
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">
                          Nilai Permohonan
                        </p>
                        <p className="text-sm">{`Rp. ${Math.floor(
                          data?.application?.value
                        ).toLocaleString()}`}</p>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">Terbilang</p>
                        <p className="text-sm">
                          {startCase(
                            toLower(angkaTerbilangJs(data?.application?.value))
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">Grace Period</p>
                        <p className="text-sm">
                          {data?.application?.grace_period ?? "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </CardForm>
              <CardForm label="Struktur Pembiayaan Ditawarkan">
                <div className="space-y-6">
                  <FileInfoField
                    title={"Surat Penawaran"}
                    showIcon
                    showDownload={true}
                    showDelete={true}
                    onDownloadClick={() => {
                      window.open(offerFile?.url, "_blank");
                    }}
                  />

                  <p className="text-lg text-white font-semibold w-full px-4 py-3 bg-primary-600 rounded-sm">
                    Struktur Pembiayaan Ditawarkan
                  </p>

                  <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                    <div className="col-span-1">
                      <p className="font-bold mb-2 text-sm">Nilai Permohonan</p>
                      <p className="text-sm">{`Rp. ${Math.floor(
                        data?.structure?.offered?.fdb_value
                      ).toLocaleString()}`}</p>
                    </div>
                    <div className="col-span-1">
                      <p className="font-bold mb-2 text-sm">Terbilang</p>
                      <p className="text-sm">
                        {startCase(
                          toLower(
                            angkaTerbilangJs(
                              data?.structure?.offered?.fdb_value
                            )
                          )
                        )}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="font-bold mb-2 text-sm">Jangka Waktu</p>
                      <p className="text-sm">{`${data?.structure?.offered?.term_value} ${data?.structure?.offered?.term_type}`}</p>
                    </div>
                    <div className="col-span-1">
                      <p className="font-bold mb-2 text-sm">Grace Period</p>
                      <p className="text-sm">
                        {data?.structure?.offered?.grace_period_term_value &&
                          data?.structure?.offered?.grace_period_term_type
                          ? `${data?.structure?.offered?.grace_period_term_value} ${data?.structure?.offered?.grace_period_term_type}`
                          : "-"}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="font-bold mb-2 text-sm">Diperpanjang</p>
                      <p className="text-sm">
                        {data?.structure?.offered?.extend_value &&
                          data?.structure?.offered?.extend_type
                          ? `${data?.structure?.offered?.extend_value} ${data?.structure?.offered?.extend_type}`
                          : "-"}
                      </p>
                    </div>
                  </div>

                  <p className="text-md text-primary-700 font-semibold">
                    Tarif Layanan
                  </p>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="col-span-1">
                      <p className="font-bold mb-2 text-sm">
                        Persentase Pertahun
                      </p>
                      <p className="text-sm">{`${data?.structure?.offered?.percentage_service_rate_per_year}%`}</p>
                    </div>
                    <div className="col-span-1">
                      <p className="font-bold mb-2 text-sm">
                        Mode Pemberlakuan
                      </p>
                      <p className="text-sm">
                        {
                          data?.structure?.offered
                            ?.service_rates_application_method
                        }
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="font-bold mb-2 text-sm">
                        Metode Perhitungan Tarif
                      </p>
                      <p className="text-sm">
                        {
                          data?.structure?.offered
                            ?.service_rate_calculation_method
                        }
                      </p>
                    </div>
                  </div>

                  <p className="text-md text-primary-700 font-semibold">
                    Tahap Penyaluran
                  </p>

                  {data?.structure?.offered?.distribution_stage === "once" && (
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex flex-col">
                        <p className="font-bold mb-2 text-sm">
                          Tahapan Penyaluran
                        </p>
                        <p className="text-sm">Sekaligus</p>
                      </div>

                      <div className="flex flex-col">
                        <p className="font-bold mb-2 text-sm">
                          Nilai Penyaluran
                        </p>
                        <p className="text-sm">
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            maximumFractionDigits: 0,
                          }).format(
                            data?.structure?.offered?.distribution[0]?.value
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                  {data?.structure?.offered?.distribution_stage !== "once" && (
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                      <div className="col-span-1">
                        <p className="font-bold mb-2 text-sm">
                          Tahapan Penyaluran
                        </p>
                        <p className="text-sm">Bertahap</p>
                      </div>
                      <div className="col-span-1">
                        <p className="font-bold mb-2 text-sm">
                          Tahap Penyaluran
                        </p>
                        <p className="text-sm">
                          {data?.structure?.offered?.distribution?.length} Tahap
                        </p>
                      </div>
                      {data?.structure?.offered?.distribution?.map(
                        (el, idx) => (
                          <div className="col-span-1">
                            <p className="font-bold mb-2 text-sm">
                              Nilai Penyaluran Tahap {idx + 1}
                            </p>
                            <p className="text-sm">
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                maximumFractionDigits: 0,
                              }).format(el?.value)}
                            </p>
                          </div>
                        )
                      )}
                      {/* <div className="col-span-1">
                        <p className="font-bold mb-2 text-sm">
                          Nilai Penyaluran Tahap 1
                        </p>
                        <p className="text-sm">Rp. 50.000.000</p>
                      </div>
                      <div className="col-span-1">
                        <p className="font-bold mb-2 text-sm">
                          Nilai Penyaluran Tahap 2
                        </p>
                        <p className="text-sm">Rp. 50.000.000</p>
                      </div> */}
                    </div>
                  )}
                </div>

                <InputFormRadio
                  label="Ingin Menegosiasi Struktur Pembiayaan?"
                  values={[
                    { label: "Ya", value: true },
                    { label: "Tidak", value: false },
                  ]}
                  controllerName={"is_negotiation_financing"}
                  required
                />

                {isNegotiationFinancing && (
                  <>
                    <p className="text-lg text-white font-semibold w-full px-4 py-3 bg-primary-600 rounded-sm">
                      Struktur Pembiayaan Diajukan
                    </p>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="col-span-1 flex flex-col gap-2">
                        <InputCurrencyForm
                          name="fdb_value"
                          wrapperClass={"col-span-1"}
                          label="Jumlah FDB"
                          placeholder="Jumlah FDB"
                          required
                          prefix="Rp "
                          onValueChange={(e) => {
                            if (e) {
                              methods.setValue(
                                "fdb_value_spell",
                                startCase(toLower(angkaTerbilangJs(e)))
                              );
                            } else {
                              methods.setValue("fdb_value_spell", ""); // Clear the value if input is null
                            }
                          }}
                        />
                        <p className="text-sm">
                          Pastikan Jumlah FDB Diajukan DIbawah Nilai FDB Yang
                          Ditawarkan
                        </p>
                      </div>
                      <div className="col-span-1 flex flex-col gap-2">
                        <InputForm
                          label="Terbilang"
                          controllerName={"fdb_value_spell"}
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          required
                          disabled
                        />
                      </div>
                    </div>

                    <p className="text-md text-primary-700 font-semibold">
                      Jangka Waktu
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="col-span-1 flex flex-col gap-2">
                        <InputForm
                          label="Jangka Waktu"
                          controllerName={"term_value"}
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          required
                        />
                        <p className="text-sm">
                          Pastikan Jangka Waktu Diajukan DIbawah Jangka Waktu
                          Ditawarkan
                        </p>
                      </div>
                      <div className="col-span-1 flex flex-col gap-2">
                        <AtomSelect
                          controllerName={"term_type"}
                          label="Satuan Waktu"
                          wrapperClass="flex flex-col gap-2"
                          options={[
                            { label: "Tahun", value: "Tahun" },
                            { label: "Bulan", value: "Bulan" },
                          ]}
                          required
                          returnObject={false}
                        />
                        <p className="text-sm">
                          Pastikan Satuan Waktu Diajukan DIbawah/Sama Satuan
                          Waktu Ditawarkan
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="col-span-1 flex flex-col gap-2">
                        <InputForm
                          label="Jangka Waktu Grace Period"
                          controllerName={"grace_period_term_value"}
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          required
                          disabled={
                            !data?.structure?.offered?.grace_period_term_value
                          }
                        />
                        <p className="text-sm">
                          Pastikan Grace Period Diajukan DIbawah Grace Period
                          Ditawarkan
                        </p>
                      </div>
                      <div className="col-span-1 flex flex-col gap-2">
                        <AtomSelect
                          controllerName={"grace_period_term_type"}
                          label="Satuan Grace"
                          wrapperClass="flex flex-col gap-2"
                          options={[
                            { label: "Tahun", value: "Tahun" },
                            { label: "Bulan", value: "Bulan" },
                          ]}
                          required
                          returnObject={false}
                          disable={
                            !data?.structure?.offered?.grace_period_term_type
                          }
                        />
                        <p className="text-sm">
                          Pastikan Satuan Grace Diajukan DIbawah/Sama Satuan
                          Grace Ditawarkan
                        </p>
                      </div>
                    </div>
                    {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="col-span-1 flex flex-col gap-2">
                        <InputForm
                          label="Waktu Perpanjangan"
                          controllerName={"extend_value"}
                          className={
                            "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                          }
                          required
                        />
                        <p className="text-sm">
                          Pastikan Waktu Perpanjangan Diajukan DIbawah Waktu
                          Perpanjangan Ditawarkan
                        </p>
                      </div>
                      <div className="col-span-1 flex flex-col gap-2">
                        <AtomSelect
                          controllerName={"extend_type"}
                          label="Satuan Waktu Perpanjangan"
                          wrapperClass="flex flex-col gap-2"
                          options={[
                            { label: "Tahun", value: "Tahun" },
                            { label: "Bulan", value: "Bulan" },
                          ]}
                          required
                          returnObject={false}
                        />
                        <p className="text-sm">
                          Pastikan Satuan Waktu Perpanjangan Diajukan
                          DIbawah/Sama Satuan Waktu Perpanjangan Ditawarkan
                        </p>
                      </div>
                    </div> */}

                    <InputFormRadio
                      label="Jangka Waktu Dapat Diperpanjang Sesuai Dengan Kesepakatan Dua Belah Pihak?"
                      values={[
                        { label: "Ya", value: true },
                        { label: "Tidak", value: false },
                      ]}
                      controllerName={"agreed_by_both"}
                      required
                    />
                  </>
                )}
              </CardForm>

              <CardForm label="Kondisi Pembiayaan Ditawarkan">
                <div className="space-y-6">
                  <p className="text-lg text-white font-semibold w-full px-4 py-3 bg-primary-600 rounded-sm">
                    Struktur Pembiayaan Diajukan
                  </p>
                  <div className="grid grid-cols-1 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">
                          Penggunaan/ Tujuan FDB
                        </p>
                        <p
                          className="text-sm"
                          dangerouslySetInnerHTML={{
                            __html:
                              data?.condition?.offered?.disbursement_purpose,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">
                          Perjanjian Mitra Penyaluran
                        </p>
                        <p
                          className="text-sm"
                          dangerouslySetInnerHTML={{
                            __html: data?.condition?.offered?.agreement,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">Pencairan</p>
                        <p
                          className="text-sm"
                          dangerouslySetInnerHTML={{
                            __html: data?.condition?.offered?.disbursement,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">
                          Hak Dan Tanggung Jawab LP
                        </p>
                        {data?.condition?.offered?.mandatory?.map((el) => (
                          <ul className="flex gap-2 items-center pl-4">
                            <li className="text-sm list-disc">
                              {el?.mandatory}
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">
                          Pembayaran Angsuran
                        </p>
                        <p
                          className="text-sm"
                          dangerouslySetInnerHTML={{
                            __html: data?.condition?.offered?.installment,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>

                  <InputFormRadio
                    values={[
                      {
                        label: "Ya",
                        value: true,
                      },
                      {
                        label: "Tidak",
                        value: false,
                      },
                    ]}
                    label={"Ingin Menegosiasi Kondisi Pembiayaan"}
                    controllerName={"is_negotiation"}
                    required
                  />

                  {isNegotiationField && (
                    <>
                      <p className="text-lg text-white font-semibold w-full px-4 py-3 bg-primary-600 rounded-sm">
                        Kondisi Pembiayaan Diajukan
                      </p>

                      <div className="flex flex-col gap-2">
                        {/* <InputForm
                          label="Penggunaan/ Tujuan FDB"
                          controllerName={"disbursement_purpose"}
                          className={
                            "py-2 px-4 mt-2 w-full flex-1 border rounded-md"
                          }
                          required
                          textArea
                        /> */}
                        <RichEditorForm
                          name="disbursement_purpose"
                          label="Penggunaan/ Tujuan FDB"
                          required
                        />
                        <p className="text-sm">
                          Berikan Catatan Anda Terkait Penggunaan/ Tujuan FDB
                        </p>
                      </div>
                      <div className="flex flex-col gap-2">
                        {/* <InputForm
                          label="Perjanjian Mitra Penyaluran"
                          controllerName={"agreement"}
                          className={
                            "py-2 px-4 mt-2 w-full flex-1 border rounded-md"
                          }
                          required
                          textArea
                        /> */}
                        <RichEditorForm
                          name="agreement"
                          label="Perjanjian Mitra Penyaluran"
                          required
                        />
                        <p className="text-sm">
                          Berikan Catatan Anda Terkait Mitra Penyaluran
                        </p>
                      </div>

                      <div className="flex flex-col gap-2">
                        {/* <InputForm
                          label="Pencairan"
                          controllerName={"disbursement"}
                          className={
                            "py-2 px-4 mt-2 w-full flex-1 border rounded-md"
                          }
                          required
                          textArea
                        /> */}
                        <RichEditorForm
                          name="disbursement"
                          label="Pencairan"
                          required
                        />
                        <p className="text-sm">
                          Berikan Catatan Anda Terkait Pencairan
                        </p>
                      </div>
                      <div className="flex flex-col gap-2">
                        {/* <InputForm
                          label="Hak Dan Tanggung Jawab LP"
                          controllerName={"mandatory"}
                          className={
                            "py-2 px-4 mt-2 w-full flex-1 border rounded-md"
                          }
                          required
                          textArea
                        /> */}
                        <RichEditorForm
                          name="mandatory"
                          label="Hak Dan Tanggung Jawab LP"
                          required
                        />
                        <p className="text-sm">
                          Berikan Catatan Anda Terkait Hak Dan Tanggung Jawab LP
                        </p>
                      </div>
                      <div className="flex flex-col gap-2">
                        {/* <InputForm
                          label="Pembayaran Angsuran"
                          controllerName={"installment"}
                          className={
                            "py-2 px-4 mt-2 w-full flex-1 border rounded-md"
                          }
                          required
                          textArea
                        /> */}
                        <RichEditorForm
                          name="installment"
                          label="Pembayaran Angsuran"
                          required
                        />
                        <p className="text-sm">
                          Berikan Catatan Anda Terkait Pembayaran Angsuran
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </CardForm>

              <CardForm label="Lampiran Penawaran Kerjasama">
                <div className="space-y-6">
                  <div className="grid grid-cols-1 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">
                          Syarat penandatanganan perjanjian FDB
                        </p>
                        <p
                          className="text-sm"
                          dangerouslySetInnerHTML={{
                            __html: data?.offer?.fdb_syara_agreement,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">
                          Ketentuan Kerja Sama
                        </p>
                        <p className="text-sm">
                          {data?.offer?.cooperation_terms?.map((el) => (
                            <ul className="pl-3">
                              <li className="list-decimal">{el?.value}</li>
                              {el?.sub?.length > 0 &&
                                el?.sub?.map((sub) => (
                                  <ul className="pl-3">
                                    <li className="list-decimal">
                                      {sub?.value}
                                    </li>
                                    {sub?.sub?.length > 0 &&
                                      sub.sub?.map((subsub) => (
                                        <ul className="pl-3">
                                          <li className="list-decimal">
                                            {subsub?.value}
                                          </li>
                                        </ul>
                                      ))}
                                  </ul>
                                ))}
                            </ul>
                          ))}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">
                          Ketentuan pencairan dan pengembalian FDB
                        </p>
                        <p
                          className="text-sm"
                          dangerouslySetInnerHTML={{
                            __html: data?.offer?.disbursement_terms,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">Laporan</p>
                        <p className="text-sm">
                          {data?.offer?.report?.map((el) => (
                            <ul className="pl-3">
                              <li className="list-decimal">{el?.value}</li>
                              {el?.sub?.length > 0 &&
                                el.sub?.map((sub) => (
                                  <ul className="pl-3">
                                    <li className="list-decimal">
                                      {sub?.value}
                                    </li>
                                    {sub?.sub?.length > 0 &&
                                      sub?.sub?.map((subsub) => (
                                        <ul className="pl-3">
                                          <li className="list-decimal">
                                            {subsub?.value}
                                          </li>
                                        </ul>
                                      ))}
                                  </ul>
                                ))}
                            </ul>
                          ))}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">
                          Ketentuan monitoring dan evaluasi
                        </p>
                        <p
                          className="text-sm"
                          dangerouslySetInnerHTML={{
                            __html: data?.offer?.monitoring_terms,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">
                          Ketentuan denda dan wanprestasi
                        </p>
                        <p
                          className="text-sm"
                          dangerouslySetInnerHTML={{
                            __html: data?.offer?.tax_terms,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">
                          Ketentuan berakhirnya perjanjian kerja sama penyaluran
                          FDB
                        </p>
                        <p
                          className="text-sm"
                          dangerouslySetInnerHTML={{
                            __html: data?.offer?.end_agreement_terms,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-4 mb-3">
                    <div className="col-span-1">
                      <div>
                        <p className="font-bold mb-2 text-sm">
                          Ketentuan penyelamatan dan penyelesaian FDB
                        </p>
                        <p
                          className="text-sm"
                          dangerouslySetInnerHTML={{
                            __html: data?.offer?.settlement_terms,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </CardForm>
            </div>

            <BottomFormAction
              // lastStep={true}
              backButtonProps={{
                type: "button",
              }}
              // draftButtonProps={{
              //   type: "button",
              // }}
              lastStep
              hideDraft
              hideSubmit={isView}
              submitButtonProps={{
                type: "submit",
              }}
              submitActionButton={methods.handleSubmit(onFormSubmit)}
              backButtonAction={onBack}
            />
          </FormProvider>
        </div>
      </div>
    </div>
  );
}
