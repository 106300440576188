import { Button, InputForm } from "components";
import InputCurrency from "components/organisms/inputs/InputCurrency";
import { formatCurrency, numberToCurrency } from "helpers";
import React, { useEffect, useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { RiAddLine, RiDeleteBinLine } from "react-icons/ri";

const TotalColumnTable = ({ nestedIndex, index, errors }) => {
  const { setValue, watch } = useFormContext();

  const convertMoney = (value) => {
    let oriValue = String(value);
    oriValue = oriValue.includes(".") ? oriValue.split(".").join("") : oriValue;
    let modifValue = "";
    let count = 1;
    if (oriValue !== "" && oriValue.length > 3) {
      const splitValue = oriValue.split("");
      for (let i = splitValue.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0 && i !== 0) {
          modifValue = `.${splitValue[i]}${modifValue}`;
        } else {
          modifValue = `${splitValue[i]}${modifValue}`;
        }
        count += 1;
      }
    } else {
      modifValue = oriValue;
    }
    return modifValue;
  };

  const {
    [nestedIndex]: {
      items: {
        [index]: { qty, price, volume },
      },
    },
  } = useWatch({
    name: `activities`,
  });

  useEffect(() => {
    let priceFormat = price?.replace(/\D/g, "");
    let formattedValue =
      priceFormat && qty
        ? convertMoney(
            String(
              parseInt(qty || 0) *
                parseInt(volume || 0) *
                parseInt(priceFormat || 0)
            )
          )
        : 0;

    setValue(
      `activities[${nestedIndex}].items[${index}].total`,
      formattedValue
    );
    let total = watch(`activities[${nestedIndex}].items`)?.reduce(
      (acc, curr) => {
        let priceFormat = curr.price?.replace(/\D/g, "");

        return (
          acc +
          parseInt(curr.qty || 0) *
            parseInt(curr.volume || 0) *
            parseInt(priceFormat || 0)
        );
      },
      0
    );

    setValue(`activities[${nestedIndex}].subTotal`, total);
  }, [qty, volume, price]);

  return (
    <div className="space-y-1">
      <InputForm
        controllerName={`activities[${nestedIndex}].items[${index}].total`}
        className={`border w-full rounded-md flex-1 text-sm`}
        showErrorLabel={false}
        showPrefix={true}
        prefix={"Rp. "}
        disabled
      />
      <div className="text-xs text-error-600 mt-1 label-text-alt text-error text-[#F04438]">
        {errors?.activities?.[nestedIndex]?.items?.[index]?.total?.message}
      </div>
    </div>
  );
};

const PriceColumnTable = ({ nestedIndex, index, errors, control }) => {
  const { setValue } = useFormContext();
  const {
    [nestedIndex]: {
      items: {
        [index]: { price },
      },
    },
  } = useWatch({
    name: "activities",
  });

  const convertMoney = (value) => {
    let oriValue = String(value);
    oriValue = oriValue.includes(".") ? oriValue.split(".").join("") : oriValue;
    let modifValue = "";
    let count = 1;
    if (oriValue !== "" && oriValue.length > 3) {
      const splitValue = oriValue.split("");
      for (let i = splitValue.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0 && i !== 0) {
          modifValue = `.${splitValue[i]}${modifValue}`;
        } else {
          modifValue = `${splitValue[i]}${modifValue}`;
        }
        count += 1;
      }
    } else {
      modifValue = oriValue;
    }
    return modifValue;
  };

  return (
    <div className="space-y-1">
      <InputForm
        controllerName={`activities.${nestedIndex}.items.${index}.price`}
        className={`border w-full rounded-md flex-1 text-sm`}
        showErrorLabel={false}
        showPrefix={true}
        prefix={"Rp. "}
        forceNumber
        onChangeInput={(e) => {
          setValue(
            `activities.${nestedIndex}.items.${index}.price`,
            convertMoney(e.target.value)
          );
        }}
      />
      <div className="text-xs text-error-600 mt-1 label-text-alt text-error text-[#F04438]">
        {errors?.activities?.[nestedIndex]?.items?.[index]?.price?.message}
      </div>
    </div>
  );
};

const TableForm = ({ nestedIndex, control }) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext();
  const {
    fields: itemFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: `activities[${nestedIndex}].items`,
  });

  return (
    <table
      className="w-full bg-white border border-gray-200"
      style={{ boxShadow: "0px 20px 40px 0px rgba(51, 40, 123, 0.04)" }}
    >
      <thead className="bg-primary-800 text-white text-sm">
        <tr>
          <td className="px-6 py-3">No</td>
          <td className="px-6 py-3">Nama Item</td>
          <td className="px-6 py-3">QTY</td>
          <td className="px-6 py-3">Volume</td>
          <td className="px-6 py-3">Satuan/Unit</td>
          <td className="px-6 py-3">Harga Satuan</td>
          <td className="px-6 py-3">Sub Total</td>
          <td className="px-6 py-3"></td>
        </tr>
      </thead>
      <tbody>
        {itemFields.map((field, key) => {
          return (
            <tr key={field.id} className="bg-gray-50">
              <td className="px-6 py-2">{key + 1}</td>
              <td className="px-6 py-2 align-top">
                <div className="space-y-1">
                  <InputForm
                    controllerName={`activities[${nestedIndex}].items.${key}.name`}
                    className={`border w-full rounded-md flex-1 text-sm`}
                    showErrorLabel={false}
                  />
                  <div className="text-xs text-error-600 mt-1 label-text-alt text-error text-[#F04438]">
                    {
                      errors?.activities?.[nestedIndex]?.items?.[key]?.name
                        ?.message
                    }
                  </div>
                </div>
              </td>
              <td className="px-6 py-2 align-top">
                <div className="space-y-1">
                  <InputForm
                    controllerName={`activities[${nestedIndex}].items.${key}.qty`}
                    className={`border w-full rounded-md flex-1 text-sm`}
                    showErrorLabel={false}
                    forceNumber
                  />
                  <div className="text-xs text-error-600 mt-1 label-text-alt text-error text-[#F04438]">
                    {
                      errors?.activities?.[nestedIndex]?.items?.[key]?.qty
                        ?.message
                    }
                  </div>
                </div>
              </td>
              <td className="px-6 py-2 align-top">
                <div className="space-y-1">
                  <InputForm
                    controllerName={`activities[${nestedIndex}].items.${key}.volume`}
                    className={`border w-full rounded-md flex-1 text-sm`}
                    showErrorLabel={false}
                    forceNumber
                  />
                  <div className="text-xs text-error-600 mt-1 label-text-alt text-error text-[#F04438]">
                    {
                      errors?.activities?.[nestedIndex]?.items?.[key]?.volume
                        ?.message
                    }
                  </div>
                </div>
              </td>
              <td className="px-6 py-2 align-top">
                <div className="space-y-1">
                  <InputForm
                    controllerName={`activities[${nestedIndex}].items.${key}.unit`}
                    className={`border w-full rounded-md flex-1 text-sm`}
                    showErrorLabel={false}
                  />
                  <div className="text-xs text-error-600 mt-1 label-text-alt text-error text-[#F04438]">
                    {
                      errors?.activities?.[nestedIndex]?.items?.[key]?.unit
                        ?.message
                    }
                  </div>
                </div>
              </td>
              <td className="px-6 py-2 align-top">
                <InputCurrency
                  controllerName={`activities[${nestedIndex}].items[${key}].price`}
                  showErrorLabel={false}
                  className={`border w-full rounded-md flex-1 text-sm !mt-0`}
                />
              </td>
              <td className="px-6 py-2 align-top">
                <TotalColumnTable
                  nestedIndex={nestedIndex}
                  index={key}
                  errors={errors}
                  control={control}
                />
              </td>
              <td className="px-6 py-2 align-top">
                {key > 0 && (
                  <Button
                    type="button"
                    className="rounded-lg bg-error-600 text-white shadow py-2 px-3.5 items-center font-semibold h-10"
                    label={<RiDeleteBinLine size={20} className="text-white" />}
                    onClick={() => removeItem(key)}
                  />
                )}
              </td>
            </tr>
          );
        })}
        <tr>
          <td colSpan={8} className="px-6 py-2 align-middle">
            <div className="flex items-center justify-between">
              <strong>
                Total:&nbsp;
                {formatCurrency(
                  watch(`activities[${nestedIndex}].subTotal`),
                  0
                )}
              </strong>
              <Button
                className="rounded-lg border border-gray-300 shadow py-2.5 px-4"
                label={
                  <div className="flex gap-2 items-center text-gray-700 text-sm">
                    <RiAddLine size={20} />
                    Item Kebutuhan
                  </div>
                }
                onClick={() => {
                  appendItem({
                    activity: "",
                  });
                }}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TableForm;
