import { yupResolver } from "@hookform/resolvers/yup"
import { useMutation, useQuery } from "@tanstack/react-query"
import { getErrorMessage } from "helpers"
import { isCitizenIdValid } from "helpers/validation"
import { validationErrorMsg } from "locale/yup_id"
import { enqueueSnackbar } from "notistack"
import { useEffect, useMemo } from "react"
import { useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { registerBeneficiaries } from "services/beneficiaries/register"
import { fileUpload } from "services/danaProgram/fileService"
import { getBankService } from "services/masterData/bank"
import { getCityService, getCountryService, getDistrictService, getPostCodeService, getProvinceService, getWardService } from "services/masterData/region"
import * as yup from "yup";

const useRegister = () => {
  const [temp, setTemp] = useState()
  const [confirmModal, setConfirmModal] = useState(false)
  const [countries, setCountries] = useState([])
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [districts, setDistricts] = useState([])
  const [wards, setWards] = useState([])
  const [zipCodes, setZipCodes] = useState([])
  const [bank, setBank] = useState([])

  const {
    data: countryData,
    isLoading: isLoadingCountryData,
    isFetching: isFetchingCountryData,
    refetch: refetchCountryData,
  } = useQueryCountry();

  const {
    data: provinceData,
    isLoading: isLoadingProvinceData,
    isFetching: isFetchingProvinceData,
    refetch: refetchProvinceData,
  } = useQueryProvince();

  const {
    data: cityData,
    isLoading: isLoadingCityData,
    isFetching: isFetchingCityData,
    refetch: refetchCityData,
  } = useQueryCity();

  const {
    data: districtData,
    isLoading: isLoadingDistrictData,
    isFetching: isFetchingDistrictData,
    refetch: refetchDistrictData,
  } = useQueryDistrict();

  const {
    data: wardData,
    isLoading: isLoadingWardData,
    isFetching: isFetchingWardData,
    refetch: refetchWardData,
  } = useQueryWard();

  const {
    data: zipcodeData,
    isLoading: isLoadingZipcodeData,
    isFetching: isFetchingZipcodeData,
    refetch: refetchZipcodeData,
  } = useQueryZipcode();

  const {
    data: bankData,
    isLoading: isLoadingBankData,
    isFetching: isFetchingBankData,
    refetch: refetchBankData,
  } = useQueryBank();


  useEffect(() => {
    if (countryData)
      _mapCountry(countryData?.data?.data)

    if (provinceData)
      _mapProvince(provinceData?.data?.data);
    
    if (cityData)
      _mapCity(cityData?.data?.data);

    if (districtData)
      _mapDistrict(districtData?.data?.data);

    if (wardData)
      _mapWard(wardData?.data?.data);

    if (zipcodeData)
      _mapZipCode(zipcodeData?.data?.data);

    if (bankData)
      _mapBank(bankData?.data?.data)
  }, [
    countryData,
    provinceData, 
    cityData,
    districtData,
    wardData,
    zipcodeData,
    bankData
  ])

  const _mapCountry = (data) => {
    const temp = data?.map((item, index) => ({
      label: item.namaNegara,
      value: item.id,
    }));
    setCountries(temp);
  };

  const _mapProvince = (data, name) => {
    const transformedData = data.map((item, index) => ({
      label: item.namaProvinsi,
      value: item.id,
      country: item.namaNegara,
    }));
    setProvinces(transformedData);
  };

  const _mapCity = (data, name) => {
    const transformedData = data.map((item, index) => ({
      label: item.namaKabupatenKota,
      value: item.id,
      province: item.namaProvinsi,
    }));
    setCities(transformedData);
  };

  const _mapDistrict = (data, name) => {
    const transformedData = data.map((item, index) => ({
      label: item.namaKecamatan,
      value: item.id,
      city: item.namaKabupatenKota,
    }));
    setDistricts(transformedData);
  };

  const _mapWard = (data, name) => {
    const transformedData = data.map((item, index) => ({
      label: item.namaKelurahan,
      value: item.id,
      district: item.namaKecamatan,
    }));
    setWards(transformedData);
  };

  const _mapZipCode = (data, name) => {
    const transformedData = data.map((item, index) => ({
      label: item.kodePos,
      value: item.id,
      ward: item.namaKelurahan,
    }));
    setZipCodes(transformedData);
  };

  const _mapBank = (data, name) => {
    const transformedData = data.map((item, index) => ({
      label: item.nama,
      value: item.id,
    }));
    setBank(transformedData);
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      general_information: yup.object().shape({
        isPersonal: yup.boolean(),
        beneficiaries_type: yup.string().required('Jenis beneficiaries wajib di pilih'),
        other_beneficiaries_type: yup.string(),
        fullname: yup.string()
        .when('isPersonal', {
          is: true,
          then: () => yup.string().required('Nama Lengkap wajib diisi')
        }),
        nik: yup.string()
        .when('isPersonal', {
          is: true,
          then: () => yup.string().trim().test(
            "no_ktp",
            ({ label }) => validationErrorMsg.mixed.default.replace("${path}", label),
            isCitizenIdValid
          )
          .required()
          .label("Nomor KTP")
        }),
        gender: yup.string()
        .when('isPersonal', {
          is: true,
          then: () => yup.string().required('Jenis Kelamin harus di pilih').nonNullable().label("Jenis Kelamin")
        }),
        birthdate: yup.string()
        .when('isPersonal', {
          is: true,
          then: () => yup.string().required('Tanggal Lahir/ Tanggal Berdiri wajib diisi')
        }),
        phone_number: yup.string()
        .when('isPersonal', {
          is: true,
          then: () => yup
          .string()
          .matches(phoneRegExp, "Nomor Handphone tidak valid")
          .min(10, "Nomor Handphone minimal 10 digit")
          .max(13, "Nomor Handphone maksimum hanya 13 digit")
          .label("Nomor Handphone"),
        }),
        nik_document: yup.mixed(),
        // GROUP
        institution_name: yup.string()
        .when('isPersonal', {
          is: false,
          then: () => yup.string().required('Nama Badan Usaha/ Lembaga/ Kelompok/ Masyarakat Hukum Adat/ Lainnya')
        }),
        deed_number: yup.number()
        .when('isPersonal', {
          is: false,
          then: () => yup.number().typeError('No. Akta/ No.Izin/ No. SK Penetapan harus angka').required('No. Akta/ No.Izin/ No. SK Penetapan wajib diisi')
        }),
        nib_number: yup.number()
        .when('isPersonal', {
          is: false,
          then: () => yup.number().typeError('NIB harus angka').required('NIB wajib diisi')
        }),
        npwp: yup.number()
        .when('isPersonal', {
          is: false,
          then: () => yup.number().typeError('NPWP harus angka').required('NPWP wajib diisi')
        }),
        est_date: yup.string()
        .when('isPersonal', {
          is: false,
          then: () => yup.string().required('Tanggal Lahir/ Tanggal Berdiri wajib diisi')
        }),
        office_phone_number: yup.number()
        .when('isPersonal', {
          is: false,
          then: () => yup.number().typeError('Nomor Telp Kantor harus angka').required('Nomor Telp Kantor wajib diisi')
        }),
        institution_description: yup.string()
        .when('isPersonal', {
          is: false,
          then: () => yup.string().required('Deskripsi Tentang Badan Usaha/ Lembaga/ Kelompok Masyarakat/ Usaha Lainnya  wajib diisi')
        }),
        deed_document: yup.mixed(),
        support_document: yup.mixed(),
      }),
      address: yup.object().shape({
        province: yup.mixed().required("provinsi wajib diisi"),
        city: yup.mixed().required("kota wajib diisi"),
        district: yup.mixed().required("kecamatan wajib diisi"),
        village: yup.mixed().required("kelurahan wajib diisi"),
        zip_code: yup.mixed().required("kode pos wajib diisi"),
        full_address: yup.string().required("alamat wajib diisi")
      }),
      bank_account_information: yup.object().shape({
        account_number:  yup.number().typeError('No Rekening harus angka').required('No Rekening wajib diisi'),
        account_name: yup.string().required('Nama Rekening wajib diisi'),
        bank_name: yup.mixed().required('Nama Bank wajib diisi'),
        book_cover_document: yup.string(),
      }),
      director_composition: yup.array().of(
        yup.object().shape({
          name: yup.string().required('Nama direktur wajib diisi'),
          nik: yup.number().typeError('NIK harus angka').required('NIK wajib diisi'),
          position: yup.string().required('Jabatan wajib diisi'),
        })
      ),
      pic_information: yup.object().shape({
        isGroup: yup.boolean(),
        name: yup.string()
        .when('isGroup', {
          is: true,
          then: () => yup.string().required('Nama penanggung wajib diisi')
        }),
        nik: yup.number()
        .when('isGroup', {
          is: true,
          then: () => yup.number().typeError('NIK harus angka').required('NIK wajib diisi')
        }),
        phone_number: yup.string()
        .when('isGroup', {
          is: true,
          then: () => yup
          .string()
          .matches(phoneRegExp, "Nomor Handphone tidak valid")
          .min(10, "Nomor Handphone minimal 10 digit")
          .max(13, "Nomor Handphone maksimum hanya 13 digit")
          .label("Nomor Handphone")
          .required('Nama penanggung wajib diisi')
        }),
        pic_nik_document: yup.mixed()
      }),
      personal_account_information: yup.object().shape({
        email: yup.string().email().required().label("Email"),
        password: yup.string().required('Password is required'),
        confirmation_password: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
      })
    })
  })

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      isPersonal: true,
      general_information: {
        beneficiaries_type: "Perorangan",
      }
    }
  })

  const {
    control,
    setValue,
    reset,
    trigger,
    getValues,
    watch,
    clearErrors,
    resetField,
    formState,
    handleSubmit,
  } = methods

  const formMutation = useFormRegisterBeneficiariesMutation(reset)

  const optJenisBeneficiaries = [
    {
      label: "Perorangan",
      value: "Perorangan",
    },
    {
      label: "Badan Usaha",
      value: "Badan Usaha",
    },
    {
      label: "Masyarakat Hukum Adat",
      value: "Masyarakat Hukum Adat",
    },
    {
      label: "Kelompok Masyarakat",
      value: "Kelompok Masyarakat",
    },
    {
      label: "Lembaga Pemerintah",
      value: "Lembaga Pemerintah",
    },
    {
      label: "Lembaga Pendidikan/Penelitian",
      value: "Lembaga Pendidikan/Penelitian",
    },
    {
      label: "Lainnya",
      value: "Lainnya",
    },
  ]

  const {
    fields: directorsFields,
    append: appendDirectors,
    remove: removeDirectors,
  } = useFieldArray({
    control,
    name: "director_composition"
  })

  const onUploadFile = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      let temp = {
        url: response?.data?.data?.url,
        id: response?.data?.data?.id,
        type: response?.data?.data?.mimeType,
        name: response?.data?.data?.name,
        size: response?.data?.data?.size,
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const consumeApi = async (data) => {
    // console.log(`data`, mappingDataPersonalGroup(data))
    const dataFix = mappingDataPersonalGroup(data)
    const res = await formMutation.mutateAsync(dataFix)
    return res
  }

  const mappingDataPersonalGroup = (data) => {
    let dataFix = data
    const {
      address,
      bank_account_information,
      general_information,
      personal_account_information,
      director_composition,
      pic_information,
    } = dataFix

    // Mapping general information
    const {
      beneficiaries_type,
      other_beneficiaries_type,
      isPersonal,
      deed_document,
      support_document,
      nik_document,
      ...restGeneralInformation
    } = general_information

    let beneficiaries_type_modif = beneficiaries_type

    if (beneficiaries_type === "Lainnya"){
      beneficiaries_type_modif = other_beneficiaries_type
    }

    // Mapping Bank Account Information
    const {
      bank_cover_document,
      ...restBankAccountInformation
    } = bank_account_information

    // Mapping PIC Information
    const {
      pic_nik_document,
      ...restPicInformation
    } = pic_information

    dataFix = {
      address,
      bank_account_information: {
        bank_cover_document: bank_cover_document?.id,
        ...restBankAccountInformation
      },
      general_information: {
        beneficiaries_type: beneficiaries_type_modif,
        nik_document: nik_document?.id,
        ...restGeneralInformation
      },
      personal_account_information,
    }

    if (!isPersonal){
      dataFix = {
        ...dataFix,
        general_information: {
          beneficiaries_type: beneficiaries_type_modif,
          deed_document: deed_document?.id,
          support_document: support_document?.id,
          ...restGeneralInformation
        },
        director_composition,
        pic_information: {
          pic_nik_document: pic_nik_document?.id,
          ...restPicInformation
        },
      }
    }
    return dataFix
  }

  const onSubmit = async (data) => {
    setTemp(data)
    setConfirmModal(true)
  }

  const onSubmitConfirmModal = async () => {
    const response = await consumeApi(temp)
    if (response.status === 200) {
      setConfirmModal(false)
      reset()
      const redirectUrl = `${process.env.REACT_APP_AUTH_URL}/account/LoginEndUser?type=Dana%20Program`;
      window.location.href = redirectUrl;
      // showToastMessage(
      //   "Data Berhasil Disimpan!",
      //   "Data yang anda masukan telah berhasil disimpan."
      // );
    }
  }

  return {
    methods,
    setValue,
    getValues,
    watch,
    clearErrors,
    resetField,
    formState,
    handleSubmit,
    control,
    trigger,
    reset,
    optJenisBeneficiaries,
    countries,
    setCountries,
    provinces,
    setProvinces,
    cities,
    setCities,
    districts,
    setDistricts,
    wards,
    setWards,
    zipCodes,
    setZipCodes,
    directorsFields,
    appendDirectors,
    removeDirectors,
    bank,
    onSubmit,
    confirmModal,
    setConfirmModal,
    onSubmitConfirmModal,
    onUploadFile
  }
}

export const useQueryCountry = () => {
  return useQuery({
    queryKey: ["list-country"],
    queryFn: () => getCountryService(),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useQueryProvince = () => {
  return useQuery({
    queryKey: ["list-province"],
    queryFn: () => getProvinceService(),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useQueryCity = () => {
  return useQuery({
    queryKey: ["list-city"],
    queryFn: () => getCityService(),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useQueryDistrict = () => {
  return useQuery({
    queryKey: ["list-district"],
    queryFn: () => getDistrictService(),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useQueryWard = () => {
  return useQuery({
    queryKey: ["list-ward"],
    queryFn: () => getWardService(),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useQueryZipcode = () => {
  return useQuery({
    queryKey: ["list-zip-code"],
    queryFn: () => getPostCodeService(),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useQueryBank = () => {
  return useQuery({
    queryKey: ["list-bank"],
    queryFn: () => getBankService(),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useFormRegisterBeneficiariesMutation = (reset) => {
  return useMutation(async (payload) => registerBeneficiaries(payload), {
    onSuccess: () => {
      // enqueueSnackbar({
      //   message: "Success",
      //   variant: "success",
      // });
      // reset();
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  })
}
export default useRegister