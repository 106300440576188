import React, { Fragment, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import {
  BottomFormAction,
  Button,
  ConfirmationModal,
  HeaderLabel,
  InputForm,
  InputFormRadio,
  Spinner,
} from 'components';
import AlertBlankData from 'components/molecules/Alert';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import CardForm from 'components/molecules/CardForm';
import AtomSelect from 'components/atoms/Select';
import { RiAddLine, RiMap2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import WarningAlert from '../components/WarningAlert';
import { FaTrashAlt } from 'react-icons/fa';
import InputFormDropzone from 'components/molecules/InputFormDropzone';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormCfP } from 'app/actions';
import SelectFDBProvince from 'components/organisms/inputs/SelectFDBProvince';
import SelectFDBCity from 'components/organisms/inputs/SelectFDBCity';
import SelectFDBDistrict from 'components/organisms/inputs/SelectFDBDistrict';
import SelectFDBVillage from 'components/organisms/inputs/SelectFDBVillage';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getGeneralInformation,
  getLocationExecutions,
  postLocationExecutions,
} from 'services/danaProgram/callForProposalService';
import { fileUpload } from 'services/danaProgram/fileService';
import { enqueueSnackbar } from 'notistack';
import FileDetail from 'components/molecules/FileDetail';

const validationSchema = yup.object({
  location: yup.array().of(
    yup.object().shape({
      province: yup.object().nullable(),
      city: yup.object().nullable(),
      district: yup.object().nullable(),
      village: yup.object().nullable(),
      latitude: yup.string().nullable(),
      longitude: yup.string().nullable(),
    })
  ),
  boundary_map: yup
    .array()
    .min(
      1,
      'Peta Batasan Wilayah Pelaksanaan wajib diisi, data peta belum di unggah'
    )
    .required('required'),
});

const validationSchemaWhenInitBoundary = yup.object({
  location: yup.array().of(
    yup.object().shape({
      province: yup.object().nullable(),
      city: yup.object().nullable(),
      district: yup.object().nullable(),
      village: yup.object().nullable(),
      latitude: yup.string().nullable(),
      longitude: yup.string().nullable(),
    })
  ),
  boundary_map: yup.array(),
});

const defaultValues = {
  location: [
    {
      province: null,
      city: null,
      district: null,
      village: null,
      latitude: '',
      longitude: '',
    },
  ],
  boundary_map: [],
  is_conservation_area: '',
  is_allowed: '',
  conservation_area: '',
};

const ImplementationLocation = () => {
  const navigate = useNavigate();
  const [showConfirmModal, setConfirmModal] = useState({
    open: false,
    type: 'draft',
    cancelLabel: 'Tidak',
    confirmLabel: 'Iya, simpan draft',
    title: '',
    description: '',
  });
  const [payload, setPayload] = useState(null);
  const [initDocs, setInitDocs] = useState([]);

  const methods = useForm({
    resolver: yupResolver(
      initDocs.length > 0 ? validationSchemaWhenInitBoundary : validationSchema
    ),
    defaultValues,
  });

  const { control } = methods;

  const callForProposalData = useSelector(
    (state) => state?.callForProposalData?.data
  );
  const { id } = useSelector((state) => state?.callForProposalData?.data);
  const executorLocationId = callForProposalData.category?.find((item) => {
    return item.id === 'executorLocation';
  })?.data?._id;

  const { data, isLoading, isFetching } = useQuery({
    enabled: !!id && !executorLocationId,
    queryKey: [`get-detail-general-information`],
    queryFn: async () => {
      const res = await getGeneralInformation({ id });
      return res.data.data;
    },
    onSuccess: (res) => {
      const modifiedLocation = res?.note_concept?.location?.map((item) => {
        return {
          province: item.province,
          city: item.cities,
          district: item.district,
          village: item.village,
          latitude: item.latitude,
          longitude: item.longitude,
        };
      });
      methods.reset({
        location: modifiedLocation,
        boundary_map: [],
        is_conservation_area: '',
        is_allowed: '',
        conservation_area: '',
      });
    },
  });

  const { data: dataDetail, isFetching: isFetchingDetail } = useQuery({
    enabled: !!executorLocationId,
    queryKey: [`get-detail-location-executions`],
    queryFn: async () => {
      const res = await getLocationExecutions(id);
      return res.data.data;
    },
    onSuccess: (res) => {
      setInitDocs(res?.boundary_map);
      const modifiedLocation = res?.location?.map((item) => {
        return {
          province: item.province,
          city: item.city,
          district: item.district,
          village: item.village,
          latitude: item.latitude,
          longitude: item.longitude,
        };
      });
      methods.reset({
        location: modifiedLocation,
        boundary_map: [],
        is_conservation_area: res?.is_conservation_area,
        is_allowed: res?.is_allowed,
        conservation_area: res?.conservation_area,
      });
    },
  });

  const {
    fields: locationFields,
    append: appendLocation,
    remove: removeLocation,
  } = useFieldArray({
    control,
    name: 'location',
  });

  const saveDraft = () => {
    setPayload({ ...methods.getValues(), status: 'DRAFT' });
    setConfirmModal((curr) => ({
      ...curr,
      open: true,
      type: 'draft',
      cancelLabel: 'Tidak',
      confirmLabel: 'Iya, simpan draft',
      title: 'Anda yakin akan menyimpan ini sebagai draft?',
      description:
        'Setelah anda menyimpan sebagai draft anda masih bisa melanjutkannya',
    }));
  };

  const submitForm = (values) => {
    setPayload({
      ...values,
      status: 'SUBMIT',
    });
    setConfirmModal((curr) => ({
      ...curr,
      open: true,
      type: 'submit',
      cancelLabel: 'Tidak, ubah data',
      confirmLabel: 'Ya, kirimkan',
      title: 'Anda yakin akan mengirimkan semua data ini?',
      description:
        'Dengan ini kami menyatakan bahwa Seluruh data yang kami berikan pada formulir ini adalah benar adanya. Jika dikemudian hari BPDLH menemukan unsur kebohongan dan atau pemalsuan data, kami siap dan bersedia untuk diajukan ke ranah hukum sesuai dengan undang-undang yang berlaku. ',
    }));
  };

  const onSubmit = () => {
    postFile.mutate();
  };

  const postFile = useMutation({
    mutationKey: [`post-file`],
    mutationFn: async () => {
      const data = payload.boundary_map?.map(async (item) => {
        const formData = new FormData();
        formData.append('file', item);
        const res = await fileUpload(formData);
        return {
          fileId: res.data.data.id,
          fileName: res.data.data.name,
          fileSize: res.data.data.size,
          mimeType: res.data.data.mimeType,
          path: res.data.data.key,
        };
      });
      return Promise.all(data);
    },
    onSuccess: (res) => {
      postLocation.mutate({
        ...payload,
        boundary_map: res.length > 0 ? [...res, ...initDocs] : initDocs,
        call_for_proposal_id: id,
        note_concept_id: data
          ? data.note_concept_id
          : dataDetail?.note_concept_id,
        location_id: executorLocationId || '',
      });
    },
  });

  const postLocation = useMutation({
    mutationKey: [`post-location`],
    mutationFn: async (params) => {
      const res = await postLocationExecutions(params);
      return res.data;
    },
    onSuccess: (res) => {
      enqueueSnackbar('Berhasil mengirimkan data', { variant: 'success' });
      navigate(`/proposal/call-for-proposal/${id}`);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <ConfirmationModal
        useGraphic
        open={showConfirmModal.open}
        onCancel={() => {
          setConfirmModal((curr) => ({
            ...curr,
            open: false,
          }));
          setPayload(null);
        }}
        cancelLabel={showConfirmModal.cancelLabel}
        confirmLabel={showConfirmModal.confirmLabel}
        onSubmit={onSubmit}
        isLoading={postFile.isLoading || postLocation.isLoading}
      >
        <div className="space-y-1">
          <div className="text-lg text-gray-900 font-semibold">
            {showConfirmModal.title}
          </div>
          <div className="text-sm text-gray-600">
            {showConfirmModal.description}
          </div>
        </div>
      </ConfirmationModal>
      <div className="space-y-6">
        <div className="bg-white rounded-xl p-10 space-y-8">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: 'Call For Proposal',
                  path: `/proposal/call-for-proposal`,
                },
                {
                  label: 'Pengajuan Full Proposal',
                },
              ]}
            />
            <div className="flex mt-5 gap-4">
              <div className="flex-1">
                <HeaderLabel text="Lokasi Pelaksanaan" />
              </div>
            </div>
          </div>
          <div className="w-full">
            <AlertBlankData
              title={`Lengkapi Data`}
              body={`Silakan isi semua data dibawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya.`}
            />
          </div>
          <FormProvider {...methods}>
            {isFetchingDetail || isFetching ? (
              <div className="flex justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                <CardForm label="Lokasi Pelaksanaan">
                  <div className="space-y-5">
                    {locationFields.map((field, index) => (
                      <NestedLocation
                        key={field.id}
                        field={field}
                        index={index}
                        locationFields={locationFields}
                        appendLocation={appendLocation}
                        removeLocation={removeLocation}
                      />
                    ))}
                    <div className="space-y-2">
                      <div className="flex text-[#1D2939] font-semibold text-sm gap-1">
                        <span className={`label-text`}>
                          Peta Batasan Wilayah Pelaksanaan
                        </span>
                        <span className="text-[#F04438]">*</span>
                      </div>
                      {initDocs?.length > 0 ? (
                        <>
                          {initDocs?.map((item, index) => (
                            <FileDetail
                              key={index}
                              file={item}
                              onRemove={() => {
                                const filtered = initDocs.filter(
                                  (file) => file.fileId !== item.fileId
                                );
                                setInitDocs(filtered);
                              }}
                            />
                          ))}
                        </>
                      ) : (
                        <InputFormDropzone
                          name="boundary_map"
                          accept={{
                            'image/png': [],
                            'image/jpeg': [],
                            'application/pdf': [],
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                              [],
                          }}
                          maxFiles={1}
                          maxSize={10}
                          multiple={false}
                          informationText="PNG, JPG, PDF, Docx"
                        />
                      )}
                    </div>
                  </div>
                </CardForm>
                <CardForm label="Tentang Lokasi Pelaksanaan">
                  <div className="space-y-5">
                    <div className="space-y-2">
                      <div className="flex gap-1 font-semibold text-[14px]">
                        <span className={`label-text`}>
                          Apakah Lokasi/Area Pelaksanaan Program/Kegiatan di
                          atas Termasuk ke dalam Area/Kawasan konservasi?
                        </span>
                        <span className="text-red-400">*</span>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex-1">
                          <InputFormRadio
                            controllerName={`is_conservation_area`}
                            values={[
                              {
                                label: 'Ya',
                                value: true,
                              },
                              {
                                label: 'Tidak',
                                value: false,
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <InputForm
                      controllerName={'conservation_area'}
                      className={`mt-2 border w-full rounded-md flex-1`}
                      disabled={!methods.watch(`is_conservation_area`)}
                      label="Tuliskan Kawasan Konservasi Tersebut"
                      required
                    />
                    <div className="space-y-2">
                      <div className="flex gap-1 font-semibold text-[14px]">
                        <span className={`label-text`}>
                          Jika Lokasi Pelaksanaan Program/Kegiatan Termasuk ke
                          dalam Area/Kawasan Konservasi, Apakah Area/Kawasan
                          Konservasi tersebut Tidak Dilarang dan Sudah
                          Memperoleh Izin?{' '}
                          <span className="text-red-400">*</span>
                        </span>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex-1">
                          <InputFormRadio
                            controllerName={`is_allowed`}
                            values={[
                              {
                                label: 'Ya',
                                value: true,
                              },
                              {
                                label: 'Tidak',
                                value: false,
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardForm>
              </>
            )}
            <BottomFormAction
              lastStep
              backButtonAction={() => navigate(-1)}
              disableDrafButton={false}
              drafButtonAction={() => {
                const values = methods.getValues();
                saveDraft(values);
              }}
              // disableButtonSubmit={isValid ? false : true}
              submitActionButton={() => {
                methods.handleSubmit(submitForm)();
              }}
            />
          </FormProvider>
        </div>
      </div>
    </Fragment>
  );
};

const NestedLocation = ({
  index,
  field,
  locationFields,
  appendLocation,
  removeLocation,
}) => {
  const { control } = useFormContext();

  const provinceId = useWatch({
    name: `location.${index}.province`,
    control,
  });

  const cityId = useWatch({
    name: `location.${index}.city`,
    control,
  });

  const districtId = useWatch({
    name: `location.${index}.district`,
    control,
  });

  return (
    <Fragment key={field.id}>
      <div className="flex gap-6">
        <div className="w-1/2">
          <SelectFDBProvince
            controllerName={`location.${index}.province`}
            className="basic-single flex-1"
            label="Provinsi"
            required
            placeholder="Provinsi"
          />
        </div>
        <div className="w-1/2">
          <SelectFDBCity
            controllerName={`location.${index}.city`}
            className="basic-single flex-1"
            label="Kabupaten/Kota"
            required
            placeholder="Kabupaten/Kota"
            provinceId={provinceId?.value}
          />
        </div>
      </div>
      <div className="flex gap-6">
        <div className="w-1/2">
          <SelectFDBDistrict
            controllerName={`location.${index}.district`}
            className="basic-single flex-1"
            label="Kecamatan"
            placeholder="Kecamatan"
            cityId={cityId?.value}
          />
        </div>
        <div className="w-1/2">
          <SelectFDBVillage
            controllerName={`location.${index}.village`}
            className="basic-single flex-1"
            label="Desa/Kelurahan"
            placeholder="Desa/Kelurahan"
            districtId={districtId?.value}
          />
        </div>
      </div>
      {/* <div>
        <div className="flex gap-1 font-semibold text-[14px] mb-2">
          <span className={`label-text`}>Titik Lokasi Alamat</span>
        </div>
        <WarningAlert text="Tandai lokasi sesuai alamat!" />
        <div className="w-full mt-2 flex justify-center gap-2 items-center bg-white p-4 border rounded-lg cursor-pointer text-sm">
          <RiMap2Line size={20} />
          Pilih Lewat Peta
        </div>
      </div>
      <div className="flex gap-6">
        <div className="w-1/2">
          <InputForm
            controllerName={`location.${index}.latitude`}
            className={`border flex-1 rounded-md w-full`}
            label="Latitude"
          />
        </div>
        <div className="w-1/2">
          <InputForm
            controllerName={`location.${index}.longitude`}
            className={`border flex-1 rounded-md w-full`}
            label="Longitude"
          />
        </div>
      </div> */}
      <hr />
      <div className="flex items-center justify-center gap-6">
        {locationFields.length > 1 && (
          <Button
            className="rounded-lg border border-error-300 py-2.5 px-4"
            onClick={() => {
              removeLocation(index);
            }}
            label={
              <div className="flex gap-2 items-center text-sm text-error-700">
                <FaTrashAlt size={20} className="text-error-700" />
                Lokasi
              </div>
            }
          />
        )}
        {((index > 0 && index === locationFields.length - 1) ||
          (index === 0 && locationFields.length === 1)) && (
          <Button
            className="rounded-lg border border-gray-300 shadow py-2.5 px-4"
            label={
              <div className="flex gap-2 items-center text-gray-700 text-sm">
                <RiAddLine size={20} />
                Lokasi Lain
              </div>
            }
            onClick={() => {
              appendLocation({
                province: null,
                district: null,
                village: null,
                city: null,
                latitude: '',
                longitude: '',
              });
            }}
          />
        )}
      </div>
      <hr />
    </Fragment>
  );
};

export default ImplementationLocation;
