import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import AtomSelect from "../../../atoms/Select";
import { getProvinceFDBService } from "services/fdb/region";

let HAS_MORE = false;

const SelectFDBProvince = React.forwardRef((props, ref) => {
  const { className, countryId, ...restProps } = props;

  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);

  const { data } = useQuery({
    queryKey: ["province-fdb", countryId],
    queryFn: async () => {
      const res = await getProvinceFDBService({
        limit: 50,
        order_by: "name",
        order_dir: "asc",
        "filter[country]": countryId,
        page,
      });

      if (res?.data?.data?.meta?.next_page_url) HAS_MORE = true;
      else HAS_MORE = false;

      return res?.data?.data?.data || [];
    },
    retry: false,
    enabled: !props.asText
  });

  useEffect(() => {
    setOptions([]);
    setPage(1);
  }, [countryId]);

  useEffect(() => {
    setOptions([]);
    data?.map((entry) => {
      setOptions((state) => [
        ...state,
        {
          value: entry?.id,
          label: entry?.name,
        },
      ]);
    });
  }, [data]);

  const fetchMore = () => {
    if (HAS_MORE) {
      setPage((curr) => curr + 1);
    }
  };

  return (
    <AtomSelect
      options={options}
      className={["basic-single mt-2 flex-1", className].join(" ")}
      onMenuScrollToBottom={fetchMore}
      {...restProps}
    />
  );
});

export default SelectFDBProvince;
