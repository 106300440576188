
import { isBoolean } from 'lodash';
import * as yup from 'yup'

const FieldLabel = {
  APP_VALUE: "Nilai Permohonan",
  COOPERATION_PATTERN: "Pola Kerjasama",
  FINANCING_TERM_PLAN: "Rencana jangka waktu pembiayaan yang akan disalurkan",
  COLLATERAL_TYPE: "Jenis Jaminan",
  GUARANTEE_FORM: "Bentuk Jaminan",
  GUARANTEE_NAME: "Nama Jaminan",
  COLLATERAL_VALUE: "Nilai Jaminan",
  GUARANTEE_FILE: "Upload Jaminan",
  FDB_POTENCY: "Upload Daftar Potensi Penerima FDB",
};

export const applicationValidationScheme = yup.object({
  application_value: yup
    .number()
    .required()
    .transform((val) => (isNaN(val) ? null : val))
    .label(FieldLabel.APP_VALUE),
  term_plan_value: yup
    .number()
    .required()
    .transform((val) => (isNaN(val) ? null : val))
    .label(FieldLabel.FINANCING_TERM_PLAN),
  term_plan_type: yup.string().required().label(FieldLabel.FINANCING_TERM_PLAN),
  is_grace_period: yup
    .bool()
    .transform((val) => (isBoolean(val) ? val : null))
    .label("Apakah Rencana Jangka Waktu Dengan Grace Period?"),
  grace_period_value: yup
    .string()
    .when(['is_grace_period'], ([is_grace_period]) => {
      if (is_grace_period) return yup.number().transform((val) => (isNaN(val) ? null : val)).label("Lama Grace Period")
    }).nullable(),
  grace_period_type: yup.string()
    .when(['is_grace_period'], ([is_grace_period]) => {
      if (is_grace_period) return yup.string().required().label("Lama Grace Period")
      else return yup.string().notRequired().label('Lama Grace Period')
    }),
  format_plan_file: yup
    .string()
    .required()
    .label("Format Rencana Penggunaan FDB"),
  format_potention_file: yup
    .string()
    .required()
    .label("Format Daftar Potensi Penerima FDB"),
  format_potention_url: yup
    .string()
    .label("URL Daftar Potensi Penerima FDB"),
  collaterals: yup.array(
    yup.object({
      id: yup.string().notRequired().label(""),
      type: yup.string().required().label(FieldLabel.COLLATERAL_TYPE),
      form: yup.string().required().label(FieldLabel.GUARANTEE_FORM),
      value: yup
        .number()
        .required()
        .transform((val) => (isNaN(val) ? null : val))
        .label(FieldLabel.COLLATERAL_VALUE),
      files: yup.array().min(1, "Upload Jaminan harus memiliki setidaknya 1 file").required().label("Upload Jaminan"),
    })
  ),
});